import { useState } from 'react';


const generateRandomPassword = () => {
    const uppercaseCharset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseCharset = 'abcdefghijklmnopqrstuvwxyz';
    const numberCharset = '0123456789';
    const specialCharset = '!@#$%^&*()_+';
  
    const requiredCharsets = [uppercaseCharset, lowercaseCharset, numberCharset];
  
    // Choose one random character from each required charset
    let password = '';
    for (const charset of requiredCharsets) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }
  
    // Fill the rest of the password with random characters from all charsets
    const allCharsets = uppercaseCharset + lowercaseCharset + numberCharset + specialCharset;
    const remainingLength = 12 - requiredCharsets.length;
    for (let i = 0; i < remainingLength; i++) {
      const randomIndex = Math.floor(Math.random() * allCharsets.length);
      password += allCharsets.charAt(randomIndex);
    }
  
    // Shuffle the password to make the character positions random
    password = password.split('').sort(() => Math.random() - 0.5).join('');
  
    return password;
  };
  

const usePasswordGenerator = () => {
  const [password, setPassword] = useState('');

  const generatePassword = () => {
    const newPassword = generateRandomPassword();
    setPassword(newPassword);
  };

  return {
    password,
    generatePassword,
  };
};

export default usePasswordGenerator;
