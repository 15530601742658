// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import { ArrowRightRounded, Send } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, TextField, Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";

import { Typography, Paper, InputLabel, Box, Fab, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { caseAPI } from "api";
import MDButton from "components/MDButton";
import { setGlobalState } from "Global";
import { useGlobalState } from "Global";

const caseStateMapping = {
  "In-Progress": "info",
  New: "warning",
  Resolved: "success",
};

function WorkFlowChange({ open, handleClose, caseDetails }) {
  const [state, setState] = useState({ ...caseDetails });
  const navigation = useNavigate();
  const [userDetails] = useGlobalState("userDetails");
  const [workflows, setWorkflows] = useState([]);
  const [nextSteps, setNextSteps] = useState({});

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const getStepMapping = (data) => {
    let tempMapping = {};
    data.forEach((item) => {
      tempMapping[item._id] = item.steps_in_workflow;
    });
    return tempMapping;
  };

  const getWorkflows = () => {
    caseAPI
      .getWorkflows()
      .then((res) => {
        if (res.status == 200) {
          setWorkflows(res.data);
          setNextSteps(getStepMapping(res.data));
        }
      })
      .catch((error) => {});
  };

  const handleSubmit = () => {
    caseAPI
      .changeWorkflow(state)
      .then((res) => {
        handleClose(!open);
        navigation(`/${userDetails.id}/cases`);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getWorkflows();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", minWidth: "35rem" }}
      >
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2 }}>
            Change Worflow {caseDetails.workflow_name}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="workflowId-label">
                  Available Workflows
                </InputLabel>
                <Select
                  id="workflowId"
                  labelId="workflowId-label"
                  value={state.workflowId}
                  name="workflowId"
                  label={"Available Workflows"}
                  onChange={handleChange}
                  sx={{ marginLeft: "2", width: "100%", minHeight: "3.5rem" }}
                >
                  {workflows.map((item) => {
                    return (
                      <MenuItem
                        sx={{ width: "100%", minHeight: "3.5rem" }}
                        value={item._id}
                        key={item.name}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            {state.workflowId && (
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="nextStep-label">Available Steps</InputLabel>
                  <Select
                    id="nextStep"
                    labelId="nextStep-label"
                    value={state.nextStep}
                    name="nextStep"
                    label={"Available Steps"}
                    onChange={handleChange}
                    sx={{ marginLeft: "2", width: "100%", minHeight: "3.5rem" }}
                  >
                    {nextSteps[state.workflowId].map((item) => {
                      return (
                        <MenuItem
                          sx={{ width: "100%", minHeight: "3.5rem" }}
                          value={item}
                          key={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={2}></Grid>

            <Grid item xs={2} md={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                p={1}
              >
                <Button
                  size="medium"
                  color="success"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Change
                </Button>
              </MDBox>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default WorkFlowChange;
