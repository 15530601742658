// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AgentChange from "layouts/forms/dialogs/agentChange";
import EmailForm from "layouts/forms/dialogs/emailform";
import StateChange from "layouts/forms/dialogs/stateChange";
import StatusChange from "layouts/forms/dialogs/statusChange";
import LoadingOverlay from "react-loading-overlay";

// Data
import {
  faBell,
  faCheck,
  faChevronDown,
  faChevronUp,
  faComments,
  faEdit,
  faEnvelopesBulk,
  faHistory,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, Tooltip } from "@mui/material";
import { setGlobalState } from "Global";
import { OCRAPI, caseAPI, featureAPI } from "api";
import { Validations } from "components/controls/Validations";
import "css/forms/dropdown.css";
import { format } from "date-fns";
import SideChat from "layouts/chat";
import WorkFlowChange from "layouts/forms/dialogs/workFlowChange";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Xarrow from "react-xarrows";
import { toFloatMark } from "utils/conversion";
import DialogWrapper from "utils/dialog/dialogWrapper";
import { NoMaxWidthTooltip } from "utils/tooltips";
import { toDecimalMark } from "../../utils/conversion";
import AIAgent from "./dialogs/AIAgent";
import NotificationHistory from "./dialogs/notificationHistory";
import CaseFiles from "./file/caseFiles";
import GroupedCaseList from "./groupedCaseList";
import FeaturesSkeleton from "skeletons/forms/featuresSkeleton";
import { useGlobalState } from "Global";

const featureMapping = {
  amountName: "Claim Amount (ZAR)",
};

const _ = require("lodash");

const boxStyle = {
  border: "1px #348dec solid",
  borderRadius: "10px",
  padding: "1rem",
  textAlign: "center",
  width: "fit-content",
  minWidth: "200px",
  height: "fit-content",
  color: "black",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  fontSize: "medium",
};

function Forms() {
  const navigation = useNavigate();
  const params = useParams();
  const [state, setState] = useState({
    showState: false,
    featuresIsEmpty: true,
    prevFeaturesIsEmpty: true,
    nextStep: {},
  });
  const [currSteps, setCurrSteps] = useState({});
  const [prevSteps, setPrevSteps] = useState({});
  const [userFiles, setUserFiles] = useState({});
  const [uploadFiles, setUploadFiles] = useState({ files: {}, prevFiles: {} });
  const [showMail, setShowMail] = useState(false);
  const [status, setStatus] = useState([]);
  const [showAgentChange, setShowAgentChange] = useState(false);
  const [showStatusChange, setShowStatusChange] = useState(false);
  const [showStateChange, setShowStateChange] = useState(false);
  const [showWorkflowChange, setShowWorkflowChange] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [showFiles, setShowFiles] = useState({ files: true, prevFiles: true });
  const [caseDetails, setCaseDetails] = useState({});
  const [caseTags, setCaseTags] = useState([]);
  const [isLoadingCaseDetials, setIsLoadingCaseDetials] = useState(true);
  const [amountValue, setAmountValue] = useState("");
  const [value, setValue] = useState(null);
  const [reachedBottom, setReachedBottom] = useState(false);
  const [showCards, setShowCards] = useState({
    currFeatures: true,
    prevFeatures: true,
    stepChange: true,
  });
  const [verifiedFeatures, setVerifiedFeatures] = useState([]);
  const [showNotificationHistory, setShowNotificationHistory] = useState(false);
  const [showCaseTransferHistory, setShowCaseTransferHistory] = useState(false);
  const [showAIAgent, setShowAIAgent] = useState(false);
  const [validationList, setValidationList] = useState([]);
  const [userDetails] = useGlobalState("userDetails");

  const stepMapping = {
    prevSet: setPrevSteps,
    currSet: setCurrSteps,
    prev: prevSteps,
    curr: currSteps,
  };

  const [caseFileKey, setCaseFileKey] = useState(0);

  useEffect(() => {
    getFeatures();
    // getFiles();
    getCaseDetails();
    getNextPaths();
    getTags();
    getFeaturesVerified();
  }, [location.pathname]);

  const reloadContents = () => {
    getFeatures();
    getCaseDetails();
    getNextPaths();
  };

  const getNextPaths = async () => {
    caseAPI
      .getNextPaths(params.case_id)
      .then((res) => {
        if (res.status == 200) {
          assignAgents(res.data);
        }
      })
      .catch((error) => {});
  };

  const getTags = async () => {
    caseAPI
      .getTags(params.case_id)
      .then((res) => {
        if (res.status == 200) {
          setCaseTags(res.data);
        }
      })
      .catch((error) => {});
  };

  const assignAgents = async (currStatus) => {
    let tempStatus = currStatus;

    for (let i in tempStatus) {
      tempStatus[i]["agent_details"] = await getAutoAssignedDetails(
        currStatus[i].value
      );
    }
    setStatus(tempStatus);
  };

  const getAutoAssignedDetails = async (nextStep) => {
    let details = {
      error: true,
    };
    try {
      const res = await caseAPI.getAutoAssignedPerson(params.case_id, nextStep);

      if (res.data === null) {
        details["name"] = "No Person Available";
      } else {
        details["error"] = false;
        details = { ...details, ...res.data };
      }
    } catch (error) {}
    return details;
  };

  const handleStepChange = () => {
    caseAPI
      .changeStatus(
        params.case_id,
        state.nextStep.name,
        state.nextStep.emailId ? true : false,
        state.nextStep.emailId,
        state.nextStep.message
      )
      .then((res) => {
        if (res.status == 200) {
          if (!["admin", "superuser","3PA"].includes(userDetails.permission)) {
            navigation(`/${userDetails.id}/cases`);
          } else {
            window.location.reload();
          }
        }
      })
      .catch((error) => {});
  };

  const handleSelectNextStep = (nextStep, emailId, status = null) => {
    if (["OOO", "Busy"].includes(status)) {
      setGlobalState("error", {
        open: true,
        message:
          "Selected agent is Busy/Out of Office. Please select the secondary assignee incase faster resolution is needed.",
        type: "warning",
      });
    }
    setState((prevState) => {
      return {
        ...prevState,
        nextStep: {
          ...prevState.nextStep,
          name: nextStep,
          emailId: emailId,
        },
      };
    });
  };

  const handleSelectNextStepMessage = (message, status = null) => {
    setState((prevState) => {
      return {
        ...prevState,
        nextStep: {
          ...prevState.nextStep,
          message: message,
        },
      };
    });
  };

  const getCaseDetails = () => {
    setIsLoadingCaseDetials(true);
    caseAPI
      .getCase(params.case_id)
      .then((res) => {
        let tempCaseDetails = {
          "Current WorkFlow": {
            name: res.data.workflow_name,
            change: () => {
              setShowWorkflowChange(!showWorkflowChange);
            },
          },
          "Claim Amount": {
            name: "ZAR " + toDecimalMark(parseFloat(res.data.claim_amount)),

            show: false,
          },
          "Customer Name": {
            name: res.data.customer,

            show: false,
          },
          "Current Assigned Agent": {
            name: res.data.assigned_agent_email,
            change: () => {
              setShowAgentChange(!showAgentChange);
            },
          },
          "Current Case State": {
            name: res.data.case_state,
            change: () => {
              setShowStateChange(!showStateChange);
            },
          },
          "Current Step": {
            name: res.data.current_status,
            change: () => {
              setShowStatusChange(!showStatusChange);
            },
          },
        };
        setCaseDetails(tempCaseDetails);
        setIsLoadingCaseDetials(false);
      })
      .catch((error) => {
        setIsLoadingCaseDetials(false);
      });
  };

  const getFeatures = async () => {
    setIsLoading(true);
    featureAPI
      .getFeatures(params.case_id)
      .then((res) => {
        setState({
          ...state,
          features: res.data.features,
          prevFeatures: res.data.prev_features,
          featuresIsEmpty: res.data.features_isempty,
          prevFeaturesIsEmpty: res.data.prev_features_isempty,
        });

        setCurrSteps(getFeatureMapping(res.data.features));
        setPrevSteps(getFeatureMapping(res.data.prev_features));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getFeatureMapping = (features) => {
    let temp_steps = {};
    Object.keys(features).forEach((stepName) => {
      let temp_features = {};
      Object.keys(features[stepName]).forEach((feature) => {
        temp_features[feature] = features[stepName][feature].value;
      });
      temp_steps[stepName] = temp_features;
    });
    return temp_steps;
  };

  const setBullet = (dict, value) => {
    Object.keys(dict).forEach((item) => {
      dict[item] = false;
    });
    dict[value] = true;
    return dict;
  };

  const handleInputChange = (
    e,
    stepName,
    values,
    setValues,
    type,
    featuresData,
    stepType
  ) => {
    let { name, value } = e.target;
    let temp_step = values[stepName];
    if (type == "Bullet") {
      value = setBullet(temp_step[name], value);
    } else if (type == "Date") {
      value = format(value, "yyyy-MM-dd");
    } else if (type == "Integer") {
      if (value) {
        let newVal = toFloatMark(value);
        if (name == featureMapping.amountName) {
          setAmountValue(newVal);
        }
        if (value.slice(-1) == ".") {
          value = newVal + ".";
        } else if (value.slice(-2) == ".0") {
          value = newVal + ".0";
        } else {
          value = newVal;
        }
      }
    }

    temp_step[name] = value;
    let newValues = { ...values, [stepName]: temp_step };

    // // Perform Preupdate validation functions
    // let tempPreValidationList =
    //   featuresData[stepName][e.target.name]["pre_update_validation_functions"];
    // let preValidationChecks = preUpdateValidations(
    //   newValues[stepName],
    //   tempPreValidationList,
    //   stepType
    // );

    // if(!preValidationChecks){
    //   return;
    // }

    // Add Validation Functions to the validation queue

    let tempValidationsList =
      featuresData[stepName][e.target.name]?.validation_functions;

    if (
      typeof tempValidationsList == typeof [] &&
      tempValidationsList.length > 0
    ) {
      tempValidationsList = tempValidationsList.filter(
        (validation) =>
          !validationList.some((item) => _.isEqual(validation, item))
      );
      setValidationList((preValidationList) => {
        let newValidationList = [...preValidationList, ...tempValidationsList];
        return newValidationList;
      });
    }
    setValues(newValues);
  };

  const checkValidations = (type, stepName) => {
    let isValid = true;
    validationList.forEach((validation) => {
      let dynamicValidation = Validations[validation.name];
      let result = true;
      if (validation.name != "C2") {
        result = dynamicValidation(
          validation.parameters["feature_name1"],
          type[validation.parameters["feature_name1"]],
          validation.parameters["feature_name2"],
          type[validation.parameters["feature_name2"]]
        );
      } else {
        result = dynamicValidation(
          type["Reason for Invalidation"],
          type["Claim Status"]
        );
      }
      if (result !== true) {
        setGlobalState("error", {
          open: true,
          message: result,
          type: "error",
        });
        isValid = false;
      }
    });
    return isValid;
  };

  const updateStep = (stepName) => {
    let updateStepData = {};

    if (currSteps[stepName]) {
      updateStepData = currSteps[stepName];
    } else {
      updateStepData = prevSteps[stepName];
    }
    if (checkValidations(updateStepData, stepName)) {
      setIsLoading(true);
      featureAPI
        .updateFeatures(params.case_id, stepName, updateStepData)
        .then((res) => {
          if (res.status == 200) {
            setIsLoading(false);
            getCaseDetails();
            getFeatures();
            setGlobalState("error", {
              open: true,
              message: "Features Successfully updated.",
              type: "success",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const getFeaturesVerified = () => {
    OCRAPI.getFeaturesVerified({ case_id: params.case_id })
      .then((res) => {
        if (res.status == 200) {
          setVerifiedFeatures(res.data);
        }
      })
      .catch((error) => {});
  };

  return (
    <DashboardLayout>
      <MDBox>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card sx={{ minHeight: "20rem" }}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  pb={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography
                    variant="h6"
                    color="white"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "white !important",
                        fontSize: "medium !important",
                      }}
                      className={"font-unset"}
                    >
                      Claim Details
                    </MDBox>
                    <MDBox
                      color="white"
                      className={"font-unset"}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <Tooltip title={"Case Notification/Transfer History"}>
                        <MDBox
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "1rem",
                          }}
                          onClick={() => {
                            setShowNotificationHistory(
                              !showNotificationHistory
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            fontSize={"large"}
                            icon={faEnvelopesBulk}
                            cursor={"pointer"}
                            color={"white"}
                            style={{ marginRight: "1rem" }}
                          />
                          <MDBox
                            sx={{
                              fontSize: "small",
                              paddingTop: "0.5rem",
                              fontWeight: "300",
                              color: "#ffffffd9 !important",
                            }}
                          >
                            Transfer History
                          </MDBox>
                        </MDBox>
                      </Tooltip>

                      <Tooltip title={"Notify Agent"}>
                        <MDBox
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "1rem",
                          }}
                          onClick={() => {
                            setShowMail(!showMail);
                          }}
                        >
                          <FontAwesomeIcon
                            fontSize={"large"}
                            icon={faBell}
                            cursor={"pointer"}
                            color={"white"}
                          />
                          <MDBox
                            sx={{
                              fontSize: "small",
                              paddingTop: "0.5rem",
                              fontWeight: "300",
                              color: "#ffffffd9 !important",
                            }}
                          >
                            Notify Agent
                          </MDBox>
                        </MDBox>
                      </Tooltip>

                      <Tooltip title={"Notes"}>
                        <MDBox
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "1rem",
                          }}
                          onClick={() => {
                            setShowComments(!showComments);
                          }}
                        >
                          <FontAwesomeIcon
                            fontSize={"large"}
                            icon={faNoteSticky}
                            cursor={"pointer"}
                            color={"white"}
                          />
                          <MDBox
                            sx={{
                              fontSize: "small",
                              paddingTop: "0.5rem",
                              fontWeight: "300",
                              color: "#ffffffd9 !important",
                            }}
                          >
                            Notes
                          </MDBox>
                        </MDBox>
                      </Tooltip>

                      <Tooltip title={"Show Case History"}>
                        <MDBox
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "1rem",
                          }}
                          onClick={() => {
                            window.open("/history/" + params.case_id, "_blank");
                          }}
                        >
                          <FontAwesomeIcon
                            fontSize={"large"}
                            icon={faHistory}
                            cursor={"pointer"}
                            color={"white"}
                          />
                          <MDBox
                            sx={{
                              fontSize: "small",
                              paddingTop: "0.5rem",
                              fontWeight: "300",
                              color: "#ffffffd9 !important",
                            }}
                          >
                            Case History
                          </MDBox>
                        </MDBox>
                      </Tooltip>
                    </MDBox>
                  </MDTypography>
                </MDBox>

                {isLoading == true && <FeaturesSkeleton />}

                {/* Features and Files */}
                {isLoading == false && (
                  <MDBox sx={{ minHeight: "1rem" }}>
                    {["prev", "curr"].map((featureType) => {
                      const dynamicSetStep = stepMapping[featureType + "Set"];
                      const dynamicStep = stepMapping[featureType];

                      return (
                        state[featureType + "FeaturesIsEmpty"] == false && (
                          <Card sx={{ margin: 5 }}>
                            <MDBox
                              mx={0}
                              mt={0}
                              mb={2}
                              py={2}
                              px={2}
                              variant="gradient"
                              bgColor="info"
                              borderRadius="lg"
                              coloredShadow="info"
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowCards({
                                  ...showCards,
                                  [featureType + "Features"]:
                                    !showCards[featureType + "Features"],
                                });
                              }}
                            >
                              <MDTypography variant="h6" color="white">
                                Step - {caseDetails["Current Step"]?.name}
                              </MDTypography>
                              <MDBox
                                sx={{ display: "flex", position: "relative" }}
                              >
                                <MDBox
                                  sx={{
                                    display: "flex",
                                    zIndex: "10",
                                    position: "absolute",
                                    right: "2rem",
                                    top: "-0.2rem",
                                  }}
                                  className={"font-unset"}
                                >
                                  {caseTags &&
                                    caseTags.slice(0, 6).map((tag) => {
                                      return (
                                        <MDBox
                                          className={"success-button"}
                                          sx={{ whiteSpace: "nowrap" }}
                                        >
                                          {tag}
                                        </MDBox>
                                      );
                                    })}
                                </MDBox>
                                <Tooltip
                                  title={
                                    showCards[featureType + "Features"]
                                      ? "Hide"
                                      : "Expand"
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      showCards[featureType + "Features"]
                                        ? faChevronUp
                                        : faChevronDown
                                    }
                                    color={"white"}
                                    style={{
                                      marginRight: "0.5rem",
                                    }}
                                  ></FontAwesomeIcon>
                                </Tooltip>
                              </MDBox>
                            </MDBox>
                            {showCards[featureType + "Features"] && (
                              <MDBox p={2}>
                                {state[featureType + "Features"] &&
                                  Object.keys(
                                    state[featureType + "Features"]
                                  ).map((stepName) => {
                                    return (
                                      <MDBox key={stepName}>
                                        {/* Features */}
                                        <MDBox sx={{ padding: "1rem" }}>
                                          <Form>
                                            <Grid container>
                                              {dynamicStep[stepName] &&
                                                Object.keys(
                                                  state[
                                                    featureType + "Features"
                                                  ][stepName]
                                                ).map((feature, index) => {
                                                  let ControlType =
                                                    Controls[
                                                      state[
                                                        featureType + "Features"
                                                      ][stepName][feature].type
                                                    ];
                                                  let isViewable =
                                                    state[
                                                      featureType + "Features"
                                                    ][stepName][feature].view;
                                                  let isEditable =
                                                    state[
                                                      featureType + "Features"
                                                    ][stepName][feature].edit;

                                                  let verifiedFeature =
                                                    verifiedFeatures.find(
                                                      (
                                                        verifiedFeatureDetails
                                                      ) => {
                                                        return (
                                                          verifiedFeatureDetails[
                                                            "step_name"
                                                          ] == stepName &&
                                                          verifiedFeatureDetails[
                                                            "feature_name"
                                                          ] == feature
                                                        );
                                                      }
                                                    );

                                                  let preValidationList =
                                                    state[
                                                      featureType + "Features"
                                                    ][stepName][feature][
                                                      "pre_update_validation_functions"
                                                    ];
                                                  if (
                                                    isViewable &&
                                                    ControlType
                                                  ) {
                                                    return (
                                                      <Grid
                                                        item
                                                        xs={6}
                                                        sx={{
                                                          display: "flex",
                                                          position: "relative",
                                                          // justifyContent:"center"
                                                        }}
                                                      >
                                                        <NoMaxWidthTooltip
                                                          title={
                                                            verifiedFeature
                                                              ? verifiedFeature.file_name_map.map(
                                                                  (file) => {
                                                                    return "Verified by AI";
                                                                  }
                                                                )
                                                              : ""
                                                          }
                                                        >
                                                          <ControlType
                                                            name={feature}
                                                            label={feature}
                                                            value={
                                                              dynamicStep[
                                                                stepName
                                                              ][feature]
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChange(
                                                                e,
                                                                stepName,
                                                                dynamicStep,
                                                                dynamicSetStep,
                                                                state[
                                                                  featureType +
                                                                    "Features"
                                                                ][stepName][
                                                                  feature
                                                                ].type,
                                                                state[
                                                                  featureType +
                                                                    "Features"
                                                                ],
                                                                featureType
                                                              )
                                                            }
                                                            disabled={
                                                              !isEditable
                                                            }
                                                            options={
                                                              state[
                                                                featureType +
                                                                  "Features"
                                                              ][stepName][
                                                                feature
                                                              ].scope
                                                            }
                                                            keyscopes={
                                                              state[
                                                                featureType +
                                                                  "Features"
                                                              ][stepName][
                                                                feature
                                                              ].keyscope
                                                            }
                                                            stepDetails={
                                                              state[
                                                                featureType +
                                                                  "Features"
                                                              ][stepName]
                                                            }
                                                            amount={amountValue}
                                                            className={
                                                              verifiedFeature
                                                                ? "form-feature-verified"
                                                                : ""
                                                            }
                                                            preValidationList={
                                                              preValidationList
                                                            }
                                                            style={{
                                                              borderRadius:
                                                                "5px",

                                                              lineHeight: 1.5,
                                                              boxShadow: `2px 2px 4px rgba(0,0,0, 0.15)`,
                                                            }}
                                                          />
                                                        </NoMaxWidthTooltip>

                                                        {verifiedFeature && (
                                                          <MDBox
                                                            sx={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                            className="font-unset ai-checked"
                                                          >
                                                            <NoMaxWidthTooltip
                                                              style={{
                                                                backgroundColor:
                                                                  "white !important",
                                                              }}
                                                              title={
                                                                verifiedFeature
                                                                  ? verifiedFeature.file_name_map.map(
                                                                      (
                                                                        file
                                                                      ) => {
                                                                        return (
                                                                          <MDBox
                                                                            sx={{
                                                                              color:
                                                                                "white !important",
                                                                            }}
                                                                          >
                                                                            AI
                                                                            Found
                                                                            the
                                                                            feature
                                                                            in{" "}
                                                                            <br />
                                                                            <b>
                                                                              File
                                                                              Type:{" "}
                                                                            </b>
                                                                            {
                                                                              file.file_feature_name
                                                                            }{" "}
                                                                            <br />
                                                                            <b>
                                                                              File
                                                                              Name:{" "}
                                                                            </b>{" "}
                                                                            {
                                                                              file.file_name
                                                                            }
                                                                          </MDBox>
                                                                        );
                                                                      }
                                                                    )
                                                                  : ""
                                                              }
                                                            >
                                                              <FontAwesomeIcon
                                                                size={"medium"}
                                                                icon={faCheck}
                                                                style={{
                                                                  color:
                                                                    "green",
                                                                  fontSize:
                                                                    "medium !important",
                                                                }}
                                                                className="ai-checked-icon"
                                                              />
                                                            </NoMaxWidthTooltip>
                                                          </MDBox>
                                                        )}
                                                      </Grid>
                                                    );
                                                  }
                                                })}
                                            </Grid>
                                          </Form>
                                          {state[
                                            featureType + "FeaturesIsEmpty"
                                          ] == false && (
                                            <MDBox
                                              sx={{
                                                display: "flex",
                                                justifyContent: "end",
                                                margin: "2rem 2rem 1rem",
                                              }}
                                            >
                                              <Controls.Button
                                                text={"Update"}
                                                color={"default"}
                                                onClick={(e) =>
                                                  updateStep(stepName)
                                                }
                                              />
                                            </MDBox>
                                          )}
                                        </MDBox>
                                        {/* Files */}
                                        <CaseFiles
                                          featureType={featureType}
                                          stepName={stepName}
                                          key={caseFileKey}
                                        />
                                      </MDBox>
                                    );
                                  })}
                              </MDBox>
                            )}
                          </Card>
                        )
                      );
                    })}
                  </MDBox>
                )}

                {/* Next Step Card */}
                <Card sx={{ margin: 5, marginTop: 2, marginBottom: 1 }}>
                  <MDBox
                    mx={0}
                    mt={0}
                    mb={2}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowCards({
                        ...showCards,
                        stepChange: !showCards.stepChange,
                      });
                    }}
                  >
                    <MDTypography variant="h6" color="white">
                      Step Change
                    </MDTypography>
                    <MDBox>
                      <Tooltip title={showCards.stepChange ? "Hide" : "Expand"}>
                        <FontAwesomeIcon
                          icon={
                            showCards.stepChange ? faChevronUp : faChevronDown
                          }
                          color={"white"}
                          style={{ marginRight: "0.5rem" }}
                        ></FontAwesomeIcon>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  {showCards["stepChange"] && (
                    <Grid spacing={2} mb={2} minHeight={"10rem"}>
                      <Grid item pt={4} xs={10} md={10} ml={10} mr={3} pb={3}>
                        <Grid
                          container
                          sx={{ minHeight: 10 + status.length * 8 + "rem" }}
                        >
                          {caseDetails["Current Step"] && (
                            <MDBox
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                position: "absolute",
                              }}
                            >
                              <MDBox
                                id="elem1"
                                style={{
                                  ...boxStyle,
                                  maxWidth: "30%",
                                  position: "relative",
                                }}
                              >
                                <MDBox
                                  style={{
                                    fontSize: "small",
                                    position: "absolute",
                                    top: "-1rem",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                >
                                  Current Step
                                </MDBox>
                                {caseDetails["Current Step"].name}
                              </MDBox>
                              {status.map((item) => {
                                return (
                                  <>
                                    <MDTypography
                                      style={{
                                        position: "relative",
                                        top: "7rem",
                                        left: "20%",
                                      }}
                                    >
                                      {item.edge}
                                    </MDTypography>
                                    <MDBox
                                      id={item.value}
                                      style={{
                                        ...boxStyle,
                                        position: "relative",
                                        top: "5rem",
                                        left: "35%",
                                        maxWidth: "50%",
                                        flexDirection: "column",
                                      }}
                                      className={
                                        item.value == state.nextStep.name
                                          ? ["OOO", "Busy"].includes(
                                              item.agent_details?.active_status
                                            )
                                            ? " nodeBox-selected-red "
                                            : " nodeBox-selected "
                                          : ["OOO", "Busy"].includes(
                                                item.agent_details
                                                  ?.active_status
                                              )
                                            ? " nodeBox-red "
                                            : " nodeBox "
                                      }
                                      onClick={() => {
                                        handleSelectNextStep(
                                          item.value,
                                          item.agent_details.email_id,
                                          item.agent_details?.active_status
                                        );
                                      }}
                                    >
                                      <MDBox>{item.value}</MDBox>
                                      <MDBox style={{ fontSize: "small" }}>
                                        ({item.agent_details?.name})
                                      </MDBox>
                                      <MDBox style={{ fontSize: "small" }}>
                                        {item.agent_details?.active_status}
                                      </MDBox>
                                    </MDBox>
                                    {item.value == state.nextStep.name &&
                                      item.agent_details
                                        .secondary_contact_name && (
                                        <MDBox
                                          sx={{
                                            position: "absolute",
                                            right: "10rem",
                                          }}
                                        >
                                          <MDBox
                                            style={{
                                              fontSize: "small",
                                              position: "absolute",
                                              top: "-1rem",
                                              left: "50%",
                                              transform:
                                                "translate(-50%, -50%)",
                                              width: "max-content",
                                            }}
                                          >
                                            Assigned Agent
                                          </MDBox>
                                          <MDBox
                                            sx={{
                                              ...boxStyle,
                                              padding: "0.5rem",
                                              width: "100%",
                                              display: "flex",
                                              marginBottom: "0.5rem",
                                              justifyContent: "space-between",
                                            }}
                                            onClick={() => {
                                              handleSelectNextStep(
                                                item.value,
                                                item.agent_details.email_id
                                              );
                                            }}
                                            className={
                                              item.agent_details.email_id ==
                                              state.nextStep.emailId
                                                ? ["OOO", "Busy"].includes(
                                                    item.agent_details
                                                      ?.active_status
                                                  )
                                                  ? " nodeBox-selected-red "
                                                  : " nodeBox-selected "
                                                : ["OOO", "Busy"].includes(
                                                      item.agent_details
                                                        ?.active_status
                                                    )
                                                  ? " nodeBox-red "
                                                  : " nodeBox "
                                            }
                                          >
                                            <MDBox
                                              style={{
                                                fontSize: "small",
                                              }}
                                            >
                                              ({item.agent_details.name})
                                            </MDBox>
                                            <MDBox
                                              style={{
                                                fontSize: "small",
                                              }}
                                            >
                                              {item.agent_details.active_status}
                                            </MDBox>
                                          </MDBox>
                                          <MDBox
                                            sx={{
                                              ...boxStyle,
                                              width: "100%",
                                              padding: "0.5rem",
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                            onClick={() => {
                                              handleSelectNextStep(
                                                item.value,
                                                item.agent_details
                                                  .secondary_contact_email
                                              );
                                            }}
                                            className={
                                              item.agent_details
                                                .secondary_contact_email ==
                                              state.nextStep.emailId
                                                ? " nodeBox-selected "
                                                : " nodeBox "
                                            }
                                          >
                                            <MDBox>
                                              (
                                              {
                                                item.agent_details
                                                  .secondary_contact_name
                                              }
                                              )
                                            </MDBox>
                                            <MDBox>
                                              {
                                                item.agent_details
                                                  .secondary_contact_active_status
                                              }
                                            </MDBox>
                                          </MDBox>
                                        </MDBox>
                                      )}
                                    <Xarrow
                                      start="elem1"
                                      end={item.value}
                                      showHead={false}
                                      startAnchor={{
                                        position: "bottom",
                                        offset: { leftness: -30 },
                                      }}
                                      path={"grid"}
                                    />
                                  </>
                                );
                              })}
                            </MDBox>
                          )}
                        </Grid>
                      </Grid>
                      {state.nextStep.name && (
                        <MDBox
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            margin: "0rem 2rem 1rem",
                          }}
                        >
                          <Grid item xs={12} sm={12}>
                            <TextField
                              id="message"
                              label="Message"
                              onChange={(e) =>
                                handleSelectNextStepMessage(e.target.value)
                              }
                              placeholder="Message to be sent..."
                              name="message"
                              size="small"
                              multiline
                              fullWidth
                              variant="outlined"
                              rows={5}
                            />
                          </Grid>
                        </MDBox>
                      )}
                      {state.nextStep.name && (
                        <MDBox
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            margin: "0rem 2rem 1rem",
                          }}
                        >
                          <Controls.Button
                            text={"Next Step"}
                            onClick={(e) => handleStepChange()}
                          />
                        </MDBox>
                      )}
                    </Grid>
                  )}
                </Card>

                {/* Grouped Cases Card */}
                <Card sx={{ margin: 5, marginBottom: 1 }}>
                  <MDBox
                    mx={0}
                    mt={0}
                    mb={2}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowCards({
                        ...showCards,
                        groupedCases: !showCards.groupedCases,
                      });
                    }}
                  >
                    <MDTypography variant="h6" color="white">
                      Grouped Cases
                    </MDTypography>
                    <MDBox>
                      <Tooltip
                        title={showCards.groupedCases ? "Hide" : "Expand"}
                      >
                        <FontAwesomeIcon
                          icon={
                            showCards.groupedCases ? faChevronUp : faChevronDown
                          }
                          color={"white"}
                          style={{ marginRight: "0.5rem" }}
                        ></FontAwesomeIcon>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  {showCards["groupedCases"] && (
                    <Grid spacing={2} mb={2} minHeight={"10rem"}>
                      <GroupedCaseList />
                    </Grid>
                  )}
                </Card>

                {/* Case Details Card */}
                <LoadingOverlay
                  active={isLoadingCaseDetials}
                  spinner
                  text="Loading your content..."
                >
                  <Card sx={{ margin: 5 }}>
                    <MDBox
                      mx={0}
                      mt={0}
                      mb={2}
                      py={2}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <MDTypography variant="h6" color="white">
                        Case Details
                      </MDTypography>
                    </MDBox>
                    <Grid spacing={2} mb={2} minHeight={"10rem"}>
                      <Grid item pt={4} xs={10} md={10} ml={3} mr={3} pb={3}>
                        <Grid container>
                          {Object.keys(caseDetails).map((item) => {
                            if (caseDetails[item].show != false)
                              return (
                                <>
                                  <Grid
                                    item
                                    xs={4}
                                    display={"flex"}
                                    justifyContent={"end"}
                                    marginRight={"2rem"}
                                    fontSize={"medium"}
                                    alignItems={"center"}
                                  >
                                    {item}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={5}
                                    display={"flex"}
                                    justifyContent={"center"}
                                  >
                                    <Controls.Button
                                      fullWidth
                                      variant="outlined"
                                      color="primary"
                                      text={caseDetails[item].name}
                                    />
                                  </Grid>
                                  {caseDetails[item].change &&
                                    item != "Current Step" && (
                                      <Grid
                                        item
                                        xs={2}
                                        display={"flex"}
                                        justifyContent={"left"}
                                        alignItems={"center"}
                                        paddingLeft={"1rem"}
                                      >
                                        <Tooltip title={"Change " + item}>
                                          <FontAwesomeIcon
                                            size={"lg"}
                                            icon={faEdit}
                                            cursor={"pointer"}
                                            onClick={caseDetails[item].change}
                                          />
                                        </Tooltip>
                                      </Grid>
                                    )}
                                </>
                              );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </LoadingOverlay>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox
          sx={{
            position: "fixed",
            right: "0",
            bottom: "2rem",
            width: "30rem",
            zIndex: "1000",
          }}
        >
          {showComments && (
            <SideChat handleClose={setShowComments} caseId={params.case_id} />
          )}
        </MDBox>
        <Tooltip
          title={"I am your AI Assistant, I can help you with the case."}
          placement="left"
          
        >
          <lottie-player
            onClick={() => setShowAIAgent(!showAIAgent)}
            src="https://lottie.host/56b2c8f6-5525-4c17-96be-87ad65de28de/LL0Sw3bEMT.json"
            background="transparent"
            speed="1"
            style={{
              width: "65px",
              height: "65px",
              position: "fixed",
              right: "2rem",
              bottom: "2rem",
              cursor: "pointer",
            }}
            loop
            autoplay
            direction="1"
            mode="normal"
          ></lottie-player>
        </Tooltip>
      </MDBox>

      {/* Dialog Forms */}
      {showMail && (
        <EmailForm
          open={showMail}
          handleClose={setShowMail}
          mailDetails={{ case_id: params.case_id }}
          caseDetails={{
            "Case Id": { name: params.case_id },
            ...caseDetails,
          }}
        ></EmailForm>
      )}

      {showWorkflowChange && (
        <WorkFlowChange
          open={showWorkflowChange}
          handleClose={setShowWorkflowChange}
          caseDetails={{
            caseId: params.case_id,
            current_workflow: caseDetails.workflow_name,
          }}
        ></WorkFlowChange>
      )}

      {showAgentChange && (
        <AgentChange
          open={showAgentChange}
          handleClose={setShowAgentChange}
          caseDetails={{ case_id: params.case_id }}
        ></AgentChange>
      )}

      {showStatusChange && (
        <StatusChange
          open={showStatusChange}
          handleClose={setShowStatusChange}
          caseDetails={{ case_id: params.case_id }}
        ></StatusChange>
      )}

      {showStateChange && (
        <StateChange
          open={showStateChange}
          handleClose={setShowStateChange}
          handleReload={getCaseDetails}
          caseDetails={{ case_id: params.case_id }}
        ></StateChange>
      )}

      {showNotificationHistory && (
        <DialogWrapper handleClose={() => setShowNotificationHistory(false)}>
          <NotificationHistory type={"notification"} />
        </DialogWrapper>
      )}

      {showCaseTransferHistory && (
        <DialogWrapper handleClose={() => setShowCaseTransferHistory(false)}>
          <NotificationHistory type={"caseTransfer"} />
        </DialogWrapper>
      )}

      {showAIAgent && (
        <DialogWrapper handleClose={() => setShowAIAgent(false)} width={"35%"}>
          <AIAgent type={"caseTransfer"} reloadContents={reloadContents} />
        </DialogWrapper>
      )}
    </DashboardLayout>
  );
}

export default Forms;
