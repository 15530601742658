import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import UploadFileCard from "layouts/forms/file/uploadFileCard";
import { LottieFiles } from "utils/lottieFiles";

function FileUpdateAction({ action, index, handlePerformAction, loaders }) {
  const uploadFiles = (stepName, fileListName, fileList) => {
    const formdata = new FormData();
    let files_feature_list = [];
    Array.from(fileList).forEach((file) => {
      files_feature_list.push(fileListName);

      formdata.append("files_list", file);
    });
    formdata.append("files_feature_list", files_feature_list);

    handlePerformAction(action, formdata);
  };

  return (
    <>
      <MDBox
        sx={{
          fontWeight: "500",
          margin: "0.5rem",
          display: "flex",
          alignItems: "center",
          marginLeft: "0",
        }}
      >
        {(action == null || action) && (
          <lottie-player
            key={
              loaders["file_action"]
                ? "loading"
                : action
                ? "failure"
                : "success"
            }
            src={
              LottieFiles[
                loaders["file_action"]
                  ? "loading"
                  : action
                  ? "failure"
                  : "success"
              ]
            }
            background="transparent"
            speed="1"
            style={{
              minWidth:"2rem",
              minHeight:"2rem",
              height: "2rem",
              width: "2rem",
            }}
            {...(loaders["file_action"] ? { loop: true } : {})}
            keepLastFrame={true}
            autoplay
            direction="1"
            mode="normal"
          ></lottie-player>
        )}
        Files Required{" "}
        {!loaders["file_action"] && action ? (
          <>
            <MDBox sx={{ whiteSpace: "nowrap", marginRight: "0.5rem" }}>
              &nbsp; -&gt;
            </MDBox>
            <MDBox sx={{ fontWeight: "400" }}>
               Please Upload these Files
            </MDBox>
          </>
        ) : (
          ""
        )}
      </MDBox>
      {!loaders["file_action"] && action && (
        <MDBox sx={{ paddingLeft: "2rem" }}>
          <MDBox sx={{ fontWeight: "400" }}>
            {/* &nbsp;<b>File Upload: </b> */}
            {/* {action.file_step_name} -&gt;  */}
            {action.file_feature_name}
          </MDBox>
          <MDBox sx={{ position: "relative" }}>
            <UploadFileCard
              fileName={action.file_feature_name}
              length={0}
              uploadFiles={uploadFiles}
              stepName={action.file_step_name}
            />
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default FileUpdateAction;
