// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import Checkbox from "@mui/material/Checkbox";

// Images
import { bulkStatAPI } from "api";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";

export default function resolveData({ setIsLoadingResolved }) {
  const navigation = useNavigate();
  const [resolveAPI, setResolveAPI] = useState([]);

  const [resolvRows, setResolveRows] = useState([]);

  const [resolveSelects, setResolveSelects] = useState([]);
  const [changeValue, setChangeValue] = useState(true);
  const [resolveColumns, setResolveColumns] = useState([]);

  const fetchResolveAPI = useCallback(async () => {
    setIsLoadingResolved(true);
    try {
      const res = await bulkStatAPI.getResolve();
      const updatedData = res.data.map((item) => ({
        ...item,
        // checked: false,
      }));
      setResolveAPI(updatedData);
      setIsLoadingResolved(false);
    } catch (err) {
      setIsLoadingResolved(false);
      if ([401].includes(err.response?.status)) {
        
        setGlobalState("error", {
          open: true,
          message: "Login Expired!",
          type: "error",
        });
      }
    }
  }, []);

  const changeResolveValues = useCallback(async () => {
    setChangeValue((prev) => !prev);
    setResolveSelects([])
  }, []);

  useEffect(() => {
    fetchResolveAPI();
  }, [changeValue]);

  const getNewResolve = useCallback(async () => {
    const tempRows = [];

    resolveAPI
      ? (() => {
        const initColumns = resolveAPI && resolveAPI.length > 0 ? Object.keys(resolveAPI[0]) : [];
          // initColumns.push("select");
          // const columns = initColumns.filter((column) => column !== "checked");
          setResolveColumns(initColumns);
          resolveAPI.map((caseDetail, key) => {
            const rows = {};
            for (const column of initColumns) {
              rows[column] = caseDetail[column];
            }
            tempRows.push(rows);
            // const row = {
            //   ReferenceKey: caseDetail["Reference Key"],
            //   statusComment: caseDetail["Status Comment"],
            //   customer: caseDetail.Customer,
            //   select: <Checkbox checked={caseDetail.checked} />,
            // };
            // tempRows.push(row);
          });
        })()
      : null;
    setResolveRows(tempRows);
  }, [resolveAPI]);

  useEffect(() => {
    getNewResolve();
  }, [getNewResolve]);

  return [
    resolvRows,
    resolveAPI,
    setResolveAPI,
    setResolveSelects,
    resolveSelects,
    changeResolveValues,
    resolveColumns
  ];
}
