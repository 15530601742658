//  React base styles
import colors from 'assets/theme-dark/base/colors'

const { text } = colors

const formLabel = {
  styleOverrides: {
    root: {
      color: text.main
    }
  }
}

export default formLabel
