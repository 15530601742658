// Pre Update functions to be executed recursively

import { toFloatMark } from "utils/conversion";

function getRemainingSumValue(numVal, dictVal) {
  if (numVal && dictVal) {
    let dictSum = Object.values(dictVal).reduce(
      (a, b) => toFloatMark(String(a)) + toFloatMark(String(b)),
      0
    );
    if (numVal == dictSum) {
      return true;
    } else {
      return numVal - dictSum;
    }
  } else {
    return true;
  }
}

export const PreValidations = {
  C1: getRemainingSumValue,
};

//   Validation functions to be executed in a queue upon update
function checkSumNumberAndDict(numName, numVal, dictName, dictVal) {
  let dictSum = Object.values(dictVal).reduce(
    (a, b) => toFloatMark(String(a)) + toFloatMark(String(b)),
    0
  );
  if (numVal == dictSum) {
    return true;
  } else if (numVal > dictSum) {
    return `Validation Error: The ${numName} exceeds sum of values of ${dictName} by ${
      numVal - dictSum
    }. Please rectify ${dictName}.`;
  }
  return `Validation Error: The sum of values ${dictName} exceeds ${numName} by ${
    dictSum - numVal
  }. Please rectify ${dictName}.`;
}

function checkFieldFilled(value, dictVal) {
  if (dictVal["Invalid"] > 0) {
    if (value) {
      return true;
    } else {
      return `Validation Error: Reason for Invalidation is empty`;
    }
  }
  return true
}

export const Validations = {
  C1: checkSumNumberAndDict,
  C2: checkFieldFilled,
};
