// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import { ArrowRightRounded, Send } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, TextField, Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";

import { Typography, Paper, InputLabel, Box, Fab, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { caseAPI } from "api";
import { setGlobalState } from "Global";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { workflowAPI } from "api";

function NameChange({ open, handleClose, customerDetails, callbackFunction }) {
  const [state, setState] = useState(customerDetails);
  const navigation = useNavigate();

  const convertKeyToName = (key) => {
    let name = key
      .split("_")
      .map((word) => {
        return word[0].toUpperCase() + word.slice(1);
      })
      .join(" ");
    return name;
  };

  const handleChange = (event) => {
    setState({ ...state, value: event.target.value });
  };

  const handleDataChange = () => {
    workflowAPI
      .updateCustomerCodeMaps({
        customer_code: state.customer_code,
        [state.key]: state.value,
      })
      .then((res) => {
        if (res.status == 200) {
          handleClose(!open);
          callbackFunction();
        }
      })
      .catch((error) => {});
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", minWidth: "30rem" }}
      >
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2 }}>
            Change {convertKeyToName(state.key)}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  value={state.customer_code}
                  name={"customer_code"}
                  label={"Customer Code"}
                  variant="outlined"
                  fullWidth
                  size="small"
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label={convertKeyToName(state.key)}
                onChange={handleChange}
                name={state.key}
                size="small"
                value={state.value}
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={2} md={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                p={1}
              >
                <Button
                  size="medium"
                  color="success"
                  variant="contained"
                  onClick={handleDataChange}
                >
                  Change
                </Button>
              </MDBox>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default NameChange;
