import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useGlobalState, setGlobalState } from "Global";
import { useEffect } from "react";

export default function Banner() {
  const [banner] = useGlobalState("banner");

  const setOpen = () => {
    setGlobalState("banner", { ...banner, open: false });
  };

//   useEffect(() => {
//     setOpen();
//   }, [banner.open]);

  return (
    <Collapse
      in={banner.open}
      sx={{
        position: "fixed",
        top: "2rem",
        left: "5%",
        right: "5%",
        width: "90%",
        zIndex: "100000",
      }}
    >
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity={banner.type}
        sx={{ mb: 2 }}
      >
        {banner.message}
      </Alert>
    </Collapse>
  );
}
