import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  Input,
} from "@material-ui/core";
import { KeyValue } from "react-key-value";
import { Grid } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon } from "@material-ui/core";
import { Tooltip,Card,Typography } from "@mui/material";
import CategoryDialog from "./CategoryDialog";
import { useGlobalState, setGlobalState } from "Global";
import { toFloatMark } from "utils/conversion";

export default function KeyValueSelect(props) {
  const {
    name,
    label,
    value,
    onChange,
    keyscopes,
    disabled,
    stepDetails,
    amount,
  } = props;
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);

  const [categories, setCategories] = useState({
    "Not decided": stepDetails["Claim Amount (ZAR)"].value,
  });

  const [totalAmount, setTotalAmount] = useState(
    stepDetails["Claim Amount (ZAR)"].value
  );
  const [currentAmount, setCurrentAmount] = useState(0);

  const handleAmountChange = (event) => {
    let value = event.target.value;
    if (value) {
      let newVal = toFloatMark(value);

      if (value.slice(-1) == ".") {
        value = newVal + ".";
      } else if (value.slice(-2) == ".0") {
        value = newVal + ".0";
      } else {
        value = String(newVal);
      }
    }
    let currAmount = currentAmount - categories[event.target.name] + value;
    if (value != "") {
      if (currAmount > totalAmount)
        throwError(
          "Entered Category amount makes Category total more than Claim Amount"
        );
      else {
        setCategories({
          ...categories,
          [event.target.name]: value,
          "Not decided": totalAmount - currAmount,
        });
        setCurrentAmount(currAmount);
      }
    } else {
      setCategories({
        ...categories,
        [event.target.name]: 0,
        "Not decided": totalAmount - currAmount,
      });
      setCurrentAmount(currAmount);
    }
  };

  useEffect(() => {
    onChange({ target: { name: name, value: categories } });
  }, [categories]);

  const handleAddNewItem = (categoryName, amount) => {
    let newCurrentAmount = currentAmount + amount;
    let unused = totalAmount - newCurrentAmount;

    if (newCurrentAmount > totalAmount)
      throwError(
        "Entered Category amount makes Category total more than Claim Amount"
      );
    else {
      setCategories({
        [categoryName]: amount,
        ...categories,
        "Not decided": unused,
      });
      setCurrentAmount(newCurrentAmount);
    }
  };

  const throwError = (message) => {
    setGlobalState("error", {
      open: true,
      message: message,
      type: "error",
    });
  };

  const handleDeleteItem = (category) => {
    let currAmount = currentAmount - categories[category];
    let unused = totalAmount - currAmount;
    setCurrentAmount((prevAmount) => {
      return currAmount;
    });
    setCategories((prevCategory) => {
      // 👇️ create copy of state object
      const copy = { ...prevCategory };
      copy["Not decided"] = unused;
      delete copy[category];
      return copy;
    });
  };

  const initializeCategories = () => {
    let tempCategories = {};
    let tempAmount = parseFloat("0");
    if (value)
      Object.keys(value).forEach((category) => {
        if (category != "Not decided") {
          tempAmount += parseFloat(value[category]);
          tempCategories[category] = value[category];
        }
      });
    setCategories({
      ...tempCategories,
      "Not decided": totalAmount - tempAmount,
    });
    setCurrentAmount(tempAmount);
  };

  useEffect(() => {
    initializeCategories();
  }, [totalAmount]);

  useEffect(() => {
    if (totalAmount - amount > categories["Not decided"]) {
      throwError(
        "Please delete categories before reducing the amount ( Entered Claim Amount is less than categories claim amount total ) "
      );
    } else if (amount != "") {
      setTotalAmount(amount);
    }
  }, [amount]);

  return (
    <FormControl>
            <Card
        sx={{ border: "1px solid lightgrey !important", padding: "0.5rem"}}
        {...props}
      >
      <MDBox
        sx={{
          display: "flex",
          width: "100%",

          justifyContent: "space-between",
        }}
      >
                  {label && (
            <Typography
              variant="headline"
              sx={{ fontSize: "1rem !important", fontWeight: "400" }}
              className={"font-unset"}
            >
              {" "}
              {name}{" "}
            </Typography>)}
        {!disabled && (
          <Tooltip title={"Add New Item"} className={"font-unset"}>
            <Icon
              sx={{ cursor: "pointer", fontSize: "large" }}
              onClick={() => {
                setShowCategoryDialog(!showCategoryDialog);
              }}
            >
              add
            </Icon>
          </Tooltip>
        )}
      </MDBox>
      <MDBox
        sx={{
          padding: "1.5rem",
          marginTop: "1rem",
          paddingRight:"1.5rem",
          paddingTop: "0",
        }}
      >
        {categories.length != 0 &&
          Object.keys(categories).map((category, index) => {
            return (
              <MDBox sx={{ display: "flex", position: "relative" }} key={index}>
                <MDBox
                  sx={{
                    display: "flex",
                    width: "100%",
                    textAlign: "right",
                    float: "right",
                    padding: "0.5rem",
                    marginBottom: "0.5rem",
                    justifyContent: "space-between",
                    border: "0.5px solid grey",
                    position: "relative",
                    borderRadius: "5px",
                  }}
                >
                  <MDTypography sx={{ textAlign: "left" }}>
                    {category}
                  </MDTypography>
                  <MDBox sx={{ display: "flex", justifyContent: "right" }}>
                    <Input
                      value={
                        (category == "Not decided")
                          ? categories[category]?.toFixed(2)
                          : categories[category]
                      }
                      name={category}
                      parentName={name}
                      inputProps={{ style: { textAlign: "center" } }}
                      style={{
                        maxWidth: "50%",
                        textAlign: "center",
                        display: "block",
                      }}
                      onChange={handleAmountChange}
                      disabled={
                        category == "Not decided" || disabled ? true : false
                      }
                    />
                  </MDBox>
                </MDBox>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    right: "-2rem",
                    top: "45%",
                    transform: "translate(0,-50%)",
                    position: "absolute",
                  }}
                >
                  <Tooltip
                    title={"Delete"}
                    sx={{ cursor: "pointer", fontSize: "large" }}
                    className={"font-unset"}
                  >
                    {category != "Not decided" && (
                      <Icon onClick={(e) => handleDeleteItem(category)}>
                        delete
                      </Icon>
                    )}
                  </Tooltip>
                </MDBox>
              </MDBox>
            );
          })}
      </MDBox>

      {showCategoryDialog && (
        <CategoryDialog
          open={showCategoryDialog}
          handleClose={setShowCategoryDialog}
          updateCategoryList={handleAddNewItem}
          categoryList={keyscopes.filter((category) => {
            if (!categories[category]) {
              return true;
            } else {
              return false;
            }
          })}
        ></CategoryDialog>
      )}
      </Card>
    </FormControl>
  );
}
