import { Card } from "@mui/material";
import { setGlobalState } from "Global";
import { AIAgentAPI } from "api";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { caseAPI } from "api";
import MDButton from "components/MDButton";
import CustomAutoComplete from "components/controls/AutoComplete";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import EmptyResult from "utils/emptyResult";
import AutoTransferAction from "./actions/autoTransferAction";
import FileUpdateAction from "./actions/fileUpdateAction";
import StepChangeAction from "./actions/stepChangeAction";
import FeatureUpdateAction from "./actions/featureUpdateAction";
import { format } from "date-fns";
import { toFloatMark } from "utils/conversion";
import { Height } from "@mui/icons-material";
import Delayed from "utils/delayed";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

function PerformActions({
  actionList = [],
  fileAction,
  featureAction,
  transferAction,
  noStepChange,
  stepChange,
  setIsLoading,
  isLoading,
  reloadSuggestions,
  reloadContents,
  sendCustomMessage,
  updateMapping,
  loaders,
  setLoaders
}) {
  const [actions, setActions] = useState([]);
  const params = useParams();
  const [agents, setAgents] = useState([]);

  const handleChange = (e, actionType, type = "Text") => {
    let { name, value } = e.target;

    if (type == "Date") {
      value = format(value, "yyyy-MM-dd");
    } else if (type == "Integer") {
      if (value) {
        let newVal = toFloatMark(value);
        if (value.slice(-1) == ".") {
          value = newVal + ".";
        } else if (value.slice(-2) == ".0") {
          value = newVal + ".0";
        } else {
          value = newVal;
        }
      }
    }

    if (e.target.value?.email_id) {
      value = e.target.value.email_id;
    }

    let dynamicAction = updateMapping[actionType];

    dynamicAction((prevAction) => {
      return { ...prevAction, value: value };
    });
  };

  const handlePerformAction = (action, data = {}) => {
    setIsLoading(true);

    if(action.name=="file_action"){
      setLoaders((prevLoaders)=>{
        return {...prevLoaders,file_action:true}
      })
      AIAgentAPI.performCaseAction(
        { case_id: params.case_id, data: JSON.stringify(action) },
        data
      )
        .then((res) => {
          if (res.status == 200) {
            // reloadSuggestions();
            sendCustomMessage({tasks:["file_action","suggestion"]})
            // setLoaders((prevLoaders)=>{
            //   return {...prevLoaders,file_action:false}
            // })
            reloadContents();
          }
        })
        .catch((error) => {
          setLoaders((prevLoaders)=>{
            return {...prevLoaders,file_action:false}
          })

        });
    }else{
      sendCustomMessage({name:action.name,value:action,type:"action"});

      setLoaders((prevLoaders)=>{
        return {...prevLoaders,[action.name]:true}
      })
      
    }

  };
  const getAgents = () => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAgents(res.data);
        }
      })
      .catch((error) => {

      });
  };

  useEffect(() => {
    getAgents();
  }, []);

  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <MDBox
        sx={{
          width: "100%",
          padding: "0rem 1rem 1rem 1rem",
          margin: "0rem 0.5rem 1rem 0.5rem ",
          position: "relative",
          marginBottom: "1rem",
        }}
      >
        <MDBox sx={{ justifyContent: "center" }}>
          <MDBox
            sx={{
              // marginLeft: "0.5rem",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              fontSize: "1.3rem !important",
              color: "green",
            }}
            className={"font-unset"}
          >
            Actions
          </MDBox>
          Actions to be performed before moving to next step.
        </MDBox>

        <Delayed waitBeforeShow={0}>
          <FeatureUpdateAction
            action={featureAction}
            handleChange={handleChange}
            handlePerformAction={handlePerformAction}
            loaders={loaders}
          />
        </Delayed>

        <Delayed waitBeforeShow={1000}>
          <FileUpdateAction
            action={fileAction}
            handlePerformAction={handlePerformAction}
            loaders={loaders}
          />
        </Delayed>

        <Delayed waitBeforeShow={2000}>
          <AutoTransferAction
            agents={agents}
            action={transferAction}
            handleNotificationChange={handleChange}
            handlePerformAction={handlePerformAction}
            loaders={loaders}
          />
        </Delayed>

        <Delayed waitBeforeShow={2000}>
          <StepChangeAction
            agents={agents}
            action={stepChange}
            handleNotificationChange={handleChange}
            handlePerformAction={handlePerformAction}
            loaders={loaders}
          />
        </Delayed>


        {noStepChange && !(fileAction || featureAction) &&
        <MDBox sx={{fontSize:"0.74rem !important",paddingTop:"0.5rem",color:"tomato"}} className={"font-unset"}>* {noStepChange.reason_for_decision}</MDBox>}

        {/* {isLoading == false && actions.length == 0 && <EmptyResult />} */}
      </MDBox>
    </MDBox>
  );
}

export default PerformActions;
