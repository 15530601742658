// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import { Send } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, TextField, Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";

import { Typography, Paper, InputLabel, Box, Fab, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { caseAPI } from "api";
import { setGlobalState } from "Global";
import MDButton from "components/MDButton";
import LoadingOverlay from "react-loading-overlay";

function EmailForm({ open, handleClose, mailDetails, caseDetails }) {
  const [state, setState] = useState({
    ...mailDetails,
    body: "",
    case_details: "",
  });
  const location = useLocation();
  const navigation = useNavigate();
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const getCaseSummary = () => {
    let tempCaseDetails = "";
    Object.keys(caseDetails).forEach((item) => {
      tempCaseDetails += ` ${item} : ${caseDetails[item].name} \n`;
    });
    let caseSummary = tempCaseDetails;
    return caseSummary;
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let tempState = state;
    caseAPI
      .sendMail(tempState)
      .then((res) => {
        setIsLoading(false);
        setGlobalState("error", {
          open: true,
          message: "Successfully sent the notification",
          type: "success",
        });
        handleClose(!open);
      })
      .catch((error) => {
        setIsLoading(false);
        if ([422].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message: "The required fields are missing",
            type: "error",
            code: error.response.status
          });
        }
      });
  };

  useEffect(() => {
    setState({ ...state, case_details: getCaseSummary() });
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAgents(res.data);
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", maxWidth: "30rem" }}
      >
        <LoadingOverlay
          active={isLoading}
          spinner
          text="Loading your content..."
        >
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2 }}>
              Send Notification
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="send-mail-label">Email to</InputLabel>
                  <Select
                    id="emailTo"
                    labelId="send-mail-label"
                    value={state.email_to}
                    name="emailTo"
                    label={"Email to"}
                    onChange={handleChange}
                    sx={{ marginLeft: "2", width: "100%", minHeight: "3.5rem" }}
                  >
                    {agents.map((agent) => {
                      return (
                        <MenuItem
                          sx={{ width: "100%", minHeight: "3.5rem" }}
                          value={agent.email_id}
                          key={agent.email_id}
                        >
                          {agent.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="emailCC"
                  name="emailCC"
                  label="CC"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="subject"
                  name="subject"
                  label="Subject"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  required
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="case_details"
                  label="Details"
                  name="case_details"
                  onChange={handleChange}
                  multiline
                  fullWidth
                  rows={6}
                  disabled
                  defaultValue={state.case_details}
                  style={{ overflow: "hidden !important" }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="body"
                  label="Body *"
                  name="body"
                  onChange={handleChange}
                  multiline
                  fullWidth
                  rows={8}
                  defaultValue={state.body}
                />
              </Grid>

              <Grid item xs={2} md={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                  p={1}
                >
                  <Button
                    size="medium"
                    color="success"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    <Send sx={{ mr: 1 }} />
                    Send Mail
                  </Button>
                </MDBox>
              </Grid>
            </Grid>
          </Box>
        </LoadingOverlay>
      </Paper>
    </Dialog>
  );
}

export default EmailForm;
