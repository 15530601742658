import MDBox from "components/MDBox";
import RandomColor from "utils/randomColor"


const getInitials = (name) => {
  let initials = "";
  if (name) {
    initials = name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  }
  return initials;
};



function InitialsAvatar({ name,sx, ...props }) {
  const saturationRange = [55, 100];
  const lightnessRange = [35, 75];

  const initials = getInitials(name);
  const color = RandomColor(initials, saturationRange, lightnessRange);

  const iconStyle = {
    width: "100%",
    height: "auto",
    
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.7rem",
    color: "white !important",
    aspectRatio:"2/1",
    backgroundColor:color
  };

  return (
    <MDBox sx={{...iconStyle,...sx}}  {...props}>
      {initials}
    </MDBox>
  );
}


export default InitialsAvatar;
