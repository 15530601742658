import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

export default function Bullet(props) {
  const { name, label, value, onChange,disabled } = props;
  const getSelected = () => {
    return Object.keys(value).map((item) => {
      if (value[item] == true) return item;
    })[0];
  };
  return (
    <FormControl disabled={disabled}>
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup row name={name} value={getSelected()}  onChange={onChange}>
        {Object.keys(value).map((item) => (
          <FormControlLabel
            key={item}
            value={item}
            control={<Radio />}
            label={item}
            checked={value[item]}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}
