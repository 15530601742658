/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import { AvatarGenerator } from "random-avatar-generator";
import MDAvatar from "components/MDAvatar";
import IsLoadingHOC from "utils/LoadingHOC";

// Images
import { agentAPI } from "api";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";
import { toDecimalMark } from "../../../utils/conversion";
import MDButton from "components/MDButton";
const CaseListGrouped = (props) => {
  const [rows, setRows] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [excelRows, setExcelRows] = useState([]);

  const [excelColumns, setExcelColumns] = useState([]);
  const location = useLocation();
  const navigation = useNavigate();

  const caseStateMapping = {
    New: "warning",
    "In-Progress": "info",
    Resolved: "success",
  };

  const chartToFilterMapping = {
    AgeBucket: "age_bucket",
    Workflow: "workflow_id",
    Customer: "customer_name",
    ReasonCodes: "reason_codes",
    SKU: "sku",
    AssignedAgent: "assigned_agent",
    Category: "category",
    CaseState: "case_state",
    ClaimType: "claim_type",
    days: "days",
    limit: "limit",
    CustomerCode:"customer_code",
    InvoiceNumber:"invoice_number",
    ReferenceKey:"reference_key",
    search:"search",
    offset: "offset",
  };

  const convertKeyToName = (key) => {
    let name = key
      .split("_")
      .map((word) => {
        return (word[0] ? word[0].toUpperCase() : "") + word.slice(1);
      })
      .join(" ");
    return name;
  };

  const setUserCases = (res) => {
    const tempRows = [];
    const tempCases = res.data;
    let initColumns =
      tempCases && tempCases.length > 0 ? Object.keys(tempCases[0]) : [];
    setPageInfo({ count: res.count, pages: res.pages });

    tempCases.map((row, key) => {
      let rows = {};
      for (const column of initColumns) {
        if (typeof row[column] == "object") {
          if (column != "Category and claim amount") {
            rows[column] = Object.keys(row[column]).filter((item) => {
              return row[column][item] == true;
            })[0];
          }
        } else {
          rows[column] = row[column];
        }
      }
      tempRows.push(rows);
    });

    initColumns = initColumns.map((column) => {
      return {
        Header: convertKeyToName(column),
        accessor: column,
        align: "center",
      };
    });

    setExcelColumns(initColumns);
    setExcelRows(tempRows);

    return tempRows;
  };

  const convertToParams = (params) => {
    let tempParams = {};
    if (params) {
      Object.keys(params).forEach((param) => {
        tempParams[chartToFilterMapping[param]] = params[param];
      });
    }
    return tempParams;
  };

  useEffect(() => {
    if (props.caseListType != "default") {
      props.setIsLoading(true);
      const adminCases = location.pathname.includes("adminCases")
        ? "Admin"
        : "";
      const groupedValue = props.caseListType == "default" ? "" : "Grouped";
      const dynamicFunction =
        agentAPI[`getAllCases${adminCases}${groupedValue}`];

      dynamicFunction(
        convertToParams(props.filters),
        convertToParams(props.filters)
      )
        .then((res) => {
          setRows(setUserCases(res.data));
          props.setIsLoading(false);
        })
        .catch((error) => {
          props.setIsLoading(false);

        });
    }
  }, [location.pathname, props.filters, props.caseListType]);

  return {
    columns: excelColumns,

    rows: excelRows,
    pages: pageInfo,
  };
};

export default CaseListGrouped;
