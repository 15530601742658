// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import AnalyticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard/analytics";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";
// Data

// Dashboard components
import { useEffect, useState } from "react";
import {
  AppBar,
  Tab,
  Tabs,
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { caseDocumentAPI } from "api";
import DataTable from "examples/Tables/DataTable";

import inputData from "./InputTableData";
import updateData from "./UpdateTableData";
import resolveData from "./resolveTableData";
import { bulkStatAPI } from "api";
import LoadingOverlay from "react-loading-overlay";
import AccessWrapper from "wrappers/accessWrapper";
import excelData from "./ExcelData";
import rejectedData from "./RejectedData";
import resolveConflictData from "./resolveConflictTableData";

function Bulk(props) {
  const navigation = useNavigate();

  const [tabValue, setTabValue] = useState("0");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [showSuccess, setShowSuccess] = useState(false);
  const [inputAllChecked, setInputAllChecked] = useState(false);
  const [resolveAllChecked, setResolveAllChecked] = useState(false);
  const [updateAllChecked, setUpdateAllChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNew, setIsLoadingNew] = useState(false);
  const [isLoadingResolved, setIsLoadingResolved] = useState(false);
  const [isLoadingResolvedConflict, setIsLoadingResolvedConflict] =
    useState(false);

  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingRejected, setIsLoadingRejected] = useState(false);
  const [insertPageNo, setInsertPageNo] = useState(null);
  const [resolvePageNo, setResolvePageNo] = useState(null);
  const [updatePageNo, setUpdatePageNo] = useState(null);
  const [insertPageSize, setInsertPageSize] = useState(null);
  const [resolvePageSize, setResolvePageSize] = useState(null);
  const [updatePageSize, setUpdatePageSize] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const [
    insertRows,
    insertAPI,
    setInsertAPI,
    setInputSelects,
    inputSelects,
    changeInputValues,
    insertColumns,
  ] = inputData({ setIsLoadingNew: setIsLoadingNew });

  const [
    resolveRows,
    resolveAPI,
    setResolveAPI,
    setResolveSelects,
    resolveSelects,
    changeResolveValues,
    resolveColumns,
  ] = resolveData({ setIsLoadingResolved: setIsLoadingResolved });

  const [
    resolveconflictRows,
    resolveconflictAPI,
    setResolveConflictAPI,
    setResolveConflictSelects,
    resolveconflictSelects,
    changeResolveConflictValues,
    resolveconflictColumns,
  ] = resolveConflictData({
    setIsLoadingResolvedConflict: setIsLoadingResolvedConflict,
  });

  const [
    updateRows,
    updateAPI,
    setUpdateAPI,
    setUpdateSelects,
    updateSelects,
    changeUpdateValues,
    updateColumns,
  ] = updateData({ setIsLoadingUpdate: setIsLoadingUpdate });

  const [
    excelRows,
    excelAPI,
    setExcelAPI,
    setExcelSelects,
    excelSelects,
    changeExcelValues,
    excelColumns,
  ] = excelData({ setIsLoadingExcel: setIsLoadingExcel });

  const [
    rejectedRows,
    rejectedAPI,
    setRejectedAPI,
    setRejectedSelects,
    rejectedSelects,
    changeRejectedValues,
    rejectedColumns,
  ] = rejectedData({ setIsLoadingRejected: setIsLoadingRejected });

  const column_insert_update = insertColumns
    ? insertColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const column_resolve = resolveColumns
    ? resolveColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const column_resolveconflict = resolveconflictColumns
    ? resolveconflictColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const column_update = updateColumns
    ? updateColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const column_excel = excelColumns
    ? excelColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const column_rejected = rejectedColumns
    ? excelColumns.map((item) => ({
        Header: item,
        accessor: item,
        align: "center",
      }))
    : [];

  const handleInputButtonClick = async () => {
    try {
      setIsLoadingNew(true);
      setRefreshing(true)
      const statusRes = await bulkStatAPI.getInsertingStatus();
      const res = await bulkStatAPI.postInsert(inputSelects);
      if (res.status == 200) {
        setGlobalState("error", {
          open: true,
          message: res.data,
          type: "info",
        });
        // setInputAllChecked((prev) => !prev);
        changeInputValues();
      }
      InsertPollApi();
    } catch (error) {
      setIsLoadingNew(false);
    }
  };

  const handleResolveButtonClick = async () => {
    try {
      setIsLoadingResolved(true);
      setRefreshing(true)
      const res = await bulkStatAPI.postResolve(resolveSelects);
      // setIsLoadingResolved(false);
      if (res.status == 200) {
        setGlobalState("error", {
          open: true,
          message: res.data,
          type: "info",
        });
        // setResolveAllChecked((prev) => !prev);
        changeResolveValues();
      }
      resolvePollApi();
    } catch (error) {
      setIsLoadingResolved(false);
    }
  };

  const handleUpdateButtonClick = async () => {
    try {
      setIsLoadingUpdate(true);
      const statusRes = await bulkStatAPI.getUpdatingStatus();
      const res = await bulkStatAPI.postUpdate(updateSelects);
      setIsLoadingUpdate(false);
      if (res.status == 200) {
        setGlobalState("error", {
          open: true,
          message: res.data,
          type: "info",
        });
        setUpdateAllChecked((prev) => !prev);
        changeUpdateValues();
      }
    } catch (error) {
      setIsLoadingUpdate(false);
    }
  };

  const handleInputSelectAll = () => {
    if (!inputAllChecked) {
      const startIndex = (insertPageNo - 1) * insertPageSize;
      const endIndex = startIndex + insertPageSize;
      const slicedData = insertAPI
        .slice(startIndex, endIndex)
        .map((item) => item["EXTERNAL REF"]);
      setInputSelects(slicedData);
      setInsertAPI((previousRows) => {
        return previousRows.map((row, index) => {
          if (index >= startIndex && index < endIndex) {
            return {
              ...row,
              checked: true,
            };
          }
          return row;
        });
      });
    } else {
      setInputSelects([]);
      setInsertAPI((previousRows) => {
        return previousRows.map((row) => ({
          ...row,
          checked: false,
        }));
      });
    }
    setInputAllChecked((prev) => !prev);
  };

  const handleResolveSelectAll = () => {
    if (!resolveAllChecked) {
      const startIndex = (resolvePageNo - 1) * resolvePageSize;
      const endIndex = startIndex + resolvePageSize;
      const slicedData = resolveAPI
        .slice(startIndex, endIndex)
        .map((item) => item["EXTERNAL REF"]);
      setResolveSelects(slicedData);
      setResolveAPI((previousRows) => {
        return previousRows.map((row, index) => {
          if (index >= startIndex && index < endIndex) {
            return {
              ...row,
              checked: true,
            };
          }
          return row;
        });
      });
    } else {
      setResolveSelects([]);
      setResolveAPI((previousRows) => {
        return previousRows.map((row) => ({
          ...row,
          checked: false,
        }));
      });
    }
    setResolveAllChecked((prev) => !prev);
  };

  const handleUpdateSelectAll = () => {
    if (!updateAllChecked) {
      const startIndex = (updatePageNo - 1) * updatePageSize;
      const endIndex = startIndex + updatePageSize;
      const slicedData = updateAPI
        .slice(startIndex, endIndex)
        .map((item) => item["EXTERNAL REF"]);
      setUpdateSelects(slicedData);
      setUpdateAPI((previousRows) => {
        return previousRows.map((row, index) => {
          if (index >= startIndex && index < endIndex) {
            return {
              ...row,
              checked: true,
            };
          }
          return row;
        });
      });
    } else {
      setUpdateSelects([]);
      setUpdateAPI((previousRows) => {
        return previousRows.map((row) => ({
          ...row,
          checked: false,
        }));
      });
    }
    setUpdateAllChecked((prev) => !prev);
  };

  const handleInputClick = (event) => {
    setInputSelects((prevCheckedItems) => {
      if (!event.row.values.select.props.checked) {
        return [...prevCheckedItems, event.row.values["EXTERNAL REF"]];
      } else {
        return prevCheckedItems.filter(
          (item) => item !== event.row.values["EXTERNAL REF"]
        );
      }
    });

    setInsertAPI((previousRows) => {
      return previousRows.map((row, index) => {
        return index == event.row.id
          ? {
              ...row,
              checked: !row.checked,
            }
          : row;
      });
    });

    // setClickedInputStates(event)
  };

  const handleResolveClick = (event) => {
    setResolveSelects((prevCheckedItems) => {
      if (!event.row.values.select.props.checked) {
        return [...prevCheckedItems, event.row.values["EXTERNAL REF"]];
      } else {
        return prevCheckedItems.filter(
          (item) => item !== event.row.values["EXTERNAL REF"]
        );
      }
    });

    setResolveAPI((previousRows) => {
      return previousRows.map((row, index) => {
        return index == event.row.id
          ? {
              ...row,
              checked: !row.checked,
            }
          : row;
      });
    });
  };

  const handleUpdateClick = (event) => {
    setUpdateSelects((prevCheckedItems) => {
      if (!event.row.values.select.props.checked) {
        return [...prevCheckedItems, event.row.value["EXTERNAL REF"]];
      } else {
        return prevCheckedItems.filter(
          (item) => item !== event.row.values["EXTERNAL REF"]
        );
      }
    });

    setUpdateAPI((previousRows) => {
      return previousRows.map((row, index) => {
        return index == event.row.id
          ? {
              ...row,
              checked: !row.checked,
            }
          : row;
      });
    });
  };

  const checkServerStatus = async () => {
    try {
      const res = await bulkStatAPI.getOverallStatus();
      setRefreshing((prevRefreshing) => {
        if (prevRefreshing) {
          InsertPollApi();
          resolvePollApi();
          updatePollApi();
        }
        return false;
      });
    } catch (err) {
      setRefreshing(true);
      InsertPollApi();
      resolvePollApi();
      updatePollApi();
      return;
    }
  };

  const toggleAllLoading = () =>{

    setIsLoadingExcel((prevLoading)=>!prevLoading);
    setIsLoadingNew((prevLoading)=>!prevLoading);
    setIsLoadingRejected((prevLoading)=>!prevLoading);
    setIsLoadingResolved((prevLoading)=>!prevLoading);
    setIsLoadingUpdate((prevLoading)=>!prevLoading)
  }

  const handleExcelFileChange = async (event, type) => {
    toggleAllLoading()
    const formdata = new FormData();
    formdata.append(event.target.name, event.target.files[0]);

    caseDocumentAPI
      .uploadBulkCases(formdata, type)
      .then((res) => {
        if (res.status == 200) {
          setGlobalState("error", {
            open: true,
            message: "Successfully uploaded the File",
            type: "success",
          });
          // changeInputValues();
          // changeResolveValues();
          // changeUpdateValues();
          // changeExcelValues();
          checkServerStatus();
          toggleAllLoading()
          event.target.value = null;
        }
      })
      .catch((error) => {
        event.target.value = null;
        toggleAllLoading()

      });
  };

  const InsertPollApi = async () => {
    try {
      const statusRes = await bulkStatAPI.getInsertingStatus();
      if (statusRes.status === 200) {
        changeInputValues();
      } else {
      }
    } catch (error) {
      changeInputValues();
    }
  };
  const updatePollApi = async () => {
    try {
      const statusRes = await bulkStatAPI.getUpdatingStatus();
      if (statusRes.status === 200) {
        changeUpdateValues();
      } else {
        changeUpdateValues();
      }
    } catch (error) {
      changeUpdateValues();
    }
  };
  const resolvePollApi = async () => {
    try {
      const statusRes = await bulkStatAPI.getResolveStatus();
      if (statusRes.status === 200) {
        changeResolveValues();
      } else {
        changeResolveValues();
      }
    } catch (error) {
      changeResolveValues();
    }
  };

  useEffect(() => {
    const serverInterval = setInterval(checkServerStatus, 15000);
    return () => {
      clearInterval(serverInterval);
    };
  }, []);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar user={props?.user} /> */}
      <LoadingOverlay active={false} spinner text="Loading your content...">
        <TabContext value={tabValue}>
          <AppBar position="static" sx={{ margin: "20px 30px 10px 0px" }}>
            <Tabs
              orientation={"horizontal"}
              value={tabValue}
              onChange={handleSetTabValue}
            >
              <Tab
                label="Excel Data"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    dataset
                  </Icon>
                }
                value={"0"}
              />
              <Tab
                label="Insert new cases"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    add
                  </Icon>
                }
                value={"1"}
              />
              <Tab
                label="Resolved on I&C"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    build
                  </Icon>
                }
                value={"2"}
              />
              <Tab
                label="Resolved on System"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    check
                  </Icon>
                }
                value={"3"}
              />
              <Tab
                label="UnChanged Cases"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    difference-icon
                  </Icon>
                }
                value={"4"}
              />
              <Tab
                label="Rejected Cases"
                icon={
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    cancel-icon
                  </Icon>
                }
                value={"5"}
              />
            </Tabs>
          </AppBar>
          <TabPanel value="0" index={2}>
            <MDBox pt={3}>
              <LoadingOverlay
                active={false}
                spinner
                text="Loading your content..."
              >
                <MDBox sx={{ position: "absolute", top: "-2rem" }}>
                  * This is the list of cases which are uploaded through excel
                </MDBox>
                <Card sx={{ paddingTop: "1rem" }}>
                  <DataTable
                    table={{ columns: column_excel, rows: excelRows }}
                    isSorted={true}
                    canSearch={true}
                    showTotalEntries={true}
                    noEndBorder
                    downloadFileName={"Excel Data"}
                    isLoading={isLoadingExcel}
                  />
                </Card>
              </LoadingOverlay>
            </MDBox>
          </TabPanel>
          <TabPanel value="1" index={0}>
            <MDBox pt={3}>
              <LoadingOverlay
                active={false}
                spinner
                text="Loading your content..."
              >
                <MDBox sx={{ position: "absolute", top: "-2rem" }}>
                  * This is the list of cases which are new to the system and
                  need to be inserted
                </MDBox>
                <Card sx={{ paddingTop: "1rem" }}>
                  <DataTable
                    table={{ columns: column_insert_update, rows: insertRows }}
                    isSorted={true}
                    canSearch={true}
                    downloadFileName={"Insert New Cases Data"}
                    showTotalEntries={true}
                    noEndBorder
                    // rowClick={handleInputClick}
                    // selectall={{
                    //   func: handleInputSelectAll,
                    //   check: inputAllChecked,
                    // }}
                    submit={{
                      value: refreshing ? "Refreshing..." : "Insert",
                      handleClick: handleInputButtonClick,
                      disabled: refreshing,
                    }}
                    isLoading={isLoadingNew}
                    // pageDetails={{
                    //   setPageNo: setInsertPageNo,
                    //   setPageSize: setInsertPageSize,
                    // }}
                  />
                </Card>
              </LoadingOverlay>
            </MDBox>
          </TabPanel>
          <TabPanel value="2" index={1}>
            <MDBox pt={3}>
              <LoadingOverlay
                active={false}
                spinner
                text="Loading your content..."
              >
                <MDBox sx={{ position: "absolute", top: "-2rem" }}>
                  * This is the list of cases which have been removed from the
                  excel but not resolved on the system
                </MDBox>
                <Card sx={{ paddingTop: "1rem" }}>
                  <DataTable
                    table={{ columns: column_resolve, rows: resolveRows }}
                    isSorted={true}
                    canSearch={true}
                    showTotalEntries={false}
                    noEndBorder
                    // rowClick={handleResolveClick}
                    // selectall={{
                    //   func: handleResolveSelectAll,
                    //   check: resolveAllChecked,
                    // }}
                    submit={{
                      value: refreshing ? "Refreshing..." : "Resolve",
                      handleClick: handleResolveButtonClick,
                      disabled: refreshing,
                    }}
                    isLoading={isLoadingResolved}
                    // pageDetails={{
                    //   setPageNo: setResolvePageNo,
                    //   setPageSize: setResolvePageSize,
                    // }}
                  />
                </Card>
              </LoadingOverlay>
            </MDBox>
          </TabPanel>
          <TabPanel value="3" index={2}>
            <MDBox pt={3}>
              <LoadingOverlay
                active={false}
                spinner
                text="Loading your content..."
              >
                <MDBox sx={{ position: "absolute", top: "-2rem" }}>
                  * This is the list of cases which are resolved in system but
                  not removed from excel. Please update the excel and re-upload.
                </MDBox>
                <Card sx={{ paddingTop: "1rem" }}>
                  <DataTable
                    table={{
                      columns: column_resolveconflict,
                      rows: resolveconflictRows,
                    }}
                    isSorted={true}
                    canSearch={true}
                    downloadFileName={"Resolved Cases Data"}
                    showTotalEntries={true}
                    noEndBorder
                    isLoading={isLoadingResolvedConflict}
                    // rowClick={handleResolveClick}
                    // selectall={{
                    //   func: handleResolveSelectAll,
                    //   check: resolveAllChecked,
                    // }}
                    // pageDetails={{
                    //   setPageNo: setResolvePageNo,
                    //   setPageSize: setResolvePageSize,
                    // }}
                  />
                </Card>
              </LoadingOverlay>
            </MDBox>
          </TabPanel>
          <TabPanel value="4" index={3}>
            <MDBox pt={3}>
              <LoadingOverlay
                active={false}
                spinner
                text="Loading your content..."
              >
                <MDBox sx={{ position: "absolute", top: "-2rem" }}>
                  * This is the list of cases whose references were found in the
                  system, no action required .
                </MDBox>
                <Card sx={{ paddingTop: "1rem" }}>
                  <DataTable
                    table={{ columns: column_update, rows: updateRows }}
                    isSorted={true}
                    canSearch={true}
                    showTotalEntries={false}
                    noEndBorder
                    isLoading={isLoadingUpdate}
                    // rowClick={handleUpdateClick}
                    // selectall={{
                    //   func: handleUpdateSelectAll,
                    //   check: updateAllChecked,
                    // }}
                    // submit={{
                    //   value:  refreshing
                    //     ? "Refreshing..."
                    //     : "Update",
                    //   handleClick: handleUpdateButtonClick,
                    //   disabled: refreshing,
                    // }}
                    // pageDetails={{
                    //   setPageNo: setUpdatePageNo,
                    //   setPageSize: setUpdatePageSize,
                    // }}
                  />
                </Card>
              </LoadingOverlay>
            </MDBox>
          </TabPanel>
          <TabPanel value="5" index={4}>
            <MDBox pt={3}>
              <LoadingOverlay
                active={false}
                spinner
                text="Loading your content..."
              >
                <MDBox sx={{ position: "absolute", top: "-2rem" }}>
                  * This is the list of cases which are rejected because of
                  empty values
                </MDBox>
                <Card sx={{ paddingTop: "1rem" }}>
                  <DataTable
                    table={{ columns: column_rejected, rows: rejectedRows }}
                    isSorted={true}
                    canSearch={true}
                    downloadFileName={"Rejected Cases Data"}
                    showTotalEntries={true}
                    noEndBorder
                    isLoading={isLoadingRejected}
                    // rowClick={handleUpdateClick}
                    // selectall={{
                    //   func: handleUpdateSelectAll,
                    //   check: updateAllChecked,
                    // }}
                    // submit={{
                    //   value: !updateStatus
                    //     ? "Update"
                    //     : refreshing
                    //     ? "Refreshing..."
                    //     : "updating",
                    //   handleClick: handleUpdateButtonClick,
                    //   disabled: updateStatus,
                    // }}
                    // pageDetails={{
                    //   setPageNo: setUpdatePageNo,
                    //   setPageSize: setUpdatePageSize,
                    // }}
                  />
                </Card>
              </LoadingOverlay>
            </MDBox>
          </TabPanel>
        </TabContext>
        <Card sx={{ height: "100%" }}>
          <MDBox
            p={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h6" fontWeight="medium">
              Bulk Create Case
            </MDTypography>
            <label htmlFor="file">
              <input
                type="file"
                onChange={(e) => handleExcelFileChange(e, "nonbase")}
                name="file"
                id="file"
                style={{ display: "none" }}
              />
              <MDButton
                variant="outlined"
                color="info"
                size="small"
                component="span"
              >
                Upload Document
              </MDButton>
            </label>
          </MDBox>
        </Card>
        {/* <Card sx={{ height: "100%", marginTop: "2rem" }}>
          <MDBox
            p={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h6" fontWeight="medium">
              Bulk Create Case (Base)
            </MDTypography>
            <label htmlFor="filebase">
              <input
                type="file"
                onChange={(e) => handleExcelFileChange(e, "base")}
                name="file"
                id="filebase"
                style={{ display: "none" }}
              />
              <MDButton
                variant="outlined"
                color="info"
                size="small"
                component="span"
              >
                Upload Document
              </MDButton>
            </label>
          </MDBox>
        </Card> */}
      </LoadingOverlay>
    </DashboardLayout>
  );
}
export default AccessWrapper(Bulk, ["agent1", "superuser", "admin"]);
