import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

const Delayed = ({ children, waitBeforeShow = 500,showComponent=null }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : showComponent;
};

export default Delayed;
