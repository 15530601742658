// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { Toolbar, Tooltip } from "@mui/material";
import "./index.css";
import  InitialsAvatar  from 'utils/initialsAvatar';

function ComplexStatisticsCard({
  color,
  status,
  name,
  description,
  timestamp,
  email,
  action
}) {
  return (
    <Card sx={{ width: "80%", minWidth: "18rem",maxWidth:"50vw" }}>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <MDBox
            variant="gradient"
            bgColor={color}
            color={color === "light" ? "dark" : "white"}
            coloredShadow={color}
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            width="4rem"
            height="4rem"
            mt={-4}
          >
            <Tooltip title={name}>
              <div className="history-avatar">
                {/* <Avatar style={{width:"40px",height:"40px"}} {...config} /> */}
                
                <MDBox><InitialsAvatar name={name} /></MDBox>
              </div>
            </Tooltip>
          </MDBox>
          <MDTypography fontWeight="light" textAlign="center" fontSize="12px">
            {email}
          </MDTypography>
        </div>
        <MDBox textAlign="center" lineHeight={1.25} pt={2} sx={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
          <MDTypography
            variant="button"
            fontWeight="medium"
            color="text"
            sx={{ textTransform: "uppercase" }}
          >
            {action}
          </MDTypography>
          <MDTypography
            variant="button"
            fontWeight="small"
            color="text" 
            sx={{ textTransform: "uppercase" }}
          >
            {status}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={2} px={1}>
        <MDTypography
          component="p"
          variant="button"
          color="text"
          display="flex"
          justifyContent="center"
        >
          <MDTypography
            component="span"
            variant="button"
            fontWeight="light"
            textAlign="center"
          >
            {description}
          </MDTypography>
        </MDTypography>
      </MDBox>
      <MDBox
        sx={{
          position: "absolute",
          bottom: "0",
          fontSize: "small",
          bottom: "-1.5rem",
          left: "50%",
          transform: "translate(-50%,5px)",
        }}
      >
        {timestamp?.substring(0, 16)}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
