import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Icon, Tab, Tabs, Card } from "@mui/material";
import { useGlobalState } from "Global";
import { setGlobalState } from "Global";
import { caseAPI } from "api";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RSC from "react-scrollbars-custom";
import EmptyResult from "utils/emptyResult";

function NotificationHistory() {
  const [notificationHistory, setNotificationHistory] = useState([]);
  const [caseTransferHistory, setCaseTransferHistory] = useState([]);
  const navigation = useNavigate();
  const params = useParams();
  const [tabValue, setTabValue] = useState("2");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [userDetails] = useGlobalState("userDetails");


  const getNotificationHistory = async () => {
    caseAPI
      .getNotificationHistory({ case_id: params.case_id })
      .then((res) => {
        if (res.status == 200) {
          setNotificationHistory(res.data);
        }
      })
      .catch((error) => {});
  };

  const getCaseTransferHistory = async () => {
    caseAPI
      .getCaseTransferHistory({ case_id: params.case_id })
      .then((res) => {
        if (res.status == 200) {
          setCaseTransferHistory(res.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getNotificationHistory();
    getCaseTransferHistory();
  }, []);

  return (
    <TabContext value={tabValue}>
      <AppBar position="static" sx={{ margin: "0 30px 20px 0px" }}>
        <Tabs
          orientation={"horizontal"}
          value={tabValue}
          onChange={handleSetTabValue}
        >
          <Tab
            label="Case Notification History"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                analytics
              </Icon>
            }
            value={"1"}
          />
          <Tab
            label="Case Transfer History"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                timeline
              </Icon>
            }
            value={"2"}
          />
        </Tabs>
      </AppBar>

      {/* Notification History */}
      <TabPanel
        value="1"
        index={1}
        sx={{ width: "100%", height: "100%", margin: "0", padding: "0" }}
      >
        <RSC
          id="RSC-Example"
          style={{ width: "100%", height: "90%", position: "relative" }}
        >
          {notificationHistory.map((message, index) => {
            return (
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: message.from == userDetails.email ? "end" : "start",
                }}
                key={index}
              >
                <Card
                  sx={{
                    width: "60%",
                    padding: "1rem",
                    margin: "1rem",
                    marginBottom: "0",
                  }}
                >
                  <MDBox sx={{ display: "flex" }}>
                    <MDBox>From:</MDBox>
                    <MDBox sx={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      {message["from"]}
                    </MDBox>
                  </MDBox>
                  <MDBox sx={{ display: "flex" }}>
                    <MDBox>To:</MDBox>
                    <MDBox sx={{ marginLeft: "2rem", fontWeight: "bold" }}>
                      {message["to"]}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    sx={{
                      marginTop: "0.5rem",
                      border: "1px outset grey",
                      padding: "0.5rem",
                      borderRadius: "5px",
                    }}
                    dangerouslySetInnerHTML={{ __html: message["content"] }}
                  ></MDBox>
                </Card>
                <MDBox sx={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}>
                  {message["timestamp"]}
                </MDBox>
              </MDBox>
            );
          })}
          {notificationHistory.length == 0 && <EmptyResult />}
        </RSC>
      </TabPanel>

      {/* Case Transfer History */}
      <TabPanel
        value="2"
        index={2}
        sx={{ width: "100%", height: "100%", margin: "0", padding: "0" }}
      >
        <RSC
          id="RSC-Example"
          style={{ width: "100%", height: "90%", position: "relative" }}
        >
          {caseTransferHistory.map((message, index) => {
            return (
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: message.from == userDetails.email ? "end" : "start",
                }}
                key={index}
              >
                <Card
                  sx={{
                    width: "60%",
                    padding: "1rem",
                    margin: "1rem",
                    marginBottom: "0",
                  }}
                >
                  <MDBox sx={{ display: "flex" }}>
                    <MDBox>From:</MDBox>
                    <MDBox sx={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      {message["from"]}
                    </MDBox>
                  </MDBox>
                  <MDBox sx={{ display: "flex" }}>
                    <MDBox>To:</MDBox>
                    <MDBox sx={{ marginLeft: "2rem", fontWeight: "bold" }}>
                      {message["to"]}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    sx={{
                      marginTop: "0.5rem",
                      border: "1px outset grey",
                      padding: "0.5rem",
                      borderRadius: "5px",
                    }}
                    dangerouslySetInnerHTML={{ __html: message["content"] }}
                  ></MDBox>
                </Card>
                <MDBox sx={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}>
                  {message["timestamp"]}
                </MDBox>
              </MDBox>
            );
          })}
          {caseTransferHistory.length == 0 && <EmptyResult />}
        </RSC>
      </TabPanel>
    </TabContext>
  );
}

export default NotificationHistory;
