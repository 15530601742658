// @mui material components

//  React components

// Images
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertKeyToName } from "utils/conversion";

const TableReport = ({ excelData, ignoreColumns=[] }) => {
  const navigation = useNavigate();

  const [excelRows, setExcelRows] = useState([]);

  const [excelColumns, setExcelColumns] = useState([]);



  useEffect(() => {
    const tempRows = [];

    let initColumns =
      excelData && excelData.length > 0 ? Object.keys(excelData[0]) : [];

    if (excelData?.length > 0) {
      excelData.map((row, key) => {
        let rows = {};
        for (const column of initColumns) {
          if (typeof row[column] == "object") {
            if (column != "Category and claim amount") {
              rows[column] = Object.keys(row[column]).filter((item) => {
                return row[column][item] == true;
              })[0];
            }
          } else {
            rows[column] = row[column];
          }
        }
        tempRows.push(rows);
      });

      initColumns = initColumns.map((column) => {
        if (!ignoreColumns.includes(column)) {
          return {
            Header: convertKeyToName(column),
            accessor: column,
            align: "center",
          };
        }
      });
    }

    setExcelColumns(initColumns);
    setExcelRows(tempRows);
  }, [excelData]);

  return [excelRows, excelColumns];
};

export default TableReport;
