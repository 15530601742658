// @mui material components

//  React components

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data

// Dashboard components
import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Icon, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";

import { setGlobalState } from "Global";
import { caseAPI } from "api";
import AdvancedAnalytics from "layouts/dashboard/advancedAnalytics";
import AgentAnalytics from "layouts/dashboard/agentAnalytics";
import BasicAnalytics from "layouts/dashboard/basicAnalytics";
import ProcessMining from "layouts/dashboard/processMining";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigation = useNavigate();
  const [tabValue, setTabValue] = useState("1");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [state, setState] = useState({});

  const [filter, setFilter] = useState({
    resolved_only: false,
    level: 1,
    variants: [],
  });

  useEffect(() => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          const agents = res.data;
          setFilter({
            ...filter,
            agents: agents,
            agent_email: agents[0].email_id,
          });
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar user={props?.user} /> */}
      <TabContext value={tabValue}>
        <AppBar position="static" sx={{ margin: "20px 30px 20px 0px" }}>
          <Tabs
            orientation={"horizontal"}
            value={tabValue}
            onChange={handleSetTabValue}
          >
            <Tab
              label="Basic Analytics"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  analytics
                </Icon>
              }
              value={"1"}
            />
            {/* <Tab
              label="Process Mining"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  timeline
                </Icon>
              }
              value={"2"}
            /> */}
            <Tab
              label="Agent Analytics"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  person
                </Icon>
              }
              value={"3"}
            />
            <Tab
              label="Advanced Analytics"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  more-ver
                </Icon>
              }
              value={"4"}
            />
          </Tabs>
        </AppBar>
        <TabPanel value="1" index={0}>
          <BasicAnalytics />
        </TabPanel>
        <TabPanel value="2" index={1}>
          <ProcessMining />
        </TabPanel>
        <TabPanel value="3" index={2}>
          <AgentAnalytics />
        </TabPanel>
        <TabPanel value="4" index={3}>
          <AdvancedAnalytics />
        </TabPanel>
      </TabContext>
    </DashboardLayout>
  );
}
export default Dashboard;
