import { AppBar, Avatar, Card, Divider, Icon, Tab, Tabs } from "@mui/material";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { caseAPI, userAPI } from "api";
import bot from "assets/images/bot.png";
import MDTypography from "components/MDTypography";

import { setGlobalState } from "Global";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AvatarGenerator } from "random-avatar-generator";
import { TabContext, TabPanel } from "@mui/lab";
import CustomInput from "components/controls/CustomInput";
import CustomAutoComplete from "components/controls/AutoComplete";
import MDButton from "components/MDButton";
import NotificationList from "./notificationList";
import RenderNotifications from "examples/Lists/NotificationsList/renderNotifications";
import InitialsAvatar from "utils/initialsAvatar";
import Skeleton from "react-loading-skeleton";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  LabelList,
  Tooltip,
  Legend,
} from "recharts";

import EmptyResult from "utils/emptyResult";

const colorList = [
  "#43a047",
  "#2096F3",
  "#FFB701",
  "#d500f9",
  "#ff3d00",
  "#ff1744",
];

const userDetailsMapping = {
  "Full Name": "name",
  Email: "email_id",
  Title: "title",
  City: "city",
  Permission: "permission",
  "Business Function": "business_func",
  "Secondary Contact": "secondary_contact",
};

const userDetailsEditable = {
  "Full Name": false,
  Email: false,
  Title: true,
  City: true,
  Permission: false,
  "Business Function": true,
  "Seondary Contact": true,
};

function Profile() {
  const [isLoadingCaseDetials, setIsLoadingCaseDetials] = useState(true);
  // const [userDetails, setUserDetials] = useState({});
  const [userDetails, setUserDetials] = useState({});
  const [agents, setAgents] = useState([]);
  const [statusTabValue, statusSetTabValue] = useState("");
  const [settingTabValue, setSettingTabValue] = useState(1);
  const [statsData, setStatsData] = useState([]);

  const generator = new AvatarGenerator();

  const navigation = useNavigate();

  const getUserDetails = () => {
    setIsLoadingCaseDetials(true);
    userAPI
      .getUserDetails()
      .then((res) => {
        setUserDetials(res.data);
        // setGlobalState("userDetails", res.data);
        statusSetTabValue(res.data.active_status);
        let cases = res.data.cases_count;
        let tempData = [];
        let caseStatsSum = 0;
        Object.keys(cases).map((status, index) => {
          caseStatsSum += cases[status];
          tempData.push({ name: status, value: cases[status] });
        });
        if (caseStatsSum != 0) {
          setStatsData(tempData);
        }

        setIsLoadingCaseDetials(false);
      })
      .catch((error) => {
        setIsLoadingCaseDetials(false);
      });
  };

  const handleStatusChange = (newValue) => {
    userAPI
      .updateUserDetails({ active_status: newValue })
      .then((res) => {
        setGlobalState("error", {
          open: true,
          message: "Successfully updated user status.",
          type: "success",
        });
      })
      .catch((error) => {});
  };

  const handleSubmit = () => {
    setIsLoadingCaseDetials(true);
    userAPI
      .updateUserDetails(userDetails)
      .then((res) => {
        setGlobalState("error", {
          open: true,
          message: "Successfully updated user data.",
          type: "success",
        });
        setIsLoadingCaseDetials(false);
        getUserDetails();
      })
      .catch((error) => {
        setIsLoadingCaseDetials(false);
      });
  };

  const handleChange = (event) => {
    if (event.target.value?.email_id) {
      event.target.value = event.target.value.email_id;
    }

    setUserDetials({ ...userDetails, [event.target.name]: event.target.value });
  };

  const getAgents = () => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAgents(res.data);
        }
      })
      .catch((error) => {});
  };

  const handleSetTabValue = (event, newValue) => {
    handleStatusChange(newValue);
    statusSetTabValue(newValue);
  };

  useEffect(() => {
    getAgents();
    getUserDetails();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {userDetails && (
        <Grid container spacing={2} mt={2}>
          {/* Profile Card */}
          <Grid item xs={2.5}>
            <Card sx={{ width: "100%", minHeight: "20rem" }}>
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "1rem",
                }}
              >
                <MDBox
                  sx={{
                    padding: "0 2rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {!userDetails["name"] && (
                    <Skeleton
                      circle
                      width={"100%"}
                      height={"100%"}
                      style={{ aspectRatio: "1/1" }}
                    />
                  )}
                  {userDetails["name"] && (
                    <InitialsAvatar
                      name={userDetails["name"]}
                      // sx={{ width: "60%", height: "auto", alignSelf: "center" }}
                      sx={{ fontSize: "2rem !important" }}
                      style={{
                        padding: "20%",
                        alignSelf: "center",
                        aspectRatio: "1/1",
                        maxWidth: "10rem",
                      }}
                      className={"font-unset"}
                    />
                  )}
                </MDBox>
                <MDTypography
                  sx={{
                    fontSize: "x-large !important",
                    fontWeight: "500",
                    margin: "0.5rem",
                  }}
                  className={"font-unset"}
                >
                  {userDetails["name"]}
                </MDTypography>
                <MDTypography
                  sx={{ fontSize: "medium !important", marginBottom: "0.5rem" }}
                  className={"font-unset"}
                >
                  {userDetails["title"]}
                </MDTypography>
                <AppBar position="static">
                  <Tabs
                    orientation={"vertical"}
                    value={statusTabValue}
                    onChange={handleSetTabValue}
                  >
                    <Tab
                      label="Busy"
                      value="Busy"
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          remove
                        </Icon>
                      }
                    />
                    <Tab
                      label="Available"
                      value="Available"
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          eventAvailable
                        </Icon>
                      }
                    />
                    <Tab
                      label="Out of Office"
                      value="OOO"
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          flight
                        </Icon>
                      }
                    />
                  </Tabs>
                </AppBar>
              </MDBox>
            </Card>
          </Grid>

          {/* Profile Details Card  */}
          <Grid item xs={6.5}>
            <Card sx={{ width: "100%", minHeight: "20rem" }}>
              <TabContext value={settingTabValue}>
                <AppBar position="static">
                  <Tabs
                    orientation={"horizontal"}
                    value={settingTabValue}
                    onChange={(e, newValue) => setSettingTabValue(newValue)}
                  >
                    <Tab
                      label={
                        <MDBox
                          sx={{ fontSize: "1rem !important" }}
                          className={"font-unset"}
                        >
                          Personal Details
                        </MDBox>
                      }
                      value={1}
                      icon={
                        <Icon
                          fontSize="medium"
                          className="navbar-icon"
                          sx={{ mt: -0.25 }}
                        >
                          person
                        </Icon>
                      }
                    />
                    <Tab
                      label={
                        <MDBox
                          sx={{ fontSize: "1rem !important" }}
                          className={"font-unset"}
                        >
                          Notifications
                        </MDBox>
                      }
                      value={2}
                      icon={
                        <Icon
                          fontSize="medium"
                          className="navbar-icon"
                          sx={{ mt: -0.25 }}
                        >
                          eventAvailable
                        </Icon>
                      }
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={1} index={1} style={{ padding: "2rem" }}>
                  <Grid container spacing={3}>
                    {Object.keys(userDetailsMapping).map((detail) => {
                      if (detail != "Secondary Contact") {
                        return (
                          <Grid
                            item
                            xs={6}
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <MDBox
                              sx={{
                                fontSize: "0.9rem !important",
                                marginLeft: "0.5rem",
                              }}
                              className={"font-unset"}
                            >
                              {detail}
                            </MDBox>
                            <CustomInput
                              value={userDetails[userDetailsMapping[detail]]}
                              name={userDetailsMapping[detail]}
                              label={""}
                              onChange={handleChange}
                              disabled={!userDetailsEditable[detail]}
                            />
                          </Grid>
                        );
                      }
                    })}
                    {Object.keys(userDetails).length > 0 && (
                      <Grid
                        item
                        xs={6}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <MDBox
                          sx={{
                            fontSize: "0.9rem !important",
                            marginLeft: "0.5rem",
                          }}
                          className={"font-unset"}
                        >
                          Secondary Contact
                        </MDBox>
                        <CustomAutoComplete
                          value={
                            userDetails[userDetailsMapping["Secondary Contact"]]
                          }
                          name={"secondary_contact"}
                          label={""}
                          options={agents}
                          onChange={handleChange}
                          size={"small"}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "0.5rem",
                    }}
                    onClick={handleSubmit}
                  >
                    <MDButton color={"info"}> Update </MDButton>
                  </MDBox>
                </TabPanel>
                <TabPanel value={2} index={2}>
                  <RenderNotifications />
                </TabPanel>
              </TabContext>
            </Card>
          </Grid>

          {/* User Stats */}
          <Grid item xs={3}>
            <Card sx={{ width: "100%", minHeight: "20rem" }}>
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "0.5rem 1rem",
                }}
              >
                <MDBox
                  sx={{
                    fontSize: "1rem !important",
                    marginLeft: "0.5rem",
                  }}
                  className={"font-unset"}
                >
                  User Stats
                </MDBox>
                <Divider />
                <MDBox sx={{ width: "100%", height: "100%" }}>
                  {statsData.length > 0 && (
                    <MDBox>
                      <ResponsiveContainer
                        width={"100%"}
                        height={"100%"}
                        minWidth={"5rem"}
                        minHeight={"10rem"}
                      >
                        <PieChart title={"Assigned Cases Status"}>
                          <Legend
                            layout="horizontal"
                            verticalAlign="top"
                            align="center"
                          />

                          <Tooltip />
                          <Pie
                            dataKey="value"
                            startAngle={180}
                            endAngle={0}
                            data={statsData}
                            cx="50%"
                            cy="90%"
                            // outerRadius={80}
                            outerRadius={"120%"}
                            label
                          >
                            {statsData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={colorList[index]}
                              />
                            ))}{" "}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                      <MDBox>Assigned Cases Status</MDBox>
                    </MDBox>
                  )}
                  {statsData.length == 0 && (
                    <EmptyResult text={"No Cases assigned/resolved"} />
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default Profile;
