import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  Grid,
  TextField,
  Icon,
  Card,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDProgress from "components/MDProgress";
import { analyticsAPI } from "api";

import { setGlobalState } from "Global";
import { useNavigate } from "react-router-dom";
import AnalyticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard/analytics";
import { Filter } from "@mui/icons-material";
import { caseAPI } from "api";
import LoadingOverlay from "react-loading-overlay";
import resolveData from "../bulkupload/resolveTableData";
import { AccessDenied } from "layouts/authentication/access-denied";
import AccessWrapper from "wrappers/accessWrapper";
import Plot from "react-plotly.js";

function AgentAnalytics() {
  const [filter, setFilter] = useState({
    days: 90,
    resolved_only: false,
    level: 1,
    variants: [],
    agent_email: "",
  });

  const [ACS, setACS] = useState({ days: 90 });

  const [ACSC, setACSC] = useState({ days: 90 });

  const [isLoading, setIsLoading] = useState(false);

  const [agent, setAgent] = useState({});

  const handleChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };

  const handleAgentFilter = () => {
    setIsLoading(true);
    getACS(filter);
    getACSC(filter);
    analyticsAPI
      .getAgentAnalytics(filter.agent_email, filter.days)
      .then((res) => {
        if (res.status == 200) {
          setAgent(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // ACS Functions
  const getACS = (filter) => {
    analyticsAPI
      .getAgentClaimAmountStatus(ACS?.days, filter)
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          const tempData = [
            {
              x: data.x,
              y: data.y,
              type: "bar",
            },
          ];
          const layout = {
            title: data.name,
            xaxis: { title: data["x-name"] },
            yaxis: { title: data["y-name"] },
          };
          setACS({ ...ACS, data: tempData, layout });
        }
      })
      .catch((error) => {});
  };

  const handleACSChange = (event) => {
    setACS({ ...ACS, [event.target.name]: event.target.value });
  };

  const getACSC = (filter) => {
    analyticsAPI
      .getAgentClaimCountStatus(ACSC?.days, filter)
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          const tempData = [
            {
              x: data.x,
              y: data.y,
              type: "bar",
            },
          ];
          const layout = {
            title: data.name,
            xaxis: { title: data["x-name"] },
            yaxis: { title: data["y-name"] },
          };
          setACSC({ ...ACSC, data: tempData, layout });
        }
      })
      .catch((error) => {});
  };

  const handleACSCChange = (event) => {
    setACSC({ ...ACSC, [event.target.name]: event.target.value });
  };

  const getAllAgents = () => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          const agents = res.data;
          setFilter({
            ...filter,
            agents: agents,
            agent_email: agents[0].email_id,
          });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAllAgents();
  }, []);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox p={3}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <FormControl size={"large"}>
                    <InputLabel sx={{}} id="agent_id_label">
                      Agent
                    </InputLabel>
                    <Select
                      labelId="agent_id_label"
                      value={filter.agent_email ? filter.agent_email : ""}
                      label="Agent"
                      name="agent_email"
                      onChange={handleChange}
                      sx={{ p: 1.5, width: "170px" }}
                    >
                      {filter.agents?.map((agent) => {
                        return (
                          <MenuItem
                            value={agent.email_id}
                            key={agent.email_id}
                            id={"agent_email"}
                            name={"agent_email"}
                          >
                            {agent.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    id="days"
                    label="Period"
                    name={"days"}
                    value={filter?.days}
                    onChange={handleChange}
                    variant="outlined"
                  />{" "}
                  <MDButton
                    onClick={handleAgentFilter}
                    variant="text"
                    color={"info"}
                  >
                    <Icon>search</Icon>&nbsp;Filter
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </Grid>

      {agent.total_time_invested != undefined && (
        <MDBox py={3}>
          <LoadingOverlay
            active={isLoading}
            spinner
            text="Loading your content..."
          >
            <Grid
              container
              md={12}
              spacing={3}
              mt={2}
              sx={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <Grid
                item
                xs={12}
                md={3}
                lg={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <MDBox mb={1.5}>
                  <AnalyticsCard
                    color="dark"
                    icon="equalizer"
                    title="Total Cases"
                    count={agent.total_cases}
                    percentage={{
                      color: "success",
                      amount: "+55%",
                      label: "than lask week",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <MDBox mb={1.5}>
                  <AnalyticsCard
                    icon="access_time"
                    title="Open Cases"
                    count={agent.open_cases}
                    percentage={{
                      color: "success",
                      amount: "+3%",
                      label: "than last month",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                lg={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <MDBox mb={1.5}>
                  <AnalyticsCard
                    color="success"
                    icon="percentage"
                    title="Closed Percentage"
                    count={agent.closed_percentage + "%"}
                    percentage={{
                      color: "success",
                      amount: "+1%",
                      label: "than yesterday",
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox m={4}></MDBox>

            <Grid
              container
              md={12}
              spacing={3}
              sx={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <Grid item xs={12} md={3} lg={3}>
                <MDBox mb={1.5}>
                  <AnalyticsCard
                    color="primary"
                    icon="cancel"
                    title="Average Turnaround Time"
                    count={agent.average_time_taken_to_close.toString() + " hr"}
                    percentage={{
                      color: "success",
                      amount: "+9%",
                      label: "than last month",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <MDBox mb={1.5}>
                  <AnalyticsCard
                    icon="schedule"
                    title="Total Invested Time"
                    count={agent.total_time_invested.toString() + " hr"}
                    percentage={{
                      color: "success",
                      amount: "+5%",
                      label: "than last month",
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              mt={4}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              {/* ACS card */}
              <Card>
                <MDBox p={3}>
                  <Grid container spacing={3}>
                    <Grid
                      justifyContent={"center"}
                      xs={9}
                      md={9}
                      display="flex"
                    >
                      <MDBox
                        sx={{
                          alignItems: "center",
                          height: "fit-content",
                          paddingTop: "1rem",
                          maxWidth: "100%",
                          width: "auto",
                          minHeight: "35vh",
                        }}
                      >
                        {<Plot data={ACS?.data} layout={ACS?.layout} />}
                      </MDBox>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        id="days"
                        label="Days"
                        name={"days"}
                        value={ACS?.days}
                        onChange={handleACSChange}
                        variant="outlined"
                        sx={{ marginBottom: "2rem", marginTop: "50%" }}
                      />{" "}
                      <MDButton onClick={getACS} color="warning">
                        <Icon>search</Icon>&nbsp;Filter
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
              <MDBox m={4}></MDBox>

              {/* ACSC card */}
              <Card>
                <MDBox p={3}>
                  <Grid container spacing={3}>
                    <Grid
                      justifyContent={"center"}
                      xs={9}
                      md={9}
                      display="flex"
                    >
                      <MDBox
                        sx={{
                          alignItems: "center",
                          height: "fit-content",
                          paddingTop: "1rem",
                          maxWidth: "100%",
                          width: "auto",
                          minHeight: "35vh",
                        }}
                      >
                        {<Plot data={ACSC?.data} layout={ACSC?.layout} />}
                      </MDBox>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        id="days"
                        label="Days"
                        name={"days"}
                        value={ACSC?.days}
                        onChange={handleACSCChange}
                        variant="outlined"
                        sx={{ marginBottom: "2rem", marginTop: "50%" }}
                      />{" "}
                      <MDButton onClick={getACSC} color="warning">
                        <Icon>search</Icon>&nbsp;Filter
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </LoadingOverlay>
        </MDBox>
      )}
    </>
  );
}

export default AccessWrapper(AgentAnalytics, ["superuser"]);
