import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
import "react-loading-skeleton/dist/skeleton.css";
import CacheBuster from "react-cache-buster";
import projectPackage from "../package.json";

import { MaterialUIControllerProvider } from "context";
import "css/main.css";
import { useGlobalState } from "Global";
import { setGlobalState } from "Global";

// Code to disable the Inspect element feature from the browser tab

// Disable right-click
document.addEventListener("contextmenu", (e) => e.preventDefault());

function ctrlShiftKey(e, keyCode) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

document.onkeydown = (e) => {
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  if (
    e.keyCode === 123 ||
    ctrlShiftKey(e, "I") ||
    ctrlShiftKey(e, "J") ||
    ctrlShiftKey(e, "C") ||
    (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
  )
    return false;
};

const AppWithAuthentication = () => {
  const [errorState] =useGlobalState("error");
  const [key,setKey] = useState(1);

  useEffect(()=>{
    if(errorState["code"]==401){

      setKey(key=>key+1)
      // setGlobalState("error",{...errorState,code:800})
    }
  },[errorState.code])
  return (
    // <MsalProvider instance={pca}>
    <CacheBuster
    key={key}
      currentVersion={projectPackage.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      // metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <BrowserRouter>
        <MaterialUIControllerProvider>
          {/* <SkeletonTheme baseColor="black" highlightColor="black"> */}
          <App />
          {/* </SkeletonTheme> */}
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </CacheBuster>
    // </MsalProvider>
  );
};

ReactDOM.render(<AppWithAuthentication />, document.getElementById("root"));
