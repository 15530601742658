// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import { ArrowRightRounded, Send } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, TextField, Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";

import { Typography, Paper, InputLabel, Box, Fab, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { caseAPI } from "api";
import MDButton from "components/MDButton";
import { setGlobalState } from "Global";
import Input from "./CustomInput";

function CategoryDialog({
  open,
  handleClose,
  categoryList,
  updateCategoryList,
}) {
  const [state, setState] = useState({ categoryName: "", amount: "" });
  const navigation = useNavigate();

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const throwError = (message) => {
    setGlobalState("error", {
      open: true,
      message: message,
      type: "error",
    });
  };

  const handleSubmit = () => {
    if (
      state.categoryName == "" ||
      state.amount == "" ||
      isNaN(parseFloat(state.amount))
    ) {
      throwError("Please select the category and enter the correct amount");
    } else {
      updateCategoryList(
        state.categoryName,
        state.amount != "" ? parseFloat(state.amount) : parseFloat("0")
      );
      handleClose(!open);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", minWidth: "30rem" }}
      >
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2 }}>
            Add Category and Claim Amount
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="categoryName-label">Category Name</InputLabel>
                <Select
                  id="categoryName"
                  labelId="categoryName-label"
                  value={state.categoryName}
                  name="categoryName"
                  label={"Category Name"}
                  onChange={handleChange}
                  sx={{ marginLeft: "2", width: "100%", minHeight: "3.5rem" }}
                >
                  {categoryList.map((category) => {
                    return (
                      <MenuItem
                        sx={{ width: "100%", minHeight: "3.5rem" }}
                        value={category}
                        key={category}
                      >
                        {category}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <Input
                  name={"amount"}
                  label={"Amount (ZAR)"}
                  value={state.amount}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} md={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                p={1}
              >
                <Button
                  size="medium"
                  color="success"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Add
                </Button>
              </MDBox>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default CategoryDialog;
