// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Dialog, TextField, Select, MenuItem } from "@mui/material";

// @mui material components

// Images

// Data
import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";

//  React components

import MDButton from "components/MDButton";

// Billing page components
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import { caseAPI } from "api";
import { FormControl, InputLabel } from "@mui/material";
import { setGlobalState } from "Global";
import LoadingOverlay from "react-loading-overlay";

function CreateCase() {
  const navigation = useNavigate();
  const [agents, setAgents] = useState([]);
  const [assignAgents, setAssignAgents] = useState([]);
  const [state, setState] = useState({});
  const [workflows, setWorkflows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const createCaseDetails = () => {
    setIsLoading(true);
    caseAPI
      .createCase(state)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          navigation(`/cases/${res.data.case_id}`);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getWorkflows = () => {
    caseAPI
      .getWorkflows()
      .then((res) => {
        if (res.status == 200) {
          setWorkflows(res.data);
        }
      })
      .catch((error) => {});
  };

  const getAgents = () => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAgents(res.data);
          setAssignAgents(res.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getWorkflows();
    getAgents();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {"Create Case"}
                </MDTypography>
              </MDBox>

              <LoadingOverlay
                active={isLoading}
                spinner
                text="Loading your content..."
              >
                <MDBox>
                  <Grid spacing={3} mb={3}>
                    <Grid item pt={4} xs={12} md={12} ml={3} mr={3}>
                      <Form>
                        <Grid container>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel id="email-to-label">
                                Assign Agent
                              </InputLabel>
                              <Select
                                id="assigned_agent_email"
                                labelId="email-to-label"
                                value={state.agent_email_id}
                                name="assigned_agent_email"
                                size="small"
                                label={"Assign Agent"}
                                onChange={handleChange}
                                sx={{
                                  marginLeft: "2",
                                  width: "100%",
                                  minHeight: "3.5rem",
                                }}
                              >
                                {assignAgents.map((agent) => {
                                  return (
                                    <MenuItem
                                      sx={{ width: "100%" }}
                                      value={agent.email_id}
                                      key={agent.email_id}
                                    >
                                      {agent.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel id="email-to-label">
                                WorkFlow
                              </InputLabel>
                              <Select
                                id="workflow_id"
                                labelId="email-to-label"
                                value={state.agent_email_id}
                                name="workflow_id"
                                size="small"
                                label={"WorkFlow Id"}
                                onChange={handleChange}
                                sx={{
                                  marginLeft: "2",
                                  width: "100%",
                                  minHeight: "3.5rem",
                                }}
                              >
                                {workflows.map((workflow) => {
                                  return (
                                    <MenuItem
                                      sx={{ width: "100%" }}
                                      value={workflow._id}
                                      key={workflow._id}
                                    >
                                      {workflow.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={2} md={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="end"
                              alignItems="center"
                              p={1}
                            >
                              <MDButton
                                size="medium"
                                color="success"
                                variant="contained"
                                onClick={createCaseDetails}
                              >
                                Create Case
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    </Grid>
                  </Grid>
                </MDBox>
              </LoadingOverlay>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateCase;
