// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import Checkbox from "@mui/material/Checkbox";

// Images
import { bulkStatAPI } from "api";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";

export default function resolveConflictData({ setIsLoadingResolvedConflict }) {
  const navigation = useNavigate();
  const [resolveConflictAPI, setResolveAPI] = useState([]);

  const [resolveConflictRows, setResolveRows] = useState([]);

  const [resolveConflictSelects, setResolveSelects] = useState([]);
  const [changeValue, setChangeValue] = useState(true);
  const [resolveConflictColumns, setResolveColumns] = useState([]);

  const fetchResolveAPI = useCallback(async () => {
    setIsLoadingResolvedConflict(true);
    try {
      const res = await bulkStatAPI.getResolveConflict();
      const updatedData = res.data.map((item) => ({
        ...item,
        // checked: false,
      }));
      setResolveAPI(updatedData);
      setIsLoadingResolvedConflict(false);
    } catch (err) {
      setIsLoadingResolvedConflict(false);
      if ([401].includes(err.response?.status)) {
        
        setGlobalState("error", {
          open: true,
          message: "Login Expired!",
          type: "error",
        });
      }
    }
  }, []);

  const changeResolveValues = useCallback(async () => {
    setChangeValue((prev) => !prev);
    setResolveSelects([])
  }, []);

  useEffect(() => {
    fetchResolveAPI();
  }, [changeValue]);

  const getNewResolve = useCallback(async () => {
    const tempRows = [];

    resolveConflictAPI
      ? (() => {
        const initColumns = resolveConflictAPI && resolveConflictAPI.length > 0 ? Object.keys(resolveConflictAPI[0]) : [];
          // initColumns.push("select");
          // const columns = initColumns.filter((column) => column !== "checked");
          setResolveColumns(initColumns);
          resolveConflictAPI.map((caseDetail, key) => {
            const rows = {};
            for (const column of initColumns) {
              rows[column] = caseDetail[column];
            }
            tempRows.push(rows);
            // const row = {
            //   ReferenceKey: caseDetail["Reference Key"],
            //   statusComment: caseDetail["Status Comment"],
            //   customer: caseDetail.Customer,
            //   select: <Checkbox checked={caseDetail.checked} />,
            // };
            // tempRows.push(row);
          });
        })()
      : null;
    setResolveRows(tempRows);
  }, [resolveConflictAPI]);

  useEffect(() => {
    getNewResolve();
  }, [getNewResolve]);

  return [
    resolveConflictRows,
    resolveConflictAPI,
    setResolveAPI,
    setResolveSelects,
    resolveConflictSelects,
    changeResolveValues,
    resolveConflictColumns
  ];
}
