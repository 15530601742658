import { Link, useNavigate } from "react-router-dom";

// prop-types is library for typechecking of props

// @mui material components

//  React components
import { Avatar, Tooltip } from "@mui/material";
import { userAPI } from "api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import EmptyResult from "utils/emptyResult";
import NotificationSkeleton from "skeletons/profile/notificationSkeleton";
import { setGlobalState } from "Global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import InitialsAvatar from "utils/initialsAvatar";
function RenderNotifications() {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigate();

  const getNotifications = () => {
    setIsLoading(true);
    userAPI
      .getNotifications()
      .then((res) => {
        if (res.status == 200) {
          setNotifications(res.data);
          setShowNotifications(Array(res.data.length).fill(false));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <MDBox>
      {notifications.length == 0 && isLoading == true && (
        <NotificationSkeleton />
      )}
      {notifications
        .slice(0, 10)
        .map(({ image, from, content, case_id, name }, index) => (
          <MDBox sx={{ position: "relative" }}>
            <MDBox
              key={from}
              component="li"
              display="flex"
              alignItems="center"
              py={1}
              mb={1}
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setShowNotifications((prevShowNotifications) => {
                  let newShowNotificatoins = [...prevShowNotifications];
                  newShowNotificatoins[index] = !prevShowNotifications[index];
                  return newShowNotificatoins;
                })
              }
            >
              <MDBox mr={2}>
                <Tooltip title={name}>
                  <MDBox>
                    <InitialsAvatar name={name} />
                  </MDBox>
                </Tooltip>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
                width={"65%"}
              >
                <MDTypography fontWeight="medium">From: {from}</MDTypography>
                <MDBox
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  {showNotifications[index] == false &&
                    content.replace("<br></br>", "")}
                </MDBox>
              </MDBox>
              <MDBox ml="auto">
                <Tooltip title={showNotifications[index] ? "Hide" : "Expand"}>
                  <FontAwesomeIcon
                    icon={
                      showNotifications[index] ? faChevronUp : faChevronDown
                    }
                    style={{ marginRight: "2rem" }}
                  ></FontAwesomeIcon>
                </Tooltip>
                {/* <Tooltip title={"View Case"}>
                  <FontAwesomeIcon
                    icon={
                      faArrowUpRightFromSquare
                    }
                    onClick={() =>
                      navigation("/cases/"+case_id)
                    }
                    style={{ marginRight: "0.2rem" }}
                  ></FontAwesomeIcon>
                </Tooltip> */}
              </MDBox>
            </MDBox>
            <Tooltip title={"View Case"}>
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                onClick={() => window.open("/cases/" + case_id, "_blank")}
                style={{
                  position: "absolute",
                  right: "0",
                  marginRight: "0.2rem",
                  top: "50%",
                  transform: "translate(0%,-50%)",
                  cursor: "pointer",
                }}
              ></FontAwesomeIcon>
            </Tooltip>
            <MDBox>
              {showNotifications[index] && (
                <MDBox
                  sx={{
                    margin: "0.5rem 3rem",

                    border: "1px outset grey",
                    padding: "0.5rem",

                    borderRadius: "5px",
                  }}
                  dangerouslySetInnerHTML={{ __html: content }}
                ></MDBox>
              )}
            </MDBox>
          </MDBox>
        ))}
      {notifications.length == 0 && isLoading == false && (
        <EmptyResult text={"No New Notifications Found"} />
      )}
    </MDBox>
  );
}

export default RenderNotifications;
