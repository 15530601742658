import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { caseAPI } from "api";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { AccessTimeFilledOutlined } from "@mui/icons-material";
import { AvatarGenerator } from "random-avatar-generator";
import { setGlobalState } from "Global";
import { useNavigate } from "react-router-dom";

export default function HistoryTimeLine({ filters }) {
  const generator = new AvatarGenerator();
  const navigation = useNavigate();
  const [cases, setCases] = React.useState([]);

  React.useEffect(() => {
    caseAPI
      .getHistory(filters)
      .then((res) => {
        if (res.status == 200) {
          setCases(res.data);
        }
      })
      .catch((error) => {});
  }, [filters]);

  return (
    <Timeline position="alternate">
      {cases.map((singleCase, index) => {
        const timeSide = index % 2 != 0 ? "" : "row-reverse";
        const cardSide = index % 2 == 0 ? "" : "end";

        return (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              color="textSecondary"
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: timeSide,
              }}
            >
              {singleCase.timestamp?.substring(0, 16)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
              <TimelineDot color="grey">
                <AccessTimeFilledOutlined color="white" />
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                py: "12px",
                px: 2,
                display: "flex",
                justifyContent: cardSide,
              }}
            >
              {/* <Typography variant="h5">{singleCase.status}</Typography>
              <Typography>{singleCase.agent_name}</Typography> */}
              <ComplexStatisticsCard
                color={"secondary"}
                status={singleCase.status}
                description={singleCase.description}
                name={singleCase.agent_name}
                email={singleCase.agent_email}
                action={singleCase.action_triggered_on}
              ></ComplexStatisticsCard>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}
