import emptyImg from "assets/images/empty.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function EmptyResult({ text }) {
  return (
    <MDBox
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <MDBox sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <img src={emptyImg} height={"100%"} width={"80%"} />
        <MDTypography sx={{whiteSpace:"nowrap"}}>{text || "No Results Found"}</MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default EmptyResult;
