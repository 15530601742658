//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  Grid,
  TextField,
  Icon,
  Card,
} from "@mui/material";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import HistoryTimeLine from "layouts/history/historytimeline";
import { useParams } from "react-router-dom";
import MDButton from "components/MDButton";
import { useState, useEffect } from "react";

function History(props) {
  //   const { columns, rows } = authorsTableData();
  const params = useParams();
  const [filter, setFilter] = useState({ show_variable_changes: false });
  const [history, setHistory] = useState(false);
  const [mainFilter, setMainFilter] = useState({
    show_variable_changes: false,
  });

  const handleChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };
  //   const { columns: pColumns, rows: pRows } = projectsTableData(params.agent_id);
  //   const [state, setState] = useState({ showState: false });
  //   const [agent,setAgent]=useState({});

  useEffect(() => {
    if (params.case_id) {
      let currFilter = { ...filter, case_id: params.case_id };
      setFilter(currFilter);
      handleFilter(currFilter);
    }
  }, []);

  const handleFilter = (currFilter = null) => {
    if (currFilter) setMainFilter({ ...currFilter });
    else setMainFilter({ ...filter });
  };

  return (
    <DashboardLayout>
      <>
        <DashboardNavbar user={props?.user} />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography
                    variant="h6"
                    color="white"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>History</div>
                    {/* <div>{case.case_id}</div> */}
                  </MDTypography>
                </MDBox>
                <MDBox p={3}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                      <TextField
                        id="case_id"
                        label="Case Id"
                        name={"case_id"}
                        value={filter?.case_id}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ width: "15rem" }}
                      />{" "}
                      <FormControl sx={{ width: "15rem", maxWidth: "30%" }}>
                        <InputLabel id="show_variable_changes">
                          Show Variable Changes
                        </InputLabel>
                        <Select
                          labelId="show_variable_changes"
                          value={filter.show_variable_changes}
                          label="show_variable"
                          name="show_variable_changes"
                          onChange={handleChange}
                          sx={{ p: 1.5 }}
                        >
                          <MenuItem value={"false"}>False</MenuItem>
                          <MenuItem value={"true"}>True</MenuItem>
                        </Select>
                      </FormControl>
                      <MDButton
                        onClick={() => handleFilter()}
                        variant="text"
                        color={"info"}
                      >
                        <Icon>search</Icon>&nbsp;Filter
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={3}>
                  {mainFilter.case_id && (
                    <HistoryTimeLine filters={mainFilter}></HistoryTimeLine>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </>
    </DashboardLayout>
  );
}

export default History;
