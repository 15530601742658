// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import Checkbox from "@mui/material/Checkbox";

// Images
import { bulkStatAPI } from "api";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";

export default function updateData({ setIsLoadingUpdate }) {

  const navigation = useNavigate();
  const [updateAPI, setUpdateAPI] = useState([]);

  const [updateRows, setUpdateRows] = useState([]);

  const [updateSelects, setUpdateSelects] = useState([]);
  const [changeValue, setChangeValue] = useState(true);
  const [updateColumns, setUpdateColumns] = useState([]);


  const fetchUpdateAPI = useCallback(async () => {
    setIsLoadingUpdate(true);
    try {
      const res = await bulkStatAPI.getUpdate();
      const updatedData = res.data.map((item) => ({
        ...item,
        // checked: false,
      }));
      setUpdateAPI(updatedData);
      setIsLoadingUpdate(false);
    } catch (err) {
      setIsLoadingUpdate(false);
      if ([401].includes(err.response?.status)) {
        
        setGlobalState("error", {
          open: true,
          message: "Login Expired!",
          type: "error",
        });
      }
    }
  }, []);

  

  const changeUpdateValues = useCallback(async () => {
    setChangeValue((prev) => !prev);
    setUpdateSelects([])
  }, []);

  useEffect(() => {
    fetchUpdateAPI();
  }, [changeValue]);

  const getNewUpdate = useCallback(async () => {
    const tempRows = [];

      updateAPI
      ? (() => {
        const initColumns = updateAPI && updateAPI.length > 0 ? Object.keys(updateAPI[0]) : [];
          // initColumns.push("select");
          // const columns = initColumns.filter((column) => column !== "checked");
          setUpdateColumns(initColumns);
          updateAPI.map((caseDetail, key) => {
            const rows = {};
            for (const column of initColumns) {
              rows[column] = caseDetail[column];
            }
            tempRows.push(rows);
          });
        })()
      : null;
    setUpdateRows(tempRows);
  }, [updateAPI]);

  
  useEffect(() => {
    getNewUpdate();
  }, [getNewUpdate]);

  return [
    updateRows,
    updateAPI,
    setUpdateAPI,
    setUpdateSelects,
    updateSelects,
    changeUpdateValues,
    updateColumns
  ];
}
