
export const toDecimalMark = num => {if(num) return num.toLocaleString('en-US')}

export const toFloatMark = string => parseFloat(string.replace(/,/g,'')) 

export const convertKeyToName = (key) => {
    let name = key
      .split("_")
      .map((word) => {
        return (word[0] ? word[0].toUpperCase() : "") + word.slice(1);
      })
      .join(" ");
    return name;
  };