// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import { ArrowRightRounded, Send } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, TextField, Select, MenuItem, Switch } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography, Paper, InputLabel, Box, Fab, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { caseAPI } from "api";
import MDButton from "components/MDButton";
import { setGlobalState } from "Global";
import MDInput from "components/MDInput";

function StatusChange({ open, handleClose, caseDetails }) {
  const [state, setState] = useState(caseDetails);
  const [status, setStatus] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [autoClicked, setAutoClicked] = useState(false);
  const [isNull, setIsNull] = useState(false);
  const navigation = useNavigate();

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
      checked: true,
    });
  };

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
    setState({ ...state, checked: !isChecked });
  };

  const handleGetAgents = async () => {
    setAutoClicked(true);
    try {
      const res = await caseAPI.getAutoAssignedPerson(
        state.case_id,
        state.nextStep
      );

      if (res.data === null) {
        setIsNull(true);
        setIsChecked(!isChecked);
        setState({ ...state, checked: !isChecked });
      } else {
        setIsNull(false);
        setState({
          ...state,
          ["name"]: res.data.name,
          ["email_id"]: res.data.email_id,
          ["active_status"]: res.data.active_status,
        });
      }
    } catch (error) {

    }
  };

  const handleSubmit = () => {
    caseAPI
      .changeStatus(
        state.case_id,
        state.nextStep,
        state.checked,
        state.email_id
      )
      .then((res) => {
        handleClose(!open);
        navigation(`/cases/${state.case_id}`);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    caseAPI
      .getNextPaths(state.case_id)
      .then((res) => {
        if (res.status == 200) {
          setStatus(res.data);
        }
      })
      .catch((error) => {});
  }, []);

  const keysToDisplay = ["name", "email_id", "active_status"];
  const filteredKeyValuePairs = Object.entries(state)
    .filter(([key]) => keysToDisplay.includes(key))
    .map(([key, value]) => `${key}: ${value}`)
    .join("\n");

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", minWidth: "30rem" }}
      >
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2 }}>
            Change Step
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="nextStep-label">Available Status</InputLabel>
                <Select
                  id="nextStep"
                  labelId="nextStep-label"
                  value={state.nextStep}
                  name="nextStep"
                  label={"Available Status"}
                  onChange={handleChange}
                  sx={{ marginLeft: "2", width: "100%", minHeight: "3.5rem" }}
                >
                  {status.map((item) => {
                    return (
                      <MenuItem
                        sx={{ width: "100%", minHeight: "3.5rem" }}
                        value={item.value}
                        key={item.value}
                      >
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="medium"
                  color="success"
                  variant="contained"
                  onClick={handleGetAgents}
                >
                  Get Agent Automatically
                </Button>
              </Grid>
            </Grid>
            {!isNull && autoClicked && (
              <>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-multiline-static"
                      label="Agent Details"
                      multiline
                      InputProps={{
                        readOnly: true,
                      }}
                      rows={4}
                      value={filteredKeyValuePairs}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {isNull && (
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="nextStep-label">{null}</InputLabel>
                  <MDInput
                    value={"No Agent is available for next step"}
                    disabled
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={2}></Grid>
            {autoClicked && (
              <Grid container spacing={2}>
                <Grid item xs={2} md={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    p={1}
                  >
                    {!isNull && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={handleSwitchChange}
                          />
                        }
                        label="Use Auto Assigned Agent?"
                      />
                    )}
                    <Button
                      size="medium"
                      color="success"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Change
                    </Button>
                  </MDBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default StatusChange;
