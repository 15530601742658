// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";

//  React example components
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components

// Images

// Data
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//  React components

// Billing page components
import { setGlobalState } from "Global";
import { caseAPI } from "api";
import { Form } from "components/controls/useForm";

import { MarkerType } from "reactflow";

// import "reactflow/dist/style.css";
import { AppBar, Icon, Tab, Tabs } from "@mui/material";
import "css/workflow/workflow.css";
import { ReactFlowProvider } from "reactflow";
import LayoutFlow from "./dagre-tree";
import { useGlobalState } from "Global";

const edgeType = "simplebezier";

function WorkflowView({ defaultWorkflow = "64f2fbe7c7f041e2041484a4" }) {
  const navigation = useNavigate();

  const [workflows, setWorkflows] = useState([]);
  const [workflowDetail, setWorkflowDetail] = useState({});
  const [isLoadingWorflowDetails, setIsLoadingWorflowDetails] = useState(false);
  const [state, setState] = useState({});
  const [initialNodes, setInitialNodes] = useState(null);
  const [initialEdges, setInitialEdges] = useState(null);
  const [tabValue, setTabValue] = useState(defaultWorkflow); // Collection Checker Workflow
  const [workflowView] = useGlobalState("workflowView");

  const getWorkflows = () => {
    caseAPI
      .getWorkflows()
      .then((res) => {
        if (res.status == 200) {
          setWorkflows(res.data);
        }
      })
      .catch((error) => {});
  };

  const getBucketPosition = (departmentList) => {
    let bucketWidth = 100 / departmentList.length;
    let bucketPosition = {};
    departmentList.forEach((department, index) => {
      bucketPosition[department] = (bucketWidth / 2 + bucketWidth * index) * 2;
    });
    return bucketPosition;
  };

  const getWorkflowDetails = (workflowId) => {
    setIsLoadingWorflowDetails(true);
    caseAPI
      .getWorkflowById(workflowId)
      .then((res) => {
        if (res.status == 200) {
          let tempWorkflowDetail = {
            workflow: res.data.workflow,
            name: res.data.name,
            bucketPositions: getBucketPosition(res.data.departments_list),
          };
          setWorkflowDetail(tempWorkflowDetail);
          setGlobalState("workflowView", (prevView) => {
            let newView = { ...prevView };
            return { ...newView, [workflowId]: tempWorkflowDetail };
          });
          setIsLoadingWorflowDetails(false);
        }
      })
      .catch((error) => {
        setIsLoadingWorflowDetails(false);
      });
  };

  const createDepartmentBlocks = async (bucketPositions) => {
    let tempNodes = [];
    for (let department of Object.keys(bucketPositions)) {
      tempNodes.push({
        id: department + "-parentNode",
        position: { x: bucketPositions[department] * 10, y: 0 },
        data: { label: null },
        className: "workflow-category-separator",
        draggable: false,
        connectable: false,
        deletable: false,
      });
    }
    return tempNodes;
  };

  const createWorkflowNodes = async (tempNodes, workflow, highlightNode) => {
    let tempEdges = [];
    for (const step of Object.keys(workflow)) {
      tempNodes.push({
        id: step,
        position: { x: 0, y: 0 },
        data: { label: step, department: workflow[step]["department"] },
        className: step == highlightNode ? "node-selected" : "",
        // parentNode: workflow[step]["department"] + "-parentNode",
        // extent: "parent",
      });
      for (const edge of Object.keys(workflow[step]["edges"])) {
        tempEdges.push({
          id: "e" + step + "-" + workflow[step]["edges"][edge],
          source: step,
          target: workflow[step]["edges"][edge],
          type: edgeType,
          label: edge != "next_step" ? edge : null,
          markerEnd: { type: MarkerType.ArrowClosed, width: 15, height: 15 },
          style: {
            strokeWidth: 2,
          },
          animated: true,
        });
      }
    }

    return tempNodes, tempEdges;
  };
  const initializeWorkflow = async (workflow, highlightNode = null) => {
    let tempNodes = [];
    let tempEdges = [];

    // tempNodes = await createDepartmentBlocks(workflow.bucketPositions);

    tempNodes,
      (tempEdges = await createWorkflowNodes(
        tempNodes,
        workflow.workflow,
        highlightNode
      ));

    setInitialNodes(tempNodes);
    setInitialEdges(tempEdges);
  };

  const handleWorkflowChange = (event, newValue) => {
    setTabValue(newValue);
    setState({ ...state, workflow: newValue });
    if (workflowView[newValue]) {
      setWorkflowDetail(workflowView[newValue]);
    } else {
      getWorkflowDetails(newValue);
    }
  };

  useEffect(() => {
    getWorkflows();
    if (workflowView[defaultWorkflow]) {
      setWorkflowDetail(workflowView[defaultWorkflow]);
    } else {
      getWorkflowDetails(defaultWorkflow);
    }
  }, []);

  useEffect(() => {
    if (
      workflowDetail.workflow &&
      Object.keys(workflowDetail.workflow).length != 0
    ) {
      initializeWorkflow(workflowDetail);
    }
  }, [workflowDetail.workflow]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ marginBottom: "1rem" }}>
              <AppBar position="static" sx={{ padding: "0.5rem" }}>
                <Tabs
                  orientation={"horizontal"}
                  value={tabValue}
                  onChange={handleWorkflowChange}
                >
                  {workflows.map((workflow, index) => {
                    return (
                      <Tab
                        label={
                          <MDBox
                            sx={{ fontSize: "1rem !important" }}
                            className={"font-unset"}
                          >
                            {workflow.name}
                          </MDBox>
                        }
                        value={workflow._id}
                        key={index}
                      />
                    );
                  })}
                </Tabs>
              </AppBar>
              {/* <Form style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="email-to-label">WorkFlow Name</InputLabel>
                      <Select
                        id="workflow_id"
                        labelId="email-to-label"
                        value={state.workflow}
                        name="workflow_id"
                        size="small"
                        label={"WorkFlow Name"}
                        onChange={(e) => {
                          handleWorkflowSelect(e);
                        }}
                        sx={{
                          marginLeft: "2",
                          width: "100%",
                          minHeight: "3.5rem",
                        }}
                      >
                        {workflows.map((workflow) => {
                          return (
                            <MenuItem
                              sx={{ width: "100%" }}
                              value={workflow._id}
                              key={workflow._id}
                            >
                              {workflow.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Form> */}
              {/* <div style={{ width: "100vw", height: "100vh" }}>
                <ReactFlow nodes={initialNodes} edges={initialEdges} />
              </div> */}
              {initialNodes && initialEdges && workflowDetail.bucketPositions  && (
                <div
                  style={{ width: "100%", height: "150vh", minHeight: "80vh" }}
                  key={initialNodes}
                >
                  <ReactFlowProvider>
                    <LayoutFlow
                      nodes={initialNodes}
                      edges={initialEdges}
                      bucketPositions={workflowDetail.bucketPositions}
                      height={"100%"}
                      width={"100%"}
                      workflowName={workflowDetail.name}
                      key={initialEdges}
                    />
                  </ReactFlowProvider>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default WorkflowView;
