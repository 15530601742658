/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import { AvatarGenerator } from "random-avatar-generator";
import MDAvatar from "components/MDAvatar";
import IsLoadingHOC from "utils/LoadingHOC";

// Images
import { agentAPI } from "api";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";
import { toDecimalMark } from "../../../utils/conversion";
import MDButton from "components/MDButton";
const data = (props) => {
  const generator = new AvatarGenerator();
  const [rows, setRows] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [action, setAction] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();

  const caseStateMapping = {
    New: "warning",
    "In-Progress": "info",
    Resolved: "success",
  };

  const chartToFilterMapping = {
    AgeBucket: "age_bucket",
    Workflow: "workflow_id",
    Customer: "customer_name",
    ReasonCodes: "reason_codes",
    SKU: "sku",
    AssignedAgent: "assigned_agent",
    Category: "category",
    CaseState: "case_state",
    ClaimType: "claim_type",
    ClaimStatus: "claim_status",
    days: "days",
    search: "search",
    limit: "limit",
    offset: "offset",
  };

  const setUserCases = (res) => {
    const tempRows = [];
    const tempCases = res.data;
    setPageInfo({ count: res.count, pages: res.pages });

    tempCases.map((caseDetail, index) => {
      const row = {
        caseState: (
          <MDTypography
            component="a"
            variant="button"
            color="yellow"
            fontWeight="medium"
          >
            <MDButton
              variant={"outlined"}
              color={caseStateMapping[caseDetail.case_state]}
              sx={{
                padding: "0",
                minHeight: "fit-content",
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
              }}
            >
              {caseDetail.case_state}
            </MDButton>
          </MDTypography>
        ),
        caseId: caseDetail.case_id,
        claim:
          "ZAR " + toDecimalMark(parseFloat(caseDetail["Claim Amount (ZAR)"])),
        case_start_timestamp: caseDetail.case_start_timestamp.slice(0, 10),
        agentAssigned: caseDetail.assigned_agent_email,
        currentStatus: caseDetail.current_status,
        workflow_id: caseDetail.workflow_id,

        workflow_name: caseDetail.workflow_name,
        case_updated_timestamp: caseDetail.case_updated_timestamp,
        reference_no: caseDetail["Reference Key"],
        // product_delivered_date:caseDetail["Product Delivered date"],
        customer: caseDetail["Customer"],
        claim_status: <MDBox sx={{display:"flex",flexDirection:"column"}} className={"font-unset"}>{Object.keys(caseDetail["Claim Status"]).map((item) => {
          return <MDBox sx={{fontSize:"0.7rem",lineHeight:"normal"}}>{item} : {caseDetail["Claim Status"][item]}</MDBox>
        })}</MDBox>,
        // completion: <Progress color="info" value={60} />,
        action: (
          <MDTypography component="a" name="action" color="text">
            <Icon name="action" id="action">
              more_vert
            </Icon>
          </MDTypography>
        ),
      };
      tempRows.push(row);
    });
    return tempRows;
  };

  const convertToParams = (params) => {
    let tempParams = {};
    if (params) {
      Object.keys(params).forEach((param) => {
        tempParams[chartToFilterMapping[param]] = params[param];
      });
    }
    return tempParams;
  };

  useEffect(() => {
    if (props.caseListType == "default") {
      props.setIsLoading(true);
      const adminCases = location.pathname.includes("adminCases")
        ? "Admin"
        : "";
      const groupedValue = props.caseListType == "default" ? "" : "Grouped";
      const dynamicFunction =
        agentAPI[`getAllCases${adminCases}${groupedValue}`];

      dynamicFunction(
        convertToParams(props.filters),
        convertToParams(props.filters)
      )
        .then((res) => {
          setRows(setUserCases(res.data));
          props.setIsLoading(false);
        })
        .catch((error) => {
          props.setIsLoading(false);

        });
    }
  }, [location.pathname, props.filters, props.caseListType]);

  return {
    columns: [
      { Header: "State", accessor: "caseState", align: "center" },
      { Header: "Case Id", accessor: "caseId", align: "center" },
      { Header: "Reference No", accessor: "reference_no", align: "center" },
      { Header: "Claim Amount", accessor: "claim", align: "center" },
      { Header: "Workflow Name", accessor: "workflow_name", align: "center" },
      // { Header: "Product Delivered Date", accessor: "product_delivered_date", align: "center" },
      { Header: "Customer", accessor: "customer", align: "center" },
      { Header: "Claim Status", accessor: "claim_status", align: "center" },
      { Header: "Agent Assigned", accessor: "agentAssigned", align: "center" },
      { Header: "Current Status", accessor: "currentStatus", align: "center" },
      {
        Header: "Case Start Timestamp",
        accessor: "case_start_timestamp",
        align: "center",
      },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows,
    pages: pageInfo,
  };
};

export default data;
