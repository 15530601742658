import React, { useCallback, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  Background,
  useReactFlow,
} from "reactflow";
import dagre from "dagre";

import DownloadButton from "./pmDownload";
import "reactflow/dist/style.css";
import { useEffect } from "react";

const nodeWidth = 200;
const nodeHeight = 100;
const concurrentNodeGap = 10;

const PMDagre = (props) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const getLayoutedElements = (nodes, edges, direction = "TB") => {
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      if (!node.id.includes("-parentNode")) {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
      }
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph, { rankdir: "TB", nodesep: 20, ranksep: 50 });

    let tempNodes = nodes;
    for (let [index, node] of nodes.entries()) {
      if (!node.id.includes("-parentNode")) {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = "top";
        node.sourcePosition = "bottom";

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        };

        tempNodes[index] = node;
      }
    }

    nodes = tempNodes;

    return { nodes, edges };
  };

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    props.nodes,
    props.edges
  );
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const { fitView } = useReactFlow();

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  );

  return (
    <div style={{ height: props.height, width: props.width }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        proOptions={{ hideAttribution: true }}
        // connectionLineType={ConnectionLineType.SmoothStep}
        fitView
        preventScrolling={false}
        edgeTypes={props.edgeTypes}
        key={nodes}
      >
        <DownloadButton />
        <Background variant="dots" gap={16} size={3} color="#dddddd" />
      </ReactFlow>
    </div>
  );
};

export default PMDagre;
