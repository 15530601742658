import { chatAPI } from "api";
import "./index.css"
import ChatRoom from "layouts/chat/chat";
import { useEffect, useState } from "react";
import { useNavigate} from 'react-router-dom'



export default function SideChat({handleClose,caseId}) {
  const navigation = useNavigate();



  return (
    <section id="chatApp" class="chatApp">
        <ChatRoom handleClose={handleClose} case_id={caseId}/>
      {/* <div class="chatApp__loaderWrapper">
        <div class="chatApp__loaderText">Loading...</div>
        <div class="chatApp__loader"></div>
      </div> */}
    </section>
  );
}
