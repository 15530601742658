import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

//  React components
import { Icon, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { setGlobalState } from "Global";
import { basicAPI, client } from "api";
import { Form } from "components/controls/useForm";
import bgImage from "assets/images/background.png";
import JSEncrypt from "jsencrypt";
import crypto from "crypto-js";
import { useGlobalState } from "Global";

const encoder = new TextEncoder();

function Basic() {
  const [state, setState] = useState({});
  
  const navigation = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState();
  const [termsChecked, setTermsChecked] = useState(true);
  const [userDetails] = useGlobalState("userDetails");
  const from = location.state?.from?.pathname || "/";

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSignin = (e) => {
    e.preventDefault();
    basicAPI
      .signinNew(state.username, encryptPassword(state.password))
      .then((res) => {
        if (res.status == 200) {
          
          client.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
          setGlobalState("userDetails", res.data);
          navigation(from=="/authentication/sign-in"?"/":from, { replace: true });
        }else if(res.status == 202){
          setGlobalState("error", {
            open: true,
            message: "2 Factor Authentication is mandatory. Please verify OTP sent to your email.",
            type: "info",
            code: 202,
          });
          navigation("/authentication/verify-email", {state: { from: from, email: state.username,  replace: true }});

        }
      })
      .catch((error) => {
        // console.log(error)
        if ([401, 422].includes(error.response?.status)) {
          let errorMessage = error.response.status==401?error.response.data:"";
          
          setGlobalState("error", {
            open: true,
            message: errorMessage || "Invalid Username or password. Please try again.",
            type: "error",
            code: error.response.status,
          });
        }
      });
  };

  const encryptPassword = (password) => {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(key);
    return jsEncrypt.encrypt(password);
  };

  const getKey = () => {
    basicAPI
      .signinKey()
      .then((res) => {
        if (res.status == 200) {
          setKey(res.data);
        }
      })
      .catch((error) => {
        // console.log(error)
        if ([401, 422].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message: "Invalid Username or password. Please try again.",
            type: "error",
            code: error.response.status,
          });
        }
      });
  };

  const handleLogin = () => {
    basicAPI
      .login()
      .then((res) => {
        let url = res.data.url;
        window.location.replace(url);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getKey();
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Form onSubmit={handleSignin}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              fontWeight="medium"
              fontSize={"medium !important"}
              color="white"
              className={"font-unset"}
            >
              SIGN IN
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pb={2} px={2}>
            <MDBox component="form" role="form">
              <MDBox mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  type="email"
                  label="Email"
                  name="username"
                  id="username"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginLeft: "0 !important",
                    marginRight: "0 !important",
                  }}
                  required={true}
                />
              </MDBox>
              <MDBox mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                <TextField
                  type="password"
                  label="Password"
                  name="password"
                  id="password"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginLeft: "0 !important",
                    marginRight: "0 !important",
                  }}
                  required={true}
                />
              </MDBox>
              <MDBox sx={{ position: "relative" }}>
                <Link to={"/authentication/reset-password"}>
                  <MDBox
                    sx={{
                      float: "right",
                      marginBottom: "1rem",
                      marginTop: "-1rem",
                      marginRight: "2rem",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Forgot Password?
                  </MDBox>
                </Link>
              </MDBox>
              <MDBox
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <input id="checkbox" type="checkbox" checked={termsChecked} onClick={()=>{setTermsChecked(!termsChecked)}} />
                <label for="checkbox">
                  &nbsp; I agree to these{" "}
                  <a
                    href="https://neatprocess.org/terms/privacy-policy"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>
                  .
                </label>
              </MDBox>
              <MDBox mt={1} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  onClick={handleSignin}
                  disabled={!termsChecked}
                  fullWidth
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={1} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  New User?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign Up
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mt={-1} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Sign in with Microsoft?{" "}
                  <MDTypography
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                    onClick={handleLogin}
                    sx={{ cursor: "pointer" }}
                  >
                    <Icon sx={{ paddingTop: "0.1rem" }} fontSize="lg">
                      window
                    </Icon>
                    Login
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Form>
    </BasicLayout>
  );
}

export default Basic;
