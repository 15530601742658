import React from "react";
import { List, ListItem, ListItemText, TextField } from "@mui/material";

export default function ListInput({ name, label, value, error = null, onChange ,disabled,...props}) {
  return (
    <List disabled={disabled}>
      {value && value.map((comment, index) => (
        <ListItem key={index}>
          {/* <ListItemText primary={name} /> */}
          <TextField
            variant="outlined"
            label={"comment"+index}
            name={name}
            value={comment}
            onChange={onChange}
            {...(error && { error: true, helperText: error })}
            {...props}
          />
        </ListItem>
      ))}
    </List>
  );
}
