import { Card } from "@mui/material";
import { setGlobalState } from "Global";
import { AIAgentAPI } from "api";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { caseAPI } from "api";
import MDButton from "components/MDButton";
import CustomAutoComplete from "components/controls/AutoComplete";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import EmptyResult from "utils/emptyResult";
import MDTypography from "components/MDTypography";
import { LottieFiles } from "utils/lottieFiles";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
      box-sizing: border-box;
      width: 320px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 8px 12px;
      border-radius: 8px;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[700] : grey[200]
      };
      box-shadow: 0px 2px 2px ${
        theme.palette.mode === "dark" ? grey[900] : grey[50]
      };
  
      &:hover {
        border-color: ${blue[400]};
      }
  
      &:focus {
        border-color: ${blue[400]};
        box-shadow: 0 0 0 3px ${
          theme.palette.mode === "dark" ? blue[600] : blue[200]
        };
      }
  
      // firefox
      &:focus-visible {
        outline: 0;
      }
    `
);

function AutoTransferAction({
  action,
  index,
  handleNotificationChange,
  handlePerformAction,
  agents,
  loaders,
}) {
  return (
    action && (
      <MDBox sx={{ padding: "0", margin: "0" }}>
        <MDBox
          sx={{
            fontWeight: "500",
            margin: "0.5rem",
            display: "flex",
            alignItems: "center",
            marginBottom: "0.5rem",
            marginLeft: "0",
          }}
        >
          <lottie-player
            key={loaders["autotransfer"] ? "loading" : "success"}
            src={LottieFiles[loaders["autotransfer"] ? "loading" : "success"]}
            background="transparent"
            speed="1"
            style={{
              minWidth:"2rem",
              minHeight:"2rem",
              height: "2rem",
              width: "2rem",
            }}
            {...(loaders["autotransfer"] ? { loop: true } : {})}
            keepLastFrame={true}
            autoplay
            direction="1"
            mode="normal"
          ></lottie-player>
          AutoTransfer{" "}
          {!loaders["autotransfer"] && (<>
        <MDBox sx={{whiteSpace:"nowrap",marginRight:"0.5rem"}}>&nbsp; -&gt;</MDBox>
            <MDBox sx={{ fontWeight: "400" }}>
              Steps that AI will perform
            </MDBox></>
          )}
        </MDBox>
        {!loaders["autotransfer"] && 
        
          <MDBox
            sx={{
              paddingLeft: "2rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between !important",
            }}
          >
            <MDBox sx={{ display: "flex" }}>
              <MDTypography sx={{ fontWeight: "450" }}>
                1) Go to Next Step: &nbsp;
              </MDTypography>
              {action.next_step_chosen} testing the wrap
            </MDBox>
            <MDBox sx={{ margin: "0.5rem 0", fontWeight: "bold" }}>
              <MDTypography sx={{ fontWeight: "450" }}>
                2) Transfer Agent with Message
              </MDTypography>
              <MDBox
                style={{
                  width: "90%",
                  margin: "0rem 1rem 0.5rem 1rem",
                  paddingTop: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <MDBox
                  sx={{
                    fontWeight: "normal",
                    fontSize: "0.7rem !important",
                  }}
                  className="font-unset"
                >
                  Transfer To
                </MDBox>
                {action && (
                  <CustomAutoComplete
                    name={"auto_assign_agent_email"}
                    value={action.auto_assign_agent_email}
                    // label={<MDBox></MDBox>}
                    onChange={(e) =>
                      handleNotificationChange(e, "transfer_action")
                    }
                    options={agents}
                    sx={{ display: "flex", width: "100% !important" }}
                    style={{
                      borderRadius: "5px",
                      lineHeight: "0.5 !important",
                      boxShadow: `2px 2px 4px rgba(0,0,0, 0.15)`,
                      minHeight: "1rem",
                      height: "1rem !important",
                    }}
                    /* styles the label component */
                    // InputLabelProps={{
                    //   style: {
                    //     height: "1rem",
                    //   },
                    // }}
                    /* styles the input component */
                    size={"small"}
                  />
                )}
              </MDBox>
              <MDBox
                sx={{
                  margin: "1rem 0 0 1rem",
                  fontWeight: "normal",
                  fontSize: "0.7rem !important",
                }}
                className="font-unset"
              >
                Notification Content
              </MDBox>
              <Textarea
                value={action["notification_for_next_person"]}
                name={"notification_for_next_person"}
                onChange={(e) => handleNotificationChange(e, "transfer_action")}
                label={"Notification Content"}
                maxRows={"4"}
                style={{
                  width: "90%",
                  margin: "0 1rem 0 1rem",
                  boxShadow: `2px 2px 4px rgba(0,0,0, 0.15)`,
                }}
              />
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                justifyContent: "right",
                cursor: "pointer",
              }}
            >
              <MDButton
                onClick={(e) => handlePerformAction(action)}
                sx={{
                  backgroundColor: "#66bb6a !important",
                  minHeight: "2rem !important",
                  marginRight: "1rem !important",
                  fontSize: "0.7rem !important",
                  height: "2rem !important",
                }}
                className={"font-unset"}
              >
                Transfer
              </MDButton>
            </MDBox>
          </MDBox>
        
        }
      </MDBox>
    )
  );
}

export default AutoTransferAction;
