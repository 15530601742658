import * as React from "react";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { PageThumbnailPlugin } from "./pageThumbnailPlugin";
import { Worker } from "@react-pdf-viewer/core";
import "./index.css";
import { LoadError } from "@react-pdf-viewer/core";

const renderError = (error) => {
  let message = "";
  switch (error.name) {
    case "InvalidPDFException":
      message = "No Preview Present";
      break;
    case "MissingPDFException":
      message = "Missing Document";
      break;
    case "UnexpectedResponseException":
      message = "No Preview Present";
      break;
    default:
      message = "No Preview Present";
      break;
  }

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#e53e3e",
          borderRadius: "0.25rem",
          color: "#fff",
          padding: "0.5rem",
        }}
      >
        {message}
      </div>
    </div>
  );
};

const DisplayThumbnail = ({ fileUrl, pageIndex = 0 }) => {
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = PageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => 0} />,
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Viewer
        fileUrl={fileUrl}
        plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
        renderError={renderError}
      />
    </Worker>
  );
};

export default DisplayThumbnail;
