// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { setGlobalState } from "Global";
import DataTable from "examples/Tables/DataTable";
// Data
import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Icon, Tab, Tabs } from "@mui/material";
import { agentAPI, analyticsAPI } from "api";
import TableBanner from "layouts/tableDisplay/tableBanner";
import data from "layouts/tables/data/projectsTableData";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useLocation, useNavigate } from "react-router-dom";
import CaseListGrouped from "./data/caseListGrouped";
import recommendedCases from "./data/recommendedCases";
import { AIAgentAPI } from "api";
import { useGlobalState } from "Global";


const initialFilters = {
  CaseState: ["New", "In-Progress"],
  AgeBucket: [],
  Workflow: [],
  Customer: [],
  ReasonCodes: [],
  AssignedAgent: [],
  Category: [],
  ClaimType: [],
  ClaimStatus: [],
  limit: 50,
  offset: 0,
};

const initialGroupedFilters = {
  ReferenceKey: [],
  InvoiceNumber: [],
  CustomerCode: [],
  ReasonCodes: [],
  Customer: [],
  limit: 50,
  offset: 0,
};

function Tables(props) {
  const [state, setState] = useState({ caseType: "default" });
  const [agent, setAgent] = useState({});
  const location = useLocation();
  const [user, setUser] = useState("User");
  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState("default");
  const [selectedGroup, setSelectedGroup] = useState({
    offset: 0,
    limit: 50,
    details: null,
  });
  const [showSendMail, setShowSendMail] = useState(false);
  const [groupedCasesList, setGroupedCasesList] = useState([]);

  const navigation = useNavigate();
  const [caseList] = useGlobalState("caseList");


  const [filters, setFilters] = useState(caseList.filters || initialFilters);


  const [groupedFilters, setGroupedFilters] = useState(
    caseList.groupedFilters || initialGroupedFilters
  );

  const handlePerformAction = (caseId, action) => {
    setIsLoading(true);
    AIAgentAPI.performCaseAction(
      { case_id: caseId, data: JSON.stringify(action) },
      {}
    )
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setFilters({
            ...filters,
            substitute: caseId + JSON.stringify(action),
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // const {
  //   columns: recommendedColumns,
  //   rows: recommendedRows,
  //   pages: recommededPageInfo,
  // } = recommendedCases({
  //   setIsLoading: setIsLoading,
  //   filters: filters,
  //   handlePerformAction: handlePerformAction,
  // });

  const {
    columns: pColumns,
    rows: pRows,
    pages: pageInfo,
  } = data({
    setIsLoading: setIsLoading,
    filters: filters,
    caseListType: tabValue,
  });

  const {
    columns: groupedColumns,
    rows: groupedRows,
    pages: groupedPageInfo,
  } = CaseListGrouped({
    setIsLoading: setIsLoading,
    filters: groupedFilters,
    caseListType: tabValue,
  });

  const [filterOptions, setFilterOptions] = useState({});

  const getFilterValues = () => {
    analyticsAPI.getSummaryFilterValues().then((res) => {
      let tempData = res.data;
      Object.keys(tempData).forEach((filter) => {
        tempData[filter].push({ name: "All", _id: "All" });
      });
      setFilterOptions((prevFilterOptions) => {
        return { ...prevFilterOptions, ...res.data };
      });
    });
    analyticsAPI.getGroupedFilterValues().then((res) => {
      let tempData = res.data;
      Object.keys(tempData).forEach((filter) => {
        tempData[filter].push({ name: "All", _id: "All" });
      });
      setFilterOptions((prevFilterOptions) => {
        return { ...prevFilterOptions, ...res.data };
      });
    });
  };

  const getGroupedCasesList = () => {
    agentAPI
      .getGroupedCasesList(selectedGroup, selectedGroup.details)
      .then((res) => {
        if (res.status == 200) {
          setGroupedCasesList(res.data.data);
        }
      });
  };

  useEffect(() => {
    if (selectedGroup.details) {
      getGroupedCasesList();
    }
  }, [selectedGroup.details]);

  useEffect(() => {
    if (location.pathname.includes("adminCases")) {
      setUser("All");
    } else {
      setUser("User");
    }
    getFilterValues();
  }, [location.pathname]);

  const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const handleRowClick = (event, caseId) => {
    const case_id = caseId;
    navigation(`/cases/${case_id}`);
  };

  const handleCasesListRowClick = (event) => {
    const case_id = event.row.values.case_id;
    window.open(`/cases/${case_id}`, "_blank");
  };

  const handleGroupedRowClick = (event) => {
    const { count, ...groupDetails } = event.row.values;
    setSelectedGroup({ ...selectedGroup, details: { ...groupDetails } });
  };

  const handleFilterChange = (event) => {
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
            ? value._id
            : "";
      }
    }

    if (
      typeof event.target.value == typeof [] &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let tempFilters = { ...filters, [event.target.name]: event.target.value };
    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    } else if (
      typeof event.target.value == "list" &&
      event.target.value?.includes("All")
    ) {
      tempFilters[event.target.name] = [];
    }
    setFilters(tempFilters);
    setGlobalState("caseList", {
      ...caseList,
      filters: tempFilters,
      caseListType: tabValue,
    });
  };

  const handleGroupedFilterChange = (event) => {
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
            ? value._id
            : "";
      }
    }

    if (
      typeof event.target.value == typeof [] &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let tempFilters = {
      ...groupedFilters,
      [event.target.name]: event.target.value,
    };
    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    } else if (
      typeof event.target.value == "list" &&
      event.target.value?.includes("All")
    ) {
      tempFilters[event.target.name] = [];
    }
    setGroupedFilters(tempFilters);
    setGlobalState("caseList", {
      ...caseList,
      groupedFilters: tempFilters,
      caseListType: tabValue,
    });
  };

  const handleDownloadBulk = (filters, data, email = false) => {
    setIsLoading(true);
    agentAPI
      .downloadCasesAdmin({ ...filters, email: email }, filters)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          if (email == false) {
            // Create a download link and trigger the download
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = "CaseList.xlsx"; // Set the desired file name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } else {
            setGlobalState("error", {
              open: true,
              message: "The data has been sent. Please check your email.",
              type: "success",
            });
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if ([453].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message:
              "Row Count Exceeded, sending you a mail with attached file",
            type: "error",
            code: error.response.status,
          });
          handleDownloadBulk(filters, data, true);
        } else if ([454].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message:
              "File too large to send, please contact the admin team to get file.",
            type: "error",
            code: error.response.status,
          });
        } else if (![422].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message: "Error while fetching data, please try after sometime.",
            type: "error",
            code: error.response.status,
          });
        }
      });
  };

  const handleSearchChange = (text, type) => {
    let dynamicFunction = type == "Filters" ? setFilters : setGroupedFilters;
    dynamicFunction((prevValue) => {
      return { ...prevValue, search: text };
    });
  };

  return (
    <DashboardLayout>
      <>
        <DashboardNavbar />

        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <TabContext value={tabValue}>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography
                      variant="h6"
                      color="white"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{user && capitalize(user)} Cases</div>
                      <div style={{ position: "relative" }}>
                        <AppBar
                          sx={{
                            position: "absolute",
                            minWidth: "30rem",
                            width: "50%",
                            top: "50%",
                            transform: "translate(0%,-50%)",
                            height: "2rem",
                          }}
                        >
                          {user == "All" && (
                            <Tabs
                              value={tabValue}
                              onChange={(e, newValue) => {
                                setTabValue(newValue);
                              }}
                              sx={{
                                background: "unset",
                                color: "white",
                                border: "1px solid ",
                                boxShadow: "0px 1px 3px 1px white",
                              }}
                            >
                              {/* <Tab
                              label="AI Recommended"
                              value="recommended"
                              icon={
                                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                  account_tree
                                </Icon>
                              }
                              sx={{
                                color:
                                  tabValue != "recommended"
                                    ? "white !important"
                                    : "",
                              }}
                            /> */}
                              {user == "All" && (
                                <Tab
                                  label="Default"
                                  value="default"
                                  icon={
                                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                      remove
                                    </Icon>
                                  }
                                  sx={{
                                    color:
                                      tabValue != "default"
                                        ? "white !important"
                                        : "",
                                  }}
                                />
                              )}
                              {user == "All" && (
                                <Tab
                                  label="Grouped Cases"
                                  value="grouped"
                                  icon={
                                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                      account_tree
                                    </Icon>
                                  }
                                  sx={{
                                    color:
                                      tabValue != "grouped"
                                        ? "white !important"
                                        : "",
                                  }}
                                />
                              )}
                            </Tabs>
                          )}
                        </AppBar>
                      </div>
                    </MDTypography>
                  </MDBox>

                  {/* <TabPanel
                    sx={{ padding: "0", marginTop: "0.5rem" }}
                    value={"recommended"}
                  >
                    {" "}
                    <MDBox>
                      <DataTable
                        table={{
                          columns: recommendedColumns,
                          rows: recommendedRows,
                        }}
                        isSorted={true}
                        entriesPerPage={true}
                        showTotalEntries={true}
                        downloadable={{ type: "bulk" }}
                        handleDownloadBulk={handleDownloadBulk}
                        noEndBorder
                        canSearch={true}
                        rowClick={handleRowClick}
                        filters={filters}
                        filterOptions={filterOptions}
                        handleFilterChange={handleFilterChange}
                        customPagination={true}
                        ignoreFilterList={["days", "limit", "offset"]}
                        pageInfo={recommededPageInfo}
                        caseListType={"default"}
                        isLoading={isLoading}
                        AIRecommended={true}
                        handleSearchChange={(text)=>handleSearchChange(text,"Filters")}
                        // columnFilter={true}
                      />
                    </MDBox>
                  </TabPanel> */}

                  <TabPanel
                    sx={{ padding: "0", marginTop: "0.5rem" }}
                    value={"default"}
                  >
                    {" "}
                    <MDBox>
                      <DataTable
                        table={{ columns: pColumns, rows: pRows }}
                        isSorted={true}
                        entriesPerPage={true}
                        showTotalEntries={true}
                        downloadable={{ type: "bulk" }}
                        handleDownloadBulk={handleDownloadBulk}
                        noEndBorder
                        canSearch={true}
                        rowClick={handleRowClick}
                        filters={filters}
                        filterOptions={filterOptions}
                        handleFilterChange={handleFilterChange}
                        customPagination={true}
                        ignoreFilterList={["days", "limit", "offset"]}
                        pageInfo={pageInfo}
                        caseListType={"default"}
                        isLoading={isLoading}
                        handleSearchChange={(text) =>
                          handleSearchChange(text, "Filters")
                        }
                        // columnFilter={true}
                      />
                    </MDBox>
                  </TabPanel>
                  <TabPanel
                    sx={{ padding: "0", marginTop: "0.5rem" }}
                    value={"grouped"}
                  >
                    {" "}
                    <MDBox>
                      <DataTable
                        table={{ columns: groupedColumns, rows: groupedRows }}
                        isSorted={true}
                        entriesPerPage={true}
                        showTotalEntries={true}
                        downloadable={{ type: "bulk" }}
                        handleDownloadBulk={handleDownloadBulk}
                        noEndBorder
                        canSearch={true}
                        rowClick={handleGroupedRowClick}
                        filters={groupedFilters}
                        filterOptions={filterOptions}
                        handleFilterChange={handleGroupedFilterChange}
                        customPagination={true}
                        ignoreFilterList={["days", "limit", "offset"]}
                        pageInfo={groupedPageInfo}
                        caseListType={"grouped"}
                        isLoading={isLoading}
                        handleSearchChange={(text) =>
                          handleSearchChange(text, "GroupedFilters")
                        }
                        // columnFilter={true}
                      />
                    </MDBox>
                  </TabPanel>
                </TabContext>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {selectedGroup.details && groupedCasesList.length > 0 && (
          <TableBanner
            open={true}
            handleClose={() => {
              setSelectedGroup({ ...selectedGroup, details: null });
            }}
            data={groupedCasesList}
            rowClick={handleCasesListRowClick}
          />
        )}
      </>
    </DashboardLayout>
  );
}

export default Tables;
