import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


function FeaturesSkeleton() {
  return (
    <Card sx={{ margin: 5 }}>
      <SkeletonTheme baseColor="#459FF0" highlightColor="white">
        <MDBox
          mx={0}
          mt={0}
          mb={2}
          py={2}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          sx={{
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          {" "}
          <Skeleton containerClassName={"flex-1"} />
        </MDBox>
      </SkeletonTheme>
      <SkeletonTheme highlightColor="white">
        <MDBox p={2}>
          <MDBox sx={{ padding: "1rem" }}>
            <Form>
              <Grid container>
                {Array(24)
                  .fill(0)
                  .map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        sx={{
                          margin: "1rem 0",
                          position: "relative",
                        }}
                        key={index}
                      >
                        <Skeleton
                          containerClassName="flex-1"
                          style={{
                            height: "2rem",
                            width: "80%",
                            marginLeft: "2rem",
                          }}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Form>
            <MDBox
              sx={{
                display:"flex",
                justifyContent: "end",
                margin: "2rem 2rem 1rem",
              }}
            >
              <Skeleton
                containerClassName="flex-1"
                style={{
                  height: "2rem",
                  width: "5rem",
                  
                }}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </SkeletonTheme>
    </Card>
  );
}

export default FeaturesSkeleton;
