// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";

//  React example components
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components

// Images

// Data
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//  React components

// Billing page components
import { setGlobalState } from "Global";
import { caseAPI } from "api";
import { Form } from "components/controls/useForm";

import { MarkerType } from "reactflow";

// import "reactflow/dist/style.css";
import "css/workflow/workflow.css";
import { ReactFlowProvider } from "reactflow";
import PMDagre from "./pmDagre";
import CustomEdge from "./pmCustomEdge";
import { rgb } from "d3";

const edgeType = "simplebezier";

function PMWorkflow(props) {
  const navigation = useNavigate();
  const [workflowDetail, setWorkflowDetail] = useState({});
  const [initialNodes, setInitialNodes] = useState(null);
  const [initialEdges, setInitialEdges] = useState(null);

  const getColor = (value, minValue, maxValue) => {
    if(value==-1){
        return `#348dec`
    }
    // Ensure the value is within the specified range
    const clampedValue = Math.min(Math.max(value, minValue), maxValue);
  
    // Normalize the clamped value to be between 0 and 1
    const normalizedValue = (clampedValue - minValue) / (maxValue - minValue);
  
    // Interpolate between green and red based on the normalized value
    const red = Math.round(245 * normalizedValue);
    const green = Math.round(245 * (1 - normalizedValue));
    const blue = 0; // Blue is set to 0 for a gradient between green and red
  
    // Return the RGB color as a string
    return `rgb(${red},${green},${blue})`;
  };

  const calculateMinMaxTime = (dataList) => {
    if (!dataList || dataList.length === 0) {
      return { minTime: null, maxTime: null };
    }
  
    return dataList.reduce(
      (acc, item) => {
        const currentTime = item.data.details.time
        return {
          minTime: Math.min(acc.minTime, currentTime),
          maxTime: Math.max(acc.maxTime, currentTime),
        };
      },
      { minTime: dataList[0].data.details.time, maxTime: dataList[0].data.details.time }
    );
  };

  const createWorkflowNodes = async (tempNodes) => {
    let tempEdges = [];
    const {minTime,maxTime} = calculateMinMaxTime(props.nodes)
    // const {minTime,maxTime} = {minTime:0,maxTime:20}
    
    for (const step of props.nodes) {
        step.data.label =<div>{step.data.label} {step.data.details.time!=-1?<div> Invested-time : {step.data.details.time}</div>:"" }  {step.data.details.frequency!=-1?<div> Count : {step.data.details.frequency}</div>:"" } </div>
      tempNodes.push({
        id: step.id.toString(),
        position: { x: 0, y: 0 },
        data: step.data,
        style: {
          backgroundColor: getColor(
            step.data.details["time"],minTime,maxTime
          ),
        minWidth:"200px",
          color: "black",
        },
        // parentNode: workflow[step]["department"] + "-parentNode",
        // extent: "parent",
      });
    }
    for (const edge of props.edges) {
        edge.label =<div>{edge.details.time!=-1?<div> Time : {edge.details.time}</div>:"" }  {edge.details.frequency!=-1?<div> Count : {edge.details.frequency}</div>:"" } </div>

      tempEdges.push({
        id: "e" + edge.id.toString(),
        source: edge.source.toString(),
        target: edge.target.toString(),
        type: "custom",
        // label:  "test",
        data:{label:edge.label},
        markerEnd: { type: MarkerType.ArrowClosed, width: 15, height: 15 },
        style: {
          strokeWidth: 2,
        },
        animated: true,
      });
    }

    return tempNodes, tempEdges;
  };

  const edgeTypes = {
    custom: CustomEdge,
  };


  const initializeWorkflow = async () => {
    let tempNodes = [];
    let tempEdges = [];

    tempNodes, (tempEdges = await createWorkflowNodes(tempNodes));

    setInitialNodes(tempNodes);
    setInitialEdges(tempEdges);
  };

  useEffect(() => {
    if (props.nodes && props.nodes.length != 0) {
      initializeWorkflow(workflowDetail);
    }
  }, [props.nodes, props.edges]);

  return (
    <Card
      sx={{
        marginBottom: "1rem",
        marginTop: "2rem",
        height: "150vh",
        maxHeight: "95%",
      }}
    >
      {initialNodes && initialEdges && (
        <div
          style={{
            width: "60vw",
            maxWidth: "100%",
            height: "150vh",
            maxHeight: "100%",
          }}
          key={initialNodes}
        >
          <ReactFlowProvider>
            <PMDagre
              nodes={initialNodes}
              edges={initialEdges}
              height={"100%"}
              width={"100%"}
              key={initialEdges}
              edgeTypes={edgeTypes}
            />
          </ReactFlowProvider>
        </div>
      )}
    </Card>
  );
}

export default PMWorkflow;
