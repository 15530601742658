// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import { Send } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, TextField, Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";

import { Typography, Paper, InputLabel, Box, Fab, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { caseAPI } from "api";
import { setGlobalState } from "Global";
import MDButton from "components/MDButton";
import LoadingOverlay from "react-loading-overlay";
import Controls from "components/controls/Controls";
import { convertKeyToName } from "utils/conversion";
import { AdminAPI } from "api";

const DefaultUserDetailsForm = {
  email_id: {
    type: "Text",
    editable: false,
    required: true,
  },
  name: {
    type: "Text",
    editable: true,
    required: true,
  },

  active_status: {
    type: "DropdownSingleSelect",
    options: ["Available", "Busy", "OOO"],
    editable: true,
    required: false,
  },
  secondary_contact: {
    type: "DropdownSingleSelect",
    options: [],
    editable: true,
    required: false,
  },
  permission: {
    type: "DropdownSingleSelect",
    options: ["admin", "3PA", "3PU", "agent1", "agent2"],
    editable: true,
    required: false,
  },
  manager_email: {
    type: "DropdownSingleSelect",
    options: [],
    editable: true,
    required: false,
  },
  organization: {
    type: "Text",
    editable: true,
    required: false,
  },
};

function UserDetailsForm({
  open,
  handleClose,
  userDetails,
  userList,
  getUsers,
  type = "Update",
}) {
  const [state, setState] = useState({});
  const location = useLocation();
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: {
        ...state[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let tempState = { ...state };
    Object.keys(tempState).map((feature) => {
      tempState[feature] = tempState[feature].value || null;
    });
    if (type == "Create") {
      AdminAPI.addAdminUser(tempState)
        .then((res) => {
          if (res.status == 200) {
            setIsLoading(false);
            handleClose(!open);
            getUsers();
            setGlobalState("error", {
              open: true,
              message: "User created successfully",
              type: "success",
              code: 200,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      AdminAPI.updateAdminUserData(tempState)
        .then((res) => {
          if (res.status == 200) {
            setIsLoading(false);
            handleClose(!open);
            getUsers();
            setGlobalState("error", {
              open: true,
              message: "User details updated successfully",
              type: "success",
              code: 200,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const initializeUserDetails = () => {
    let tempState = {};
    Object.keys(DefaultUserDetailsForm).map((feature) => {
      if (DefaultUserDetailsForm[feature].options?.length == 0) {
        DefaultUserDetailsForm[feature].options = userList;
      }

      tempState[feature] = DefaultUserDetailsForm[feature];
      tempState[feature].value = userDetails[feature];
    });

    if (type == "Create") {
      tempState["email_id"].editable = true;
    }
    setState(tempState);
  };

  useEffect(() => {
    if (userDetails) {
      initializeUserDetails();
    }
  }, [userDetails]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", maxWidth: "30rem" }}
      >
        <LoadingOverlay
          active={isLoading}
          spinner
          text="Loading your content..."
        >
          <Box sx={{ padding: 5 }}>
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                paddingBottom: 2,
                textAlign: "center",
                fontSize: "medium !important",
              }}
              className="font-unset"
            >
              {type == "Update" ? "Update User Details" : "Create User"}
            </Typography>
            <Grid container spacing={3}>
              {Object.keys(state).map((feature) => {
                let ControlType = Controls[state[feature]?.type];
                return (
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      position: "relative",
                      // justifyContent:"center"
                    }}
                  >
                    <FormControl fullWidth>
                      <ControlType
                        name={feature}
                        label={convertKeyToName(feature)}
                        value={state[feature]?.value}
                        onChange={handleChange}
                        disabled={!state[feature]?.editable}
                        options={state[feature]?.options}
                        style={{
                          //   borderRadius: "5px",
                          height: "2.75rem",
                          lineHeight: 1,
                          //   boxShadow: `2px 2px 4px rgba(0,0,0, 0.15)`,
                        }}
                        textFieldStyles={{
                          height: "2.75rem !important",
                        }}
                      />
                    </FormControl>
                  </Grid>
                );
              })}

              <Grid item xs={2} md={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                  p={1}
                >
                  <Button
                    size="medium"
                    color="success"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    <Send sx={{ mr: 1 }} />
                    {type == "Update" ? "Update" : "Create"}
                  </Button>
                </MDBox>
              </Grid>
            </Grid>
          </Box>
        </LoadingOverlay>
      </Paper>
    </Dialog>
  );
}

export default UserDetailsForm;
