import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

//  React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import Button from "components/controls/Button";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  Tooltip,
  Card,
  Badge,
  Paper,
  TextField,
  Typography,
  Popover,
  Divider,
  InputAdornment,
} from "@mui/material";
import { Grid } from "@mui/material";
import { CSVLink } from "react-csv";
import MDButton from "components/MDButton";
import ButtonGroup from "examples/ButtonGroup";
import { exportToExcel } from "react-json-to-excel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faFilter } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core";
import RowSkeleton from "skeletons/tables/rowSkeleton";
import HeaderSkeleton from "skeletons/tables/headerSkeleton";

const caseStateMapping = {
  New: "warning",
  "In-Progress": "info",
  Resolved: "success",
};

const useStyles = makeStyles({
  popper: { minWidth: "max-content !important" },
});

const daysList = [10, 20, 30, 60, 90, 180];

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  rowClick,
  selectall,
  submit,
  pageDetails,
  columnFilter,
  handleColumnFilter,
  handleDaysFilter,
  filters,
  filterOptions,
  handleFilterChange,
  downloadable,
  downloadableBulk,
  handleDownloadBulk,
  downloadFileName,
  customPagination,
  ignoreFilterList,
  pageInfo,
  customStyle,
  rowColor,
  isLoading,
  AIRecommended = false,
  handleSearchChange,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 50;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25", "50", "100", "500"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const [showFilters, setShowFilters] = useState(false);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 }, autoResetPage: false },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const classes = useStyles();

  const [openFilterOptions, setOpenFilterOptions] = useState({ new: false });

  const [anchorEl, setAnchorEl] = useState(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  const [customPageOptions, setCustomPageOptions] = useState([]);
  const [customPageIndex, setCustomPageIndex] = useState(pageIndex);
  const [canPreviousPage, setCanPreviousPage] = useState(true);
  const [canNextPage, setCanNextPage] = useState(true);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);
  pageDetails?.setPageSize(pageSize);
  pageDetails?.setPageNo(customPageIndex + 1);

  // Create a list of page indexes for page options
  useEffect(() => {
    if (customPagination) {
      let tempPageOptions = [];
      for (let i = 0; i < pageInfo.pages; i++) {
        tempPageOptions.push(i);
      }
      setCustomPageOptions(tempPageOptions);
    }
  }, [pageInfo]);

  useEffect(() => {
    if (!customPagination) {
      setCustomPageOptions(pageOptions);
    }
  }, [pageOptions]);

  useEffect(() => {
    if (customPageIndex == 0) {
      setCanPreviousPage(false);
    } else {
      setCanPreviousPage(true);
    }
    if (customPageIndex >= customPageOptions.length - 1) {
      setCanNextPage(false);
    } else {
      setCanNextPage(true);
    }
  }, [customPageIndex]);

  // Render the paginations
  const renderPagination = customPageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => handlePageSelect(Number(option))}
      active={customPageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  const handlePageSelect = (indexValue) => {
    if (customPagination) {
      // Call handleFilter Change with pagination index
      handleFilterChange({ target: { name: "offset", value: indexValue } });
      setCustomPageIndex(indexValue);
    } else {
      // Call gotoPage with the index n.o
      gotoPage(indexValue);
      setCustomPageIndex(indexValue);
    }
  };

  const filterColumns = (data) => {
    // Get column names
    const headerColumns = Object.keys(data[0]);
    let headers = [];
    headerColumns.forEach((col, idx) => {
      headers.push({ label: col.toUpperCase(), key: col });
    });

    return headers;
  };

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) => {
    value > customPageOptions.length || value < 0
      ? handlePageSelect(0)
      : handlePageSelect(Number(value) - 1);
  };

  const handleEntriesPerPageChange = (event, newValue) => {
    setEntriesPerPage(parseInt(newValue, 10));
    if (customPagination) {
      handleFilterChange({
        target: { name: "limit", value: parseInt(newValue, 10) },
      });
      setCustomPageIndex(0);
    }
  };

  // Search input value state
  const [search, setSearch] = useState(handleSearchChange?globalFilter:"");

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const customNextPage = () => {
    handlePageSelect(customPageIndex + 1);
  };

  const customPreviousPage = () => {
    handlePageSelect(customPageIndex - 1);
  };

  // Setting the entries starting point
  const entriesStart =
    customPageIndex === 0
      ? customPageIndex + 1
      : customPageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (customPageIndex === 0) {
    entriesEnd = pageSize;
  } else if (customPageIndex === customPageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (customPageIndex + 1);
  }

  function getStyles(option, selectedList) {
    return {
      fontWeight: selectedList.includes(
        option.email_id ? option.email_id : option._id ? option._id : option
      )
        ? "regular"
        : "medium",
    };
  }

  return (
    <MDBox>
      {entriesPerPage || canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position={"relative"}
          sx={{ margin: "0 2rem" }}
        >
          {entriesPerPage && (
            <MDBox
              display="flex"
              alignItems="center"
              sx={{
                // position: "absolute",
                // left: "2rem",

                width: "max-content",
              }}
            >
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  handleEntriesPerPageChange(event, newValue);
                }}
                size="small"
                sx={{ width: "7rem" }}
                style={{
                  display: "inline-block",
                  textAlign: "right",
                }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          <MDBox sx={{ display: "flex", flexDirection: "row" }}>
            {downloadable && data.length != 0 && (
              <MDBox
                display="inline-block"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0.5rem 1rem",
                  cursor: "pointer",
                }}
              >
                <Tooltip title={"Download"}>
                  <Icon
                    onClick={() => {
                      if (downloadable.type == "bulk") {
                        handleDownloadBulk(filters, data);
                      } else if (downloadable.type == "mail") {
                        handleDownloadBulk(filters, data, true);
                      } else {
                        exportToExcel(
                          [{ sheetName: "Details", details: data }],
                          downloadFileName ? downloadFileName : "DataFile",
                          true
                        );
                      }
                    }}
                    className={"font-unset"}
                    fontSize="3rem"
                  >
                    download
                  </Icon>
                </Tooltip>
              </MDBox>
            )}
            {filters && (
              <>
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0.5rem 1rem",
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    setShowFilters(!showFilters);
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <Tooltip title={"Filters"}>
                    <Badge
                      badgeContent={
                        Object.keys(filters).filter((filterName) => {
                          if (
                            typeof filters[filterName] == "object" &&
                            filters[filterName].length > 0
                          )
                            return true;
                        }).length + 1
                      }
                      color="info"
                    >
                      <Icon className={"font-unset"} fontSize="3rem">
                        filter_alt
                      </Icon>
                    </Badge>
                  </Tooltip>
                </MDBox>
                <Popover
                  open={showFilters}
                  id={"simple-popover"}
                  anchorEl={anchorEl}
                  onClose={() => {
                    setShowFilters(!showFilters);
                    setAnchorEl(null);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Card
                    sx={{
                      zIndex: "20",
                      boxShadow: "1px 5px 10px 2px grey",
                      maxWidth: "20rem",
                    }}
                  >
                    <Grid
                      container
                      pl={"2rem"}
                      pr={"2rem"}
                      sx={{
                        minWidth: "20rem",
                        width: "100%",
                        display: "flex",
                        marginTop: "1rem",
                      }}
                    >
                      {Object.keys(filters).map((filterName, index) => {
                        if (!ignoreFilterList.includes(filterName))
                          return (
                            <>
                              <Grid item xs={6}>
                                <FormControl
                                  size={"small"}
                                  sx={{
                                    width: "90%",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  {filterOptions[filterName]?.length > 0 && (
                                    <Autocomplete
                                      multiple
                                      value={filters[filterName]}
                                      onChange={(event, newValue) => {
                                        // setOpenFilterOptions({
                                        //   ...openFilterOptions,
                                        //   [filterName]: false,
                                        // });
                                        handleFilterChange({
                                          target: {
                                            name: filterName,
                                            value: newValue,
                                          },
                                        });
                                      }}
                                      size={"small"}
                                      id="multiple-limit-tags"
                                      options={filterOptions[filterName]}
                                      getOptionLabel={(option) => {
                                        return option?.name
                                          ? option.name
                                          : option;
                                      }}
                                      // onOpen={() => {
                                      //   setOpenFilterOptions({
                                      //     ...openFilterOptions,
                                      //     [filterName]: false,
                                      //   });
                                      // }}
                                      // onClose={() => {
                                      //   setOpenFilterOptions({
                                      //     ...openFilterOptions,
                                      //     [filterName]: false,
                                      //   });
                                      // }}
                                      classes={{ popper: classes.popper }}
                                      PaperComponent={(props) => (
                                        <Paper
                                          sx={{
                                            fontSize: "25px",
                                          }}
                                          {...props}
                                        />
                                      )}
                                      renderInput={(params) => {
                                        return (
                                          <TextField
                                            {...params}
                                            label={filterName}
                                            placeholder={filterName}
                                            InputProps={{
                                              ...params.InputProps,

                                              endAdornment: <></>,
                                            }}
                                          />
                                        );
                                      }}
                                      renderTags={(
                                        value,
                                        getTagProps,
                                        ownerState
                                      ) => {
                                        let showChips = filters[
                                          filterName
                                        ].slice(0, 2);
                                        if (ownerState.focused) {
                                          showChips = filters[filterName];
                                        }
                                        return (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              gap: 0.1,
                                              justifyContent: "left",
                                            }}
                                          >
                                            {showChips.map((value, index) => {
                                              if (
                                                index <
                                                (ownerState.focused
                                                  ? filters[filterName].length
                                                  : 1)
                                              ) {
                                                return (
                                                  <Tooltip
                                                    title={value}
                                                    placement="bottom-start"
                                                  >
                                                    <Chip
                                                      key={value}
                                                      label={value}
                                                      sx={{
                                                        fontSize: "x-small",
                                                        height: "fit-content",
                                                      }}
                                                    />
                                                  </Tooltip>
                                                );
                                              } else {
                                                return (
                                                  <Chip
                                                    key={"+1"}
                                                    label={`+${
                                                      filters[filterName]
                                                        .length - 1
                                                    }`}
                                                    sx={{
                                                      fontSize: "x-small",
                                                      height: "fit-content",
                                                    }}
                                                  />
                                                );
                                              }
                                            })}
                                          </Box>
                                        );
                                      }}
                                      sx={{ width: "100%" }}
                                    />
                                  )}
                                </FormControl>
                              </Grid>
                            </>
                          );
                      })}
                    </Grid>
                  </Card>
                </Popover>
              </>
            )}
            {canSearch && (
              <MDBox sx={{ width: "15rem" }}>
                <MDInput
                  placeholder="Search..."
                  value={search}
                  size="small"
                  style={{
                    // position: "absolute",
                    // right: "2rem",
                    width: "15rem",
                    // top: "1rem",
                    textAlign: "right",
                  }}
                  fullWidth
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if(!handleSearchChange){
                      onSearchChange(e.target.value);
                    }else if(e.target.value==""){
                      handleSearchChange("")
                    }
                    
                    
                  }}
                  // If handleSearchChange is there then show the search icon else hide it
                  InputProps={{
                    endAdornment: handleSearchChange?<InputAdornment position='end'><Tooltip title={"Search"}><FontAwesomeIcon onClick={(e)=>handleSearchChange(search)} fontSize={"large"} style={{cursor:"pointer",color:search?"#348dec":"grey"}} className="font-unset" icon={faArrowAltCircleRight}></FontAwesomeIcon></Tooltip></InputAdornment>:<></>,
                  }}
                  
                />
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      ) : null}
      <TableContainer
        sx={
          customStyle
            ? customStyle
            : {
                boxShadow: "none",

                paddingBottom: "5rem",
              }
        }
      >
        {/* {selectall ? (
        <MDBox
          style={{
            display: "flex",
            justifyContent: "end",
            textAlign: "right",
            marginRight: "2rem",
          }}
        >
          <span>
            <MDTypography
              variant="caption"
              color="secondary"
              style={{ marginRight: "5px" }}
            >
              Select All
            </MDTypography>
            <Checkbox checked={selectall.check} onChange={selectall.func} />
          </span>
        </MDBox>
      ) : null} */}
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {isLoading == true && headerGroups.length == 0 && (
              <HeaderSkeleton />
            )}
            {(isLoading == undefined || isLoading == false) &&
              headerGroups.map((headerGroup, index) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column) => (
                    <DataTableHeadCell
                      {...column.getHeaderProps(
                        isSorted && column.getSortByToggleProps()
                      )}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {isLoading == true && (
              <RowSkeleton columns={table.columns.length || 10} />
            )}
            {(isLoading == undefined || isLoading == false) &&
              page.map((row, key) => {
                prepareRow(row);
                const isIdMatched = rowColor
                  ? row.values[rowColor["id"]["name"]] ==
                    rowColor["id"]["value"]
                  : false;
                return (
                  <>
                    {!AIRecommended && (
                      <TableRow color="info" {...row.getRowProps()}>
                        {row.cells.map((cell, index) => (
                          <DataTableBodyCell
                            key={index}
                            bgcolor={
                              isIdMatched
                                ? rowColor["backgroundColor"]
                                : "white"
                            }
                            noBorder={noEndBorder && rows.length - 1 === key}
                            color={isIdMatched ? rowColor["color"] : "black"}
                            align={
                              cell.column.align ? cell.column.align : "left"
                            }
                            {...cell.getCellProps()}
                            onclick={() => {
                              if (rowClick && cell.column?.id != "action") {
                                rowClick(row.cells[0], row.original?.caseId,row.original);
                              }
                            }}
                            case_id={row.original?.caseId}
                            style={{ paddingBottom: "0" }}
                          >
                            {cell.render("Cell")}
                          </DataTableBodyCell>
                        ))}
                      </TableRow>
                    )}
                    {AIRecommended && (
                      <TableRow
                        color="info"
                        {...row.getRowProps()}
                        sx={{ height: "6rem !important" ,position:"relative"}}
                      >
                        <>
                          {row.cells.map((cell, index) => (
                            <DataTableBodyCell
                              key={index}
                              bgcolor={
                                isIdMatched
                                  ? rowColor["backgroundColor"]
                                  : "white"
                              }
                              noBorder={noEndBorder && rows.length - 1 === key}
                              color={isIdMatched ? rowColor["color"] : "black"}
                              align={
                                cell.column.align ? cell.column.align : "left"
                              }
                              {...cell.getCellProps()}
                              onclick={() => {
                                if (rowClick && cell.column?.id != "action") {
                                  rowClick(row.cells[0], row.original?.caseId,row.original);
                                }
                              }}
                              case_id={row.original?.caseId}
                              style={{ paddingBottom: "2rem",borderBottom:"1px solid lightgrey",borderRadius:"10px" }}
                            >
                              {cell.render("Cell")}
                            </DataTableBodyCell>
                          ))}
                          <Divider />
                          <MDBox sx={{position:"absolute",left:"2rem",bottom:"0.5rem"}}><b>Claim Summary:</b> {row.original?.summary}</MDBox>
                        </>
                      </TableRow>
                    )}
                  </>
                );
              })}
          </TableBody>
        </Table>

        <MDBox
          display="inline-block"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && customPageOptions.length === 1 ? 0 : 3}
          sx={{ position: "absolute", display: "flex", bottom: "0.5rem" }}
        >
          {customPageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination item onClick={() => customPreviousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 12 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{
                      type: "number",
                      min: 1,
                      max: customPageOptions.length,
                    }}
                    value={customPageOptions[customPageIndex] + 1}
                    onChange={handleInputPagination}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination item onClick={() => customNextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                Showing {entriesStart} to {entriesEnd} of{" "}
                {customPagination ? pageInfo.count : rows.length} entries
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
        {/* {downloadable && data.length != 0 && (
          <MDBox
            display="inline-block"
            sx={{
              position: "absolute",
              display: "flex",
              bottom: "1.5rem",
              right: submit ? "12rem" : "3rem",
            }}
          >
            <MDButton
              color={"info"}
              onClick={() => {
                if(downloadable.type=="bulk"){

                    handleDownloadBulk(filters, data);

                }else if(downloadable.type=="mail"){
                  handleDownloadBulk(filters, data, true);
                }else{
                  exportToExcel(
                    [{ sheetName: "Details", details: data }],
                    downloadFileName ? downloadFileName : "DataFile",
                    true
                  );
                }

              }}
            >
              <MDTypography sx={{ color: "white !important" }}>
                Download
              </MDTypography>
            </MDButton>
          </MDBox>
        )} */}

        {downloadableBulk && data.length != 0 && (
          <ButtonGroup
            handleDownloadBulk={() => {
              handleDownloadBulk(filters, data);
            }}
            handleDownloadBulkEmail={() => {
              handleDownloadBulk(filters, data, true);
            }}
          />
        )}
        {submit && (
          <MDBox
            display="inline-block"
            sx={{
              position: "absolute",
              display: "flex",
              bottom: "1.5rem",
              right: "2rem",
            }}
          >
            <MDButton
              color={"primary"}
              onClick={submit.handleClick}
              disabled={submit.disabled}
            >
              {submit.value}
            </MDButton>
          </MDBox>
        )}
      </TableContainer>
    </MDBox>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: {
    defaultValue: 50,
    entries: [5, 10, 15, 20, 25, 50, 100, 500],
  },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  downloadable: true,
  customPagination: false,
  ignoreFilterList: [],
  pageInfo: { count: 0, pages: 0 },
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
