import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  Input,
} from "@material-ui/core";
import { KeyValue } from "react-key-value";
import { Grid } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import CategoryDialog from "./CategoryDialog";
import { useGlobalState, setGlobalState } from "Global";
import { PreValidations } from "./Validations";
import { toFloatMark } from "utils/conversion";
import { Card, Typography } from "@mui/material";

export default function KeyValueConst({
  name,
  label,
  value,
  onChange,
  keyscopes,
  disabled,
  stepDetails,
  amount,
  preValidationList = [],
  ...props
}) {
  const [categories, setCategories] = useState({});
  const [error, setError] = useState(null);

  const handleValueChange = (event) => {
    let value = event.target.value;
    if (value) {
      let newVal = toFloatMark(value);

      if (value.slice(-1) == ".") {
        value = newVal + ".";
      } else if (value.slice(-2) == ".0") {
        value = newVal + ".0";
      } else {
        value = String(newVal);
      }
    }
    if (value != "") {
      setCategories({
        ...categories,
        [event.target.name]: value,
      });
    } else {
      setCategories({
        ...categories,
        [event.target.name]: 0,
      });
    }
  };

  const preUpdateValidations = () => {
    preValidationList.forEach((validation) => {
      let dynamicValidation = PreValidations[validation.name];
      let result = true;
      result = dynamicValidation(
        stepDetails[validation.parameters["feature_name1"]].value,
        categories
      );

      if (result == true) result = 0;
      setError(result.toFixed(2));
    });
  };

  useEffect(() => {
    onChange({ target: { name: name, value: categories } });

    preUpdateValidations();
  }, [categories]);

  const initializeCategories = () => {
    let tempCategories = {};
    let tempAmount = parseFloat("0");
    if (value)
      Object.keys(value).forEach((category) => {
        tempAmount += parseFloat(value[category]);
        tempCategories[category] = value[category];
      });
    setCategories({
      ...tempCategories,
    });
  };

  useEffect(() => {
    initializeCategories();
  }, []);

  return (
    <FormControl
       {...(props.componentSize == "small"?{fullWidth:true}:{})}
    >
      <Card
        sx={{
          border: "1px solid lightgrey !important",
          padding: "0.5rem",
          width: props.componentSize == "small" ? "100%" : "100%",
        }}
        {...props}
      >
        <MDBox
          sx={{
            display: "flex",
            width: "100%",

            justifyContent: "space-between",
          }}
        >
          {label && (
            <Typography
              variant="headline"
              sx={{ fontSize: "1rem !important", fontWeight: "400" }}
              className={"font-unset"}
            >
              {" "}
              {name}{" "}
            </Typography>
          )}
        </MDBox>
        <MDBox
          {...(props.componentSize == "small"
            ? {}
            : {
                sx: {
                  padding: "1rem",
                  marginTop: "1rem",
                  paddingTop: "0",
                },
              })}
        >
          {categories.length != 0 &&
            Object.keys(categories).map((category, index) => {
              return (
                <MDBox sx={{ display: "flex" }} key={index}>
                  <MDBox
                    sx={{
                      display: "flex",
                      width: "100%",
                      textAlign: "right",
                      float: "right",
                      padding: "0.5rem",
                      marginBottom: "0.5rem",
                      justifyContent: "space-between",
                      border: "0.5px solid grey",
                      borderRadius: "5px",
                    }}
                  >
                    <MDTypography
                      sx={{
                        textAlign: "left",
                        fontSize:
                          props.componentSize == "small"
                            ? "0.7rem !important"
                            : "small",
                      }}
                      className="no-fontset"
                    >
                      {category}
                    </MDTypography>
                    <MDBox sx={{ display: "flex", justifyContent: "right" }}>
                      <Input
                        value={categories[category]}
                        name={category}
                        inputProps={{
                          style: {
                            textAlign: "center",
                            padding:
                              props.componentSize == "small" ? "0" : "default",
                          },
                        }}
                        style={{
                          maxWidth: "50%",
                          textAlign: "center !important",
                          display: "block",
                        }}
                        size={props.componentSize}
                        onChange={handleValueChange}
                        disabled={disabled ? true : false}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              );
            })}
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: error == 0 ? "green" : "red",
              width: "100%",
              padding: "0 8px",
            }}
          >
            {error && (
              <>
                <MDTypography sx={{ color: error == 0 ? "green" : "red" }}>
                  Balance Amount:{" "}
                </MDTypography>
                <MDTypography
                  sx={{
                    maxWidth: "50%",
                    width: "74px",
                    color: error == 0 ? "green" : "red",
                    textAlign: "center",
                  }}
                >
                  {error}
                </MDTypography>
              </>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </FormControl>
  );
}
