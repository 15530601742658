//  React helper functions
import pxToRem from 'assets/theme-dark/functions/pxToRem'

//  React base styles
import colors from 'assets/theme-dark/base/colors'
import boxShadows from 'assets/theme-dark/base/boxShadows'
import borders from 'assets/theme-dark/base/borders'

const { transparent } = colors
const { md } = boxShadows
const { borderRadius } = borders

const popover = {
  styleOverrides: {
    paper: {
      backgroundColor: transparent.main,
      boxShadow: md,
      padding: pxToRem(8),
      borderRadius: borderRadius.md
    }
  }
}

export default popover
