import { setGlobalState } from "Global";
import { userAPI } from "api";
import NotificationsList from "examples/Lists/NotificationsList";
import ProfilesList from "examples/Lists/ProfilesList";
import profilesListData from "layouts/profile2/data/profilesListData";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyResult from "utils/emptyResult";

function NotificationList({notifications,isLoading}) {



    return ( <NotificationsList title="Notifications" isLoading={isLoading} notifications={notifications} shadow={true} />);
}

export default NotificationList;