import {
  Autocomplete,
  Box,
  Card,
  Chip,
  FormControl,
  Grid,
  Icon,
  Paper,
  TextField,
  Tooltip
} from "@mui/material";
import { analyticsAPI } from "api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";
import { AppBar, Tab, Tabs } from "@mui/material";
import { setGlobalState, useGlobalState } from "Global";
import "css/analytics/basicAnalytics.css";
import BasicAnalyticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard/basicAnalytics";
import LoadingOverlay from "react-loading-overlay";
import Plot from "react-plotly.js";
import { useNavigate } from "react-router-dom";

const ignoredSummaries = ["CaseState", "ClaimType", "ClaimStatus"];

const useStyles = makeStyles({
  popper: { minWidth: "max-content !important" },
});

export const summaryMappings = {
  AgeBucket: {},
  Workflow: {},
  Customer: {},
  Retailer: {},
  ReasonCodes: {},
  // SKU: {},
  Category: {},
  CaseState: {},
  ClaimStatus: {},
  ClaimType: {},
};

export const summaryFilterMapping = {
  AgeBucket: [],
  Workflow: [],
  Customer: [],
  ReasonCodes: [],
  SKU: [],
  Category: [],
  CaseState: [],
  Retailer: [],
  ClaimType: [],
  ClaimStatus: [],
};

export const chartToFilterMapping = {
  AgeBucket: "age_bucket",
  Workflow: "workflow_id",
  Customer: "customer_name",
  ReasonCodes: "reason_codes",
  SKU: "sku",
  Category: "category",
  CaseState: "state",
  Retailer: "retailer_name",
  ClaimType: "claim_type",
  ClaimStatus: "claim_status",
};

function BasicAnalytics() {
  const [SWC, setSWC] = useState({resolved_only: false });
  const [summary, setSummary] = useState({});
  const [summaryFilters, setSummaryFilters] = useState(summaryMappings);
  const [summaryFilterOptions, setSummaryFilterOptions] = useState({});
  const [summaryLoader, setSummaryLoaders] = useState({
    AgeBucket: false,
    Workflow: false,
    Customer: false,
    ReasonCodes: false,
    SKU: false,
    Retailer: false,
    Category: false,
  });
  const [summaryToggles, setSummaryToggles] = useState({});

  const [storedSummary] = useGlobalState("basicAnalytics");

  const navigation = useNavigate();

  const [basicFilter, setBasicFilter] = useState({
    AgeBucket: [],
    Workflow: [],
    Customer: [],
    ReasonCodes: [],
    Category: [],
    Retailer: [],
    ClaimType: [],
    ClaimStatus: [],
    
  });

  const classes = useStyles();

  // Get Summary for all pie charts
  const getSummary = async (
    summaryName,
    bulk = false,
    updatedFilters = null
  ) => {
    const dynamicFunction = analyticsAPI[`getSummary${summaryName}`];
    if (!bulk) {
      setSummaryLoaders((prevSummaryLoader) => ({
        ...prevSummaryLoader,
        [summaryName]: true,
      }));
    }
    let test = await dynamicFunction(
      convertToParams(
        updatedFilters
          ? updatedFilters[summaryName]
          : summaryFilters[summaryName]
      ),
      convertToParams(
        updatedFilters
          ? updatedFilters[summaryName]
          : summaryFilters[summaryName]
      )
    )
      .then(async (res) => {
        if (res.status == 200) {
          const data = res.data;
          const layout = {
            title: data.Count.name,
          };
          setSummary((prevSummary) => ({
            ...prevSummary,
            [summaryName]: { ...data, layout: layout },
          }));
          if (Object.keys(storedSummary).length == 0) {
            setGlobalState("basicAnalytics", (prevSummary) => ({
              ...prevSummary,
              summaries: {
                ...prevSummary["summaries"],
                [summaryName]: { ...data, layout: layout },
              },
            }));
          }
          if (!bulk) {
            setSummaryLoaders((prevSummaryLoader) => ({
              ...prevSummaryLoader,
              [summaryName]: false,
            }));
          }
        }
      })
      .catch((error) => {
        if (!bulk) {
          setSummaryLoaders((prevSummaryLoader) => ({
            ...prevSummaryLoader,
            [summaryName]: false,
          }));
        }
      });
  };

  const handleBasicFilterChange = (event) => {
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value._id ? value._id : "";
      }
    }
    let tempBasicFilter = {
      ...basicFilter,
      [event.target.name]: event.target.value,
    };
    if (event.target.value.includes("All")) {
      tempBasicFilter[event.target.name] = [];
    }
    setBasicFilter(tempBasicFilter);

    const summaryNames = Object.keys(summaryMappings);
    setSummaryFilters((prevFilters) => {
      let updatedFilters = { ...prevFilters };

      summaryNames.forEach((summaryName) => {
        updatedFilters[summaryName] = {
          ...updatedFilters[summaryName],
          [event.target.name]: event.target.value,
        };
      });
      handleBasicFilter(tempBasicFilter, updatedFilters);
      return updatedFilters;
    });
  };

  const handleBasicFilter = async (
    tempBasicFilter = null,
    updatedFilters = null
  ) => {
    Object.keys(summaryMappings).forEach(async (summaryName) => {
      if (!ignoredSummaries.includes(summaryName)) {
        getSummary(summaryName, false, updatedFilters);
      }
    });

    // const summaries = await Promise.all(summaryPromises);
    analyticsAPI
      .getCasesStatesAnalytics(
        convertToParams(tempBasicFilter ? tempBasicFilter : basicFilter),
        convertToParams(tempBasicFilter ? tempBasicFilter : basicFilter)
      )
      .then((res) => {
        if (res.status == 200) {
          setSWC({ ...res.data });
          // setCommonSummaryFilters();
          if (Object.keys(storedSummary).length == 0) {
            setGlobalState("basicAnalytics", (prevSummary) => ({
              ...prevSummary,
              basic: { ...res.data },
            }));
          }
        }
      })
      .catch((error) => {});
  };

  const handleSummaryFilters = (event, summaryName) => {
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value._id ? value._id : "";
      }
    }

    if (event.target.value.includes("All")) {
      event.target.value = [];
    }
    setSummaryFilters({
      ...summaryFilters,
      [summaryName]: {
        ...summaryFilters[summaryName],
        [event.target.name]: event.target.value,
      },
    });
  };

  const getSummaryFilterValues = () => {
    analyticsAPI.getSummaryFilterValues().then((res) => {
      let tempData = res.data;
      Object.keys(tempData).forEach((filter) => {
        tempData[filter].push({ name: "All", _id: "All" });
      });
      setSummaryFilterOptions(res.data);
    });
  };

  const initializeSummaryFilters = () => {
    let tempFilters = {};
    Object.keys(summaryMappings).forEach((summary) => {
      tempFilters[summary] = summaryFilterMapping;
    });
    setSummaryFilters(tempFilters);
  };

  const initializeSummaryToggles = () => {
    let tempToggles = {};
    Object.keys(summaryMappings).forEach((summary) => {
      tempToggles[summary] = "Count";
    });
    setSummaryToggles(tempToggles);
  };

  const convertToParams = (params) => {
    let tempParams = {};
    if (params) {
      Object.keys(params).forEach((param) => {
        tempParams[chartToFilterMapping[param]] = params[param];
      });
    }
    return tempParams;
  };

  const handleSetTabValue = (event, newValue, summaryName) => {
    setSummaryToggles({
      ...summaryToggles,
      [summaryName]: newValue,
    });
  };

  useEffect(() => {
    // getWWCC();
    // getWWCA();
    // getSWC();
    // getSWT();
    // getWWC();
    // getCWCS();
    initializeSummaryToggles();
    initializeSummaryFilters();
    getSummaryFilterValues();
    // handleBasicFilter();
    const fetchAllSummaries = async () => {
      if (Object.keys(storedSummary).length != 0) {
        setSummary(storedSummary["summaries"]);
        setSWC(storedSummary["basic"]);
      } else {
        handleBasicFilter();
      }
    };
    fetchAllSummaries();
  }, []);

  return (
    <>
      <MDBox py={3}>
        <Grid container spacing={6}>
          <Grid container spacing={6}>
            <Grid marginLeft={6} item xs={12}>
              <Card>
                <MDBox p={2}>
                  <Grid container spacing={3}>
                    {Object.keys(basicFilter).map((filterName, index) => {
                      if (filterName != "days")
                        return (
                          <Grid item xs={12/8} key={index}>
                            {summaryFilterOptions[filterName]?.length > 0 && (
                              <Autocomplete
                                multiple
                                value={basicFilter[filterName]}
                                onChange={(event, newValue) => {
                                  handleBasicFilterChange({
                                    target: {
                                      name: filterName,
                                      value: newValue,
                                    },
                                  });
                                }}
                                size={"small"}
                                id="multiple-limit-tags"
                                options={summaryFilterOptions[filterName]}
                                getOptionLabel={(option) => {
                                  return option?.name ? option.name : option;
                                }}
                                classes={{ popper: classes.popper }}
                                PaperComponent={(props) => (
                                  <Paper
                                    sx={{
                                      fontSize: "25px",
                                    }}
                                    {...props}
                                  />
                                )}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label={filterName}
                                      placeholder={filterName}
                                      InputProps={{
                                        ...params.InputProps,

                                        endAdornment: <></>,
                                      }}
                                    />
                                  );
                                }}
                                renderTags={(
                                  value,
                                  getTagProps,
                                  ownerState
                                ) => {
                                  let showChips = basicFilter[filterName].slice(
                                    0,
                                    2
                                  );
                                  if (ownerState.focused) {
                                    showChips = basicFilter[filterName];
                                  }
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.1,
                                        justifyContent: "left",
                                      }}
                                    >
                                      {showChips.map((value, index) => {
                                        if (
                                          index <
                                          (ownerState.focused
                                            ? basicFilter[filterName].length
                                            : 1)
                                        ) {
                                          return (
                                            <Tooltip
                                              title={value}
                                              placement="bottom-start"
                                            >
                                              <Chip
                                                key={value}
                                                label={value}
                                                sx={{
                                                  fontSize: "x-small",
                                                  height: "fit-content",
                                                }}
                                              />
                                            </Tooltip>
                                          );
                                        } else {
                                          return (
                                            <Chip
                                              key={"+1"}
                                              label={`+${
                                                basicFilter[filterName].length -
                                                1
                                              }`}
                                              sx={{
                                                fontSize: "x-small",
                                                height: "fit-content",
                                              }}
                                            />
                                          );
                                        }
                                      })}
                                    </Box>
                                  );
                                }}
                                sx={{ width: "100%" }}
                              />
                            )}
                          </Grid>
                        );
                    })}
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          {/* SWC cards */}
          {SWC && (
            <Grid
              container
              spacing={3}
              xs={12}
              md={12}
              mt={2}
              ml={3}
              sx={{ display: "flex", justifyContent: "space-evenly",marginBottom: "0"}}
            >
              {SWC["claim_amount"] &&
                Object.keys(SWC["claim_amount"]).map((item, index) => {
                  return (
                    <Grid item xs={12} md={6} lg={3} key={index}>
                      <MDBox mb={1.5}>
                        <BasicAnalyticsCard
                          color="dark"
                          icon="equalizer"
                          count_title1={"Count"}
                          count_title2={"Amount"}
                          count1={SWC["claim_count"][item]}
                          count2={SWC["claim_amount"][item]+ " ZAR"}
                          name={item}
                        />
                      </MDBox>
                    </Grid>
                  );
                })}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{ alignItems: "center", justifyContent: "center",marginTop:"0 !important",paddingTop:"0 !important" }}
          >
            {/* Summary of Claims card */}
            {Object.keys(summaryMappings).map((summaryChart) => {
              if (!ignoredSummaries.includes(summaryChart))
                return (
                  <>
                    <Card sx={{ marginTop: "2rem" }}>
                      <MDBox p={3}>
                        <Grid container spacing={3}>
                          <Grid
                            justifyContent={"center"}
                            xs={8}
                            md={8}
                            display="flex"
                          >
                            <LoadingOverlay
                              active={summaryLoader[summaryChart]}
                              spinner
                              text="Loading your content..."
                            >
                              <MDBox
                                sx={{
                                  alignItems: "center",
                                  height: "auto",
                                  paddingTop: "1rem",
                                  maxWidth: "100%",
                                  marginBottom: "1rem",
                                  minHeight: "35vh",
                                }}
                              >
                                <AppBar position="static">
                                  <Tabs
                                    orientation={"horizontal"}
                                    value={summaryToggles[summaryChart]}
                                    onChange={(event, value) =>
                                      handleSetTabValue(
                                        event,
                                        value,
                                        summaryChart
                                      )
                                    }
                                  >
                                    <Tab label="Count" value="Count" />
                                    <Tab label="Amount" value="Amount" />
                                  </Tabs>
                                </AppBar>
                                {summary[summaryChart] && (
                                  <>
                                    <Plot
                                      data={[
                                        summary[summaryChart][
                                          summaryToggles[summaryChart]
                                        ],
                                      ]}
                                      layout={{
                                        title:
                                          summary[summaryChart][
                                            summaryToggles[summaryChart]
                                          ]["name"],
                                      }}
                                      useResizeHandler={true}
                                      config={{ displayModeBar: "hover" }}
                                    />
                                    <MDBox
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        fontSize: "small",
                                      }}
                                    >
                                      {summary[summaryChart].Extra?.map(
                                        (extraValue) => {
                                          return <MDBox>{extraValue}</MDBox>;
                                        }
                                      )}
                                    </MDBox>
                                  </>
                                )}
                              </MDBox>
                            </LoadingOverlay>
                          </Grid>
                          <Grid item xs={4} md={4} mt={3} container>
                            {Object.keys(summaryFilters).map((filterName) => {
                              if (filterName != summaryChart) {
                                return (
                                  <>
                                    <Grid item xs={6}>
                                      <FormControl
                                        size={"large"}
                                        sx={{
                                          width: "90%",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        {summaryFilterOptions[filterName]
                                          ?.length > 0 && (
                                          <Autocomplete
                                            multiple
                                            value={
                                              summaryFilters[summaryChart][
                                                filterName
                                              ]
                                            }
                                            onChange={(event, newValue) => {
                                              handleSummaryFilters(
                                                {
                                                  target: {
                                                    name: filterName,
                                                    value: newValue,
                                                  },
                                                },
                                                summaryChart
                                              );
                                            }}
                                            size={"small"}
                                            id="multiple-limit-tags"
                                            options={
                                              summaryFilterOptions[filterName]
                                            }
                                            getOptionLabel={(option) => {
                                              return option?.name
                                                ? option.name
                                                : option;
                                            }}
                                            classes={{ popper: classes.popper }}
                                            PaperComponent={(props) => (
                                              <Paper
                                                sx={{
                                                  fontSize: "25px",
                                                }}
                                                {...props}
                                              />
                                            )}
                                            renderInput={(params) => {
                                              return (
                                                <TextField
                                                  {...params}
                                                  label={filterName}
                                                  placeholder={filterName}
                                                  InputProps={{
                                                    ...params.InputProps,

                                                    endAdornment: <></>,
                                                  }}
                                                />
                                              );
                                            }}
                                            renderTags={(
                                              value,
                                              getTagProps,
                                              ownerState
                                            ) => {
                                              let showChips = summaryFilters[
                                                summaryChart
                                              ][filterName].slice(0, 2);
                                              if (ownerState.focused) {
                                                showChips =
                                                  summaryFilters[summaryChart][
                                                    filterName
                                                  ];
                                              }
                                              return (
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    gap: 0.1,
                                                    justifyContent: "left",
                                                  }}
                                                >
                                                  {showChips.map(
                                                    (value, index) => {
                                                      if (
                                                        index <
                                                        (ownerState.focused
                                                          ? summaryFilters[
                                                              summaryChart
                                                            ][filterName].length
                                                          : 1)
                                                      ) {
                                                        return (
                                                          <Tooltip
                                                            title={value}
                                                            placement="bottom-start"
                                                          >
                                                            <Chip
                                                              key={value}
                                                              label={value}
                                                              sx={{
                                                                fontSize:
                                                                  "x-small",
                                                                height:
                                                                  "fit-content",
                                                              }}
                                                            />
                                                          </Tooltip>
                                                        );
                                                      } else {
                                                        return (
                                                          <Chip
                                                            key={"+1"}
                                                            label={`+${
                                                              summaryFilters[
                                                                summaryChart
                                                              ][filterName]
                                                                .length - 1
                                                            }`}
                                                            sx={{
                                                              fontSize:
                                                                "x-small",
                                                              height:
                                                                "fit-content",
                                                            }}
                                                          />
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </Box>
                                              );
                                            }}
                                            sx={{ width: "100%" }}
                                          />
                                        )}
                                      </FormControl>
                                    </Grid>
                                  </>
                                );
                              }
                            })}

                            <MDButton
                              sx={{ height: "2rem", width: "100%" }}
                              onClick={(e) => {
                                getSummary(summaryChart);
                              }}
                              color="warning"
                            >
                              <Icon>search</Icon>&nbsp;Filter
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                  </>
                );
            })}
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default BasicAnalytics;
