import React, { useCallback, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  Background,
  useReactFlow,
  Controls,
} from "reactflow";
import dagre from "dagre";

import DownloadButton from "./downloadImage";
import "reactflow/dist/style.css";
import { useEffect } from "react";
import RandomColor from "utils/randomColor";

const nodeWidth = 150;
const nodeHeight = 100;
const concurrentNodeGap = 200;
const colorList = ["#43a047","#2096F3","#FFB701","#d500f9","#ff3d00","#ff1744"]

const LayoutFlow = (props) => {
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const getLayoutedElements = (nodes, edges, direction = "TB") => {
    const isHorizontal = direction === "LR";
    let categories = Object.keys(props.bucketPositions);

    dagreGraph.setGraph({ rankdir: direction, align: "UL" });

    nodes.forEach((node) => {
      if (!node.id.includes("-parentNode")) {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight,style:{backgroundColor:"red"} });
      }
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    categories.forEach((node) => {
      dagreGraph.setNode(node, { width: nodeWidth, height: nodeHeight });
    });

    dagre.layout(dagreGraph, { rankdir: "TB", nodesep: 20, ranksep: 50 });

    let tempNodes = nodes;
    for (let [index, node] of nodes.entries()) {
      if (!node.id.includes("-parentNode")) {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = "top";
        node.sourcePosition = "bottom";

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
          x: props.bucketPositions[node.data.department] * 10,
          y: nodeWithPosition.y + nodeHeight,
        };

        let targetIndex = tempNodes.findIndex((n) => {
          return (
            n.position.x == node.position.x && n.position.y == node.position.y
          );
        });

        if (targetIndex >= 0) {
          let oldNodePositions = tempNodes[targetIndex].position;

          // Shift old node to left
          tempNodes[targetIndex].position = {
            x: oldNodePositions.x - concurrentNodeGap / 1.5,
            y: oldNodePositions.y,
          };

          // Shift new node to right
          node.position = {
            x: node.position.x + concurrentNodeGap / 1.5,
            y: node.position.y,
          };
          // RandomColor(,[95, 100],[30,40])
          node.style={backgroundColor:colorList[categories.indexOf(node.data.department)],fontSize:"1.5rem !important" }
          node.className="workflow-node no-fontset"
        }

        tempNodes[index] = node;
      }
    }

    nodes = tempNodes;

    // For Header Nodes
    let categoryNodes = categories.map((category,index) => {
      let categoryNode = {
        id: category,
        data: { label: category },
        className: "workflow-category-name font-unset",
        style:{backgroundColor:colorList[index] ,fontSize:"1rem !important"},
        draggable: false,
        connectable: false,
        deletable: false,
      };

      categoryNode["position"] = {
        x: props.bucketPositions[category] * 10,
        y: 0,
      };

      return categoryNode;
    });

    let graphNodes = nodes.concat(categoryNodes);

    return { nodes: graphNodes, edges };
  };

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    props.nodes,
    props.edges
  );
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const { fitView } = useReactFlow();

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  );

  useEffect(() => {
    fitView();
  }, [props.width, props.height]);

  const onLoad = (rf) => {
    setReactFlowInstance(rf);
  };

  // useEffect(() => {
  //   if (reactFlowInstance && props.nodes) {
  //     reactFlowInstance.fitView();
  //   }
  // }, [reactFlowInstance, props.nodes]);

  return (
    <div style={{ height: props.height, width: props.width }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        proOptions={{ hideAttribution: true }}
        // connectionLineType={ConnectionLineType.SmoothStep}
        fitView
        preventScrolling={false}
      >
        <DownloadButton />
        {/* <Background variant="dots" gap={16} size={3} color="#dddddd" /> */}
        <Controls position="top-right" style={{size:"2rem"}}/>
      </ReactFlow>
    </div>
  );
};

export default LayoutFlow;
