// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import RSC from "react-scrollbars-custom";
import EmptyResult from "utils/emptyResult";
import { Avatar, Tooltip } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import NotificationSkeleton from "skeletons/profile/notificationSkeleton";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InitialsAvatar from "utils/initialsAvatar";

function NotificationsList({ title, notifications, shadow, isLoading }) {
  const renderNotifications = notifications
    .slice(0, 10)
    .map(({ image, from, content, case_id, name }) => (
      <MDBox
        key={from}
        component="li"
        display="flex"
        alignItems="center"
        width="100%"
        py={1}
        mb={1}
      >
        <MDBox mr={2}>
          <Tooltip title={name}>
            {/* <Avatar src={image} alt="something here" shadow="md" /> */}
            <MDBox><InitialsAvatar name={name} /></MDBox>
          </Tooltip>
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          width={"55%"}
        >
          <MDTypography variant="button" fontWeight="medium">
            {from}
          </MDTypography>
          <MDBox
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            {content}
          </MDBox>
        </MDBox>
        <MDBox ml="auto">
          <Tooltip title={"View Case"}>
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              onClick={() => window.open("/cases/" + case_id, "_blank")}
              style={{
                cursor: "pointer",
              }}
            ></FontAwesomeIcon>
          </Tooltip>
        </MDBox>
      </MDBox>
    ));

  return (
    <Card
      sx={{
        height: "100%",
        boxShadow: !shadow && "none",
        margin: "-6px",
        minWidth: "25rem",
        minHeight: "15rem",
        width:"25rem"
      }}
    >
      <MDBox pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {notifications.length == 0 && isLoading == true && (
            <NotificationSkeleton />
          )}
          {renderNotifications}
          {notifications.length == 0 && isLoading == false && (
            <EmptyResult text={"No New Notifications Found"} />
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the NotificationsList
NotificationsList.defaultProps = {
  shadow: true,
};

// Typechecking props for the NotificationsList
NotificationsList.propTypes = {
  title: PropTypes.string.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default NotificationsList;
