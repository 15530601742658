import { Card, Icon, Tooltip } from "@mui/material";
import { setGlobalState } from "Global";
import { OCRAPI } from "api";
import MDBox from "components/MDBox";
import DisplayThumbnail from "examples/PDFViewer";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";


function FileThumbnailCard({
  file,
  deleteFile,
  handleThumbnailClick,
  stepName,
  fileFeatureName,
}) {
  const navigation = useNavigate();
  const params = useParams();
  const [verifiedFeatures, setVerifiedFeatures] = useState({});

  const downloadDocument = (link) => {
    const dom = document.createElement("a");
    const url = link;
    dom.href = url;
    dom.download = file["name"];
    dom.style.display = "none";
    document.body.appendChild(dom);
    dom.click();
    dom.parentNode.removeChild(dom);
    window.URL.revokeObjectURL(url);
  };

  const getFileFeatures = (fileName, fileFeatureName, stepName) => {
    if (Object.keys(verifiedFeatures).length != 0) {
      setVerifiedFeatures({});
    } else {
      OCRAPI.getFileFeatures({
        case_id: params.case_id,
        file_name: fileName,
        file_feature_name: fileFeatureName,
        step_name: stepName,
      })
        .then((res) => {
          if (res.status == 200) {
            setVerifiedFeatures(res.data);
            if (Object.keys(res.data).length == 0) {
              setGlobalState("error", {
                open: true,
                message: "No Features found for the file",
                type: "warning",
              });
            }
          }
        })
        .catch((error) => {

        });
    }
  };

  return (
    <MDBox>
      <Tooltip title={file["name"]}>
        <Card
          sx={{
            height: "8rem",
            width: "8rem",
            padding: "1rem",
            margin: "1rem",
            marginBottom: "0.5rem",
            cursor: "pointer",
          }}
          onClick={handleThumbnailClick}
        >
          <DisplayThumbnail fileUrl={file["download_link"]} />
          <MDBox className={"truncate"}>{file["name"]}</MDBox>
        </Card>
      </Tooltip>
      {/* Icon Box */}
      <MDBox
        sx={{
          marginLeft: "1rem",
          marginRight: "1rem",
          display: "flex",
          justifyContent: "space-evenly",
        }}
        className="font-unset"
      >
        <Tooltip title="View Extracted Features">
          <Icon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              getFileFeatures(file["name"], fileFeatureName, stepName);
            }}
          >
            document_scanner
          </Icon>
        </Tooltip>
        <Tooltip title="Download above file">
          <Icon
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              downloadDocument(file["download_link"]);
            }}
          >
            download
          </Icon>
        </Tooltip>
        <Tooltip title="Delete above file">
          <Icon
            fontSize="small"
            sx={{ color: "red !important", cursor: "pointer" }}
            onClick={() => {
              deleteFile(file["name"], fileFeatureName, stepName);
            }}
          >
            delete
          </Icon>
        </Tooltip>
      </MDBox>

      {/* OCR Features */}
      <MDBox
        sx={{
          marginLeft: "1.2rem",
          marginTop: "0.5rem",
        }}
        className="font-unset"
      >
        {Object.keys(verifiedFeatures).map((feature,index) => {
          return (
            <MDBox
              sx={{
                fontSize: "small !important",
                display: "flex",
                alignItems: "center",
              }}
              key={index}
            >
              {feature}
              {verifiedFeatures && (
                <Icon
                  sx={{
                    color: "green",
                    fontSize: "small !important",
                    marginTop: "0.1rem",
                  }}
                >
                  check_circle
                </Icon>
              )}
              {!verifiedFeatures && (
                <Icon sx={{ color: "red", fontSize: "small !important" }}>
                  cancel
                </Icon>
              )}
            </MDBox>
          );
        })}
      </MDBox>


    </MDBox>
  );
}

export default FileThumbnailCard;
