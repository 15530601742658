import { useState, useEffect } from 'react'

// react-router components
import { Link } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import Container from '@mui/material/Container'
import Icon from '@mui/material/Icon'

//  React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

//  React example components
import DefaultNavbarLink from 'examples/Navbars/DefaultNavbar/DefaultNavbarLink'
import DefaultNavbarMobile from 'examples/Navbars/DefaultNavbar/DefaultNavbarMobile'
import logo from "assets/images/ef-logo.png";
import pgLogo from "assets/images/pg-logo.png";

//  React base styles
import breakpoints from 'assets/theme/base/breakpoints'

//  React context
import { useMaterialUIController } from 'context'

function DefaultNavbar ({ transparent, light, action }) {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  const [mobileNavbar, setMobileNavbar] = useState(false)
  const [mobileView, setMobileView] = useState(false)

  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode)
  const closeMobileNavbar = () => setMobileNavbar(false);

  // TODO: This should be dynamic
  const companyLogo = pgLogo;
  const companyName = "Proctor & Gamble";

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    window.addEventListener('resize', displayMobileNavbar);
    displayMobileNavbar();

    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, []);

  return (
    <Container>
      <MDBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={3}
        mx={3}
        width="calc(100% - 48px)"
        borderRadius="lg"
        shadow={transparent ? 'none' : 'md'}
        color={light ? 'white' : 'dark'}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left={0}
        zIndex={3}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba }
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.sidenav : white.main, 0.8),
          backdropFilter: transparent ? 'none' : 'saturate(200%) blur(30px)'
        })}
      >
        <MDBox
          component={Link}
          to="/"
          py={transparent ? 1.5 : 0.75}
          lineHeight={1}
          pl={{ xs: 0, lg: 1 }}
          sx={{display:"flex"}}
        >
                    
            <MDBox component="img" src={logo} alt="Brand" width="3rem" />
         
          <MDBox sx={{display:"flex",alignItems:"center"}}>
            NeatProcess
          </MDBox>
        </MDBox>
        <MDBox
          py={transparent ? 1.5 : 0.75}
          lineHeight={1}
          pl={{ xs: 0, lg: 1 }}
          sx={{ display: "flex" }}
        >
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "large !important",
            }}
            className={"font-unset"}
          >
            Claim Desk
          </MDBox>
        </MDBox>
        {/* Generalise the logo and Proctor and Gamble name */}
        {dynamicCompanyDetails(transparent,companyLogo,companyName)}
      </MDBox>
      {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />}
    </Container>
  )
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false
}

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light'
      ]),
      label: PropTypes.string.isRequired
    })
  ])
}

export default DefaultNavbar
function dynamicCompanyDetails(transparent,logo,companyName) {
  return <MDBox
    component={Link}
    to="/"
    py={transparent ? 1.5 : 0.75}
    lineHeight={1}
    pl={{ xs: 0, lg: 1 }}
    sx={{ display: "flex" }}
  >

    <MDBox component="img" src={logo} alt="Brand" width="2rem" />
    &nbsp;
    <MDBox sx={{ display: "flex", alignItems: "center" }}>
      {companyName}
    </MDBox>
  </MDBox>
}

