// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import Checkbox from "@mui/material/Checkbox";
import { setGlobalState } from "Global";

// Images
import { bulkStatAPI } from "api";
import { useEffect, useState, useCallback } from "react";

export default function inputData({ setIsLoadingNew }) {
  const [insertAPI, setInsertAPI] = useState([]);
  const [insertRows, setInsertRows] = useState([]);
  const [inputSelects, setInputSelects] = useState([]);
  const [changeValue, setInputValue] = useState(true);
  const [insertColumns, setInsertColumns] = useState([]);

  const navigation = useNavigate();

  const fetchInsertAPI = useCallback(async () => {
    setIsLoadingNew(true);
    try {
      const res = await bulkStatAPI.getNewInsert();
      const updatedData = res.data.map((item) => ({
        ...item,
        // checked: false,
      }));
      setInsertAPI(updatedData);
      setIsLoadingNew(false);
    } catch (err) {
      setIsLoadingNew(false);
      if ([401].includes(err.response?.status)) {
        
        setGlobalState("error", {
          open: true,
          message: "Login Expired!",
          type: "error",
        });
      }
    }
  }, []);

  const changeInputValues = useCallback(async () => {
    setInputValue((prev) => !prev);
    setInputSelects([])
  }, []);

  useEffect(() => {
    fetchInsertAPI();
    
  }, [changeValue]);

  useEffect(() => {
    const initColumns =
      insertAPI && insertAPI.length > 0 ? Object.keys(insertAPI[0]) : [];
    // initColumns.push("select");
    // const columns = initColumns.filter((column) => column !== "checked");
    setInsertColumns(initColumns);
  }, [insertAPI]);

  const getNewInsert = useCallback(async () => {
    const tempRows = [];
    insertAPI
      ? insertAPI.map((caseDetail, key) => {
          const rows = {};

          for (const column of insertColumns) {
            rows[column] = `${caseDetail[column]}`;
          }
          // rows.select = <Checkbox checked={caseDetail.checked} />;
          tempRows.push(rows);
        })
      : null;
    setInsertRows(tempRows);
  }, [insertAPI, insertColumns]);

  useEffect(() => {
    getNewInsert();
  }, [getNewInsert]);

  return [
    insertRows,
    insertAPI,
    setInsertAPI,
    setInputSelects,
    inputSelects,
    changeInputValues,
    insertColumns,
  ];
}
