/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import { AvatarGenerator } from "random-avatar-generator";
import MDAvatar from "components/MDAvatar";
import IsLoadingHOC from "utils/LoadingHOC";

// Images
import { agentAPI } from "api";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";
import { workflowAPI } from "api";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import { Input } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowCircleRightRounded } from "@mui/icons-material";
import { faArrowRight, faPen } from "@fortawesome/free-solid-svg-icons";

const CustomerData = (props) => {
  const [rows, setRows] = useState([]);
  const location = useLocation();
  const navigation = useNavigate();
  //   const [userData,setUserData]=useState({})

  const convertKeyToName = (key) => {
    let name = key.split("_").join(" ");

    return name;
  };

  const handleDataChange = (customerCode, key, value) => {
    props.setIsLoading(true);
    workflowAPI
      .updateCustomerCodeMaps({
        customer_code: customerCode,
        [key]: value,
      })
      .then((res) => {
        if (res.status == 200) {
          props.setIsLoading(false);
          loadCustomerCodeTable();
        }
      })
      .catch((error) => {
        props.setIsLoading(false);
      });
  };

  const addAgentSelects = (data) => {
    let tempData = [];
    // let tempUserData = {}
    data.map((row) => {
      let tempRow = {};
      Object.keys(row).map((key) => {
        if (key.includes("_user")) {
          tempRow[key] = (
            <>
              <FormControl fullWidth sx={{ minWidth: "10rem" }}>
                {row[key] == null && (
                  <InputLabel
                    sx={{
                      "&.MuiInputLabel-root": {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                      },
                      "&.MuiInputLabel-shrink": {
                        position: "absolute",
                        top: "-50%",
                        left: "8%",
                        transform: "inherit",
                        fontSize: "small",
                      },
                    }}
                    id="email-to-label"
                  >
                    Not assigned
                  </InputLabel>
                )}
                <Select
                  id="assigned_agent_email"
                  labelId="email-to-label"
                  label={row[key] == null ? "Not Assigned" : ""}
                  value={row[key] ? row[key] : null}
                  name="assigned_agent_email"
                  size="small"
                  onChange={(e) =>
                    handleDataChange(row["customer_code"], key, e.target.value)
                  }
                  sx={{
                    marginLeft: "2",
                    width: "100%",
                    minHeight: "1.5rem",
                  }}
                >
                  <MenuItem sx={{ width: "100%" }} value={null} key={0}>
                    None
                  </MenuItem>
                  {props.agents.map((agent) => {
                    return (
                      <MenuItem
                        sx={{ width: "100%" }}
                        value={agent.email_id}
                        key={agent.email_id}
                      >
                        {agent.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          );
        } else if (key.includes("workflow_name")) {
          tempRow[key] = (
            <>
              <FormControl fullWidth sx={{ minWidth: "10rem" }}>
                <Select
                  id="workflow_name"
                  labelId="email-to-label"
                  value={row[key]}
                  name="workflow_name"
                  size="small"
                  onChange={(e) =>
                    handleDataChange(row["customer_code"], key, e.target.value)
                  }
                  sx={{
                    marginLeft: "2",
                    width: "100%",
                    minHeight: "1.5rem",
                  }}
                >
                  {props.workflows.map((workflow) => {
                    return (
                      <MenuItem
                        sx={{ width: "100%" }}
                        value={workflow.name}
                        key={workflow.name}
                      >
                        {workflow.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          );
        } else if (key.includes("_name")) {
          tempRow[key] = (
            <>
              <FormControl
                fullWidth
                sx={{
                  minWidth: "10rem",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TextField
                  name={key}
                  value={row[key]}
                  variant={"outlined"}
                  sx={{ fontSize: "small !important" }}
                  size="small"
                />

                <MDBox
                  sx={{
                    borderRadius: "50%",
                    width: "1.5rem",
                    height: "1.5rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    marginLeft: "5px",
                  }}
                  onClick={(e) =>
                    props.handleNameChange(row["customer_code"], key, row[key])
                  }
                >
                  <Tooltip title="Edit">
                    <FontAwesomeIcon
                      color={"#398FED"}
                      icon={faPen}
                    ></FontAwesomeIcon>
                  </Tooltip>
                </MDBox>
              </FormControl>
            </>
          );
        } else {
          tempRow[key] = row[key];
        }
      });
      tempData.push(tempRow);
    });

    return tempData;
  };

  useEffect(() => {
    if (props.agents.length != 0 && props.workflows.length != 0) {
      loadCustomerCodeTable();
    }
  }, [location.pathname, props.agents, props.workflows]);

  const loadCustomerCodeTable = () => {
    props.setIsLoading(true);
    workflowAPI
      .getCustomerCodeMaps()
      .then((res) => {
        setRows(addAgentSelects(res.data));
        props.setIsLoading(false);
      })
      .catch((error) => {
        props.setIsLoading(false);
      });
  };

  return {
    columns: Object.keys(rows[0] ? rows[0] : []).map((key) => {
      return { Header: convertKeyToName(key), accessor: key, align: "center" };
    }),

    rows: rows,
    callbackFunction: loadCustomerCodeTable,
  };
};

export default CustomerData;
