// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  AppBar,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { TabContext, TabPanel } from "@mui/lab";

// @mui material components

// Images

// Data
import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";

//  React components

import MDButton from "components/MDButton";

// Billing page components
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import { caseAPI } from "api";
import { setGlobalState } from "Global";
import LoadingOverlay from "react-loading-overlay";
import CustomInput from "components/controls/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import MaxHeightTextarea from "utils/maxHeightTextArea";
import CustomAutoComplete from "components/controls/AutoComplete";
import { AdminAPI } from "api";
import TableDisplay from "layouts/tableDisplay/tableDisplay";
import UserDetailsForm from "./dialogs/updateUserDetails";

const ignoreColumns = ["id"];

function UserManagement() {
  const navigation = useNavigate();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});
  const [showUserDetailsForm, setShowUserDetailsForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [userEmailList, setUserEmailList] = useState([]);
  const [formType, setFormType] = useState("Update");

  const handleRowClick = (id, caseId, row) => {
    setShowUserDetailsForm(true);
    setSelectedUser(row);
    setFormType("Update");
  };

  const getUsers = () => {
    setIsLoading(true);
    AdminAPI.getAdminUserData()
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setUserEmailList(res.data.map((user) => user.email_id));
          setUserList(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console;
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ marginBottom: "1rem" }}>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                pb={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h6"
                  color="white"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white !important",
                      fontSize: "medium !important",
                    }}
                    className={"font-unset"}
                  >
                    Users Access
                  </MDBox>
                  <MDTypography
                    variant="button"
                    color="white"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white !important",
                      fontSize: "medium !important",
                    }}
                    className={"font-unset"}
                  >
                    <Tooltip title="Add New User" arrow>
                      <MDButton
                        color="white"
                        variant="contained"
                        onClick={() => {
                          setShowUserDetailsForm(true);
                          setSelectedUser({});
                          setFormType("Create");
                        }}
                      >
                        <Icon>add</Icon>
                        &nbsp; Add User
                      </MDButton>
                    </Tooltip>
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox pt={-1}>
                <TableDisplay
                  data={userList}
                  isLoading={isLoading}
                  downloadable={true}
                  canSearch={true}
                  ignoreColumns={ignoreColumns}
                  rowClick={handleRowClick}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {showUserDetailsForm && (
        <UserDetailsForm
          open={showUserDetailsForm}
          handleClose={setShowUserDetailsForm}
          getUsers={getUsers}
          userDetails={selectedUser}
          userList={userEmailList}
          type={formType}
        />
      )}
    </DashboardLayout>
  );
}

export default UserManagement;
