// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import { ArrowRightRounded, Send } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, TextField, Select, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect } from "react";

import { Typography, Paper, InputLabel, Box, Fab, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { caseAPI } from "api";
import { setGlobalState } from "Global";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useGlobalState } from "Global";

function AgentChange({ open, handleClose, caseDetails }) {
  const [state, setState] = useState(caseDetails);
  const [agents, setAgents] = useState([]);
  const navigation = useNavigate();
  const [userDetails] = useGlobalState("userDetails");


  const errorStatus = "OOO";

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    caseAPI
      .changeAgent(state.case_id, state.emailTo, state.message)
      .then((res) => {
        handleClose(!open);
        if (res.status == 200) {
          if (!["admin", "superuser"].includes(userDetails.permission)) {
            navigation(`/${userDetails.id}/cases`);
          } else {
            window.location.reload(false);
          }
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAgents(res.data);
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(!open);
      }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", minWidth: "30rem" }}
      >
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 2 }}>
            Change Agent
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="mailer-label">Agent Name</InputLabel>
                <Select
                  id="emailTo"
                  labelId="mailer-label"
                  value={state.agent_email_id}
                  name="emailTo"
                  label={"Agent Name"}
                  onChange={handleChange}
                  sx={{ marginLeft: "2", width: "100%", minHeight: "3.5rem" }}
                >
                  <MenuItem
                    className="testclass"
                    sx={{ width: "100%", minHeight: "3.5rem" }}
                    value={""}
                    key={""}
                    disabled
                  >
                    <Grid sx={{}} container>
                      <Grid item xs={4} textAlign={"center"}>
                        Agent Name
                      </Grid>
                      <Grid item xs={4} textAlign={"center"}>
                        Agent Status
                      </Grid>
                      <Grid item xs={4} textAlign={"center"}>
                        Secondary Contact
                      </Grid>
                    </Grid>
                  </MenuItem>
                  {agents.map((agent) => {
                    return (
                      <MenuItem
                        sx={{ width: "100%", minHeight: "3.5rem" }}
                        value={agent.email_id}
                        key={agent.email_id}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            backgroundColor:
                              agent.active_status == errorStatus
                                ? "#FFBABA"
                                : "inherit",
                          }}
                          container
                        >
                          <Grid item xs={4} textAlign={"center"}>
                            {agent.name}
                          </Grid>
                          <Grid item xs={4} textAlign={"center"}>
                            {agent.active_status}
                          </Grid>
                          <Grid item xs={4} textAlign={"center"}>
                            {agent.secondary_contact
                              ? agent.secondary_contact
                              : "Not updated"}
                          </Grid>
                        </Grid>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="message"
                label="Message"
                onChange={handleChange}
                name="message"
                size="small"
                multiline
                fullWidth
                variant="outlined"
                rows={8}
                required
              />
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={2} md={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                p={1}
              >
                <Button
                  size="medium"
                  color="success"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Change
                </Button>
              </MDBox>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default AgentChange;
