import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  Grid,
  TextField,
  Icon,
  Card,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDProgress from "components/MDProgress";
import { analyticsAPI, caseAPI } from "api";

import { setGlobalState } from "Global";
import CaseVariant from "layouts/dashboard/casevariant";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate } from "react-router-dom";

// React Flow components
import { ReactFlowProvider } from "reactflow";
import PMWorkflow from "layouts/dashboard/processmining/pmWorkflow";

function ProcessMining() {
  const [filter, setFilter] = useState({
    resolved_only: false,
    level: 1,
    workflow_id: null,
    variants: [],
  });
  const [plot, setPlot] = useState({});
  const [PMData, setPMData] = useState({});
  const [variants, setVariants] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [variantDetails, setVariantDetails] = useState({});
  const [action, setAction] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [workflows, setWorkflows] = useState([]);
  const navigation = useNavigate();

  const handleOpenAction = (event) => {
    setAction(event.target);
  };

  const getVariants = ( level, resolved, workflow_id) => {
    analyticsAPI
      .getVariants( level, resolved, workflow_id)
      .then((res) => {
        setVariants(res.data);
      })
      .catch((error) => {});
  };

  const getWorkflows = () => {
    caseAPI
      .getWorkflows()
      .then((res) => {
        if (res.status == 200) {
          setWorkflows(res.data);
        }
      })
      .catch((error) => {});
  };

  const handlePlotFilter = () => {
    let variantCases = [];
    selectedVariants.forEach((index) => {
      variantCases = variantCases.concat(variants[index].case_ids);
    });
    setIsLoading(true);
    analyticsAPI
      .getPMData(
       
        filter.resolved_only,
        filter.case_id,
        filter.level,
        filter.workflow_id,
        variantCases
      )
      .then((res) => {
        if (res.status == 200) {
          setPMData(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleChange = (event) => {
    if (event.target.name != "case_id") {
      setFilter({ ...filter, [event.target.name]: event.target.value });
 if (event.target.name == "level") {
        getVariants(
          
          event.target.value,
          filter?.resolved_only,
          filter.workflow_id
        );
      } else if (event.target.name == "resolved_only") {
        getVariants(
         
          filter?.level,
          event.target.value,
          filter.workflow_id
        );
      } else if (event.target.name == "workflow_id") {
        getVariants(
          
          filter?.level,
          filter?.resolved_only,
          event.target.value
        );
      }
    } else {
      setFilter({ ...filter, [event.target.id]: event.target.value });
    }
  };

  const handleVariant = (index) => {
    const tempVariants = selectedVariants;

    if (!tempVariants.includes(index)) {
      tempVariants.push(index);
      setSelectedVariants([...tempVariants]);
    } else {
      const currIndex = tempVariants.indexOf(index);
      tempVariants.splice(currIndex, 1);
      setSelectedVariants([...tempVariants]);
    }
  };

  const handleVariantModal = (variant) => {
    setVariantDetails(variant);
    setShowModal(true);
  };

  const defaultStyles = {
    cursor: "pointer",
    border: "solid 0.5px black",
    padding: "10px",
    borderRadius: "10px",
    margin: "5px",
  };

  const onSelectStyles = {
    cursor: "pointer",
    border: "solid 0.5px black",
    padding: "10px",
    borderRadius: "10px",
    margin: "5px",
    backgroundColor: "lightgreen",
  };

  useEffect(() => {
    handlePlotFilter();
    getWorkflows();
    getVariants( filter?.level, filter?.resolved_only);
  }, []);

  return (
    <>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid
            item
            xs={12}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Card>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid justifyContent={"center"} xs={9} md={9} display="flex">
                    <MDBox
                      sx={{
                        width: "100% !important",

                        maxHeight: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingOverlay
                        active={isLoading}
                        spinner
                        text="Loading your content..."
                      >
                        <MDBox
                          sx={{
                            display: "flex",
                            height: "100vh",
                            maxHeight: "100%",
                            justifyContent: "center",
                          }}
                        >
                          {/* React Flow component */}
                          {!isLoading && (
                            <PMWorkflow
                              nodes={PMData.nodes}
                              edges={PMData.edges}
                            />
                          )}
                        </MDBox>
                      </LoadingOverlay>
                    </MDBox>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormControl sx={{ marginBottom: "2rem" }}>
                      <MDTypography
                        id="demo-row-radio-buttons-group-label"
                        sx={{ fontSize: "medium" }}
                      >
                        View
                      </MDTypography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="level"
                        // value={filter?.view}
                        onChange={handleChange}
                        sx={{ display: "flex", justifyContent: "center" }}
                        defaultValue={1}
                      >
                        <FormControlLabel
                          value={1}
                          name="level"
                          control={<Radio />}
                          label="Eagle"
                        />
                        <FormControlLabel
                          value={2}
                          name="level"
                          control={<Radio />}
                          label="In-Depth"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl size={"large"} sx={{ marginBottom: "2rem" }}>
                      <InputLabel id="email-to-label">WorkFlow Name</InputLabel>
                      <Select
                        id="workflow_id"
                        labelId="email-to-label"
                        value={filter.workflow_id}
                        name="workflow_id"
                        size="small"
                        label={"WorkFlow Name"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        sx={{ p: 1.5 }}
                      >
                        <MenuItem value={null} key={null}>
                          None
                        </MenuItem>
                        {workflows.map((workflow) => {
                          return (
                            <MenuItem value={workflow._id} key={workflow._id}>
                              {workflow.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl size={"large"} sx={{ marginBottom: "2rem" }}>
                      <InputLabel sx={{}} id="resolved_label">
                        Resolved
                      </InputLabel>
                      <Select
                        labelId="resolved_label"
                        value={
                          filter?.resolved_only ? filter.resolved_only : "false"
                        }
                        label="Resolved"
                        name="resolved_only"
                        onChange={handleChange}
                        sx={{ p: 1.5 }}
                      >
                        <MenuItem value={"false"} name={"resolved_only"}>
                          False
                        </MenuItem>
                        <MenuItem value={"true"} name={"resolved_only"}>
                          True
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <MDBox sx={{ marginBottom: "2rem" }}>
                      <MDTypography sx={{ fontSize: "medium" }}>
                        Variants
                      </MDTypography>
                      {variants.map((variant, index) => {
                        // console.log(selectedVariants);
                        return (
                          <>
                            <MDBox
                              onClick={(event) => {
                                handleVariant(index);
                                if (!action) {
                                  handleOpenAction(event);
                                }
                              }}
                              sx={
                                selectedVariants.includes(index)
                                  ? onSelectStyles
                                  : defaultStyles
                              }
                              key={index}
                            >
                              <MDTypography
                                sx={{
                                  fontSize: "small",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {variant?.name}
                                <MDTypography
                                  sx={{ fontSize: "large" }}
                                  onClick={() => {
                                    handleVariantModal(variant);
                                  }}
                                >
                                  <Icon>visibility</Icon>
                                </MDTypography>
                              </MDTypography>

                              <MDProgress
                                value={variant?.percentage_time_invested}
                                text={variant?.time_invested}
                                color="info"
                                variant="gradient"
                                label={true}
                              />
                            </MDBox>
                          </>
                        );
                      })}
                    </MDBox>
                    <TextField
                      id="case_id"
                      label="Case Id"
                      name={"case_id"}
                      value={filter?.case_id}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{ marginBottom: "2rem" }}
                    />{" "}
                    <MDButton onClick={handlePlotFilter} color="warning">
                      <Icon>search</Icon>&nbsp;Search
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {showModal && (
        <CaseVariant
          variantDetails={variantDetails}
          handleClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}

export default ProcessMining;
