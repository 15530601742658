import { Icon, Paper, Slide } from "@mui/material";
import MDBox from "components/MDBox";
import "./dialogWrapper.css";

export default function DialogWrapper(props) {
  return (
    <MDBox onClose={props.handleClose}>
      <Slide direction="left" in={true} timeout={500} >
        <Paper
          elevation={3}
          sx={{
            marginRight: "0%",
            marginLeft: "0%",
            width: props.width ? props.width : "35% !important",
          }}
          className="dialog-component"
        >
          <MDBox className="font-unset close-modal" onClick={props.handleClose}>
            <Icon sx={{ color: "red" }}>close</Icon>
          </MDBox>

          {props.children}
        </Paper>
      </Slide>
    </MDBox>
  );
}
