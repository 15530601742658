// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  AppBar,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { TabContext, TabPanel } from "@mui/lab";

// @mui material components

// Images

// Data
import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";

//  React components

import MDButton from "components/MDButton";

// Billing page components
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import { caseAPI } from "api";
import { setGlobalState } from "Global";
import LoadingOverlay from "react-loading-overlay";
import CustomInput from "components/controls/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import CustomerData from "./customerData";
import NameChange from "./nameChange";
import MaxHeightTextarea from "utils/maxHeightTextArea";
import CustomAutoComplete from "components/controls/AutoComplete";

function WorkflowUpdate() {
  const navigation = useNavigate();
  const [assignAgents, setAssignAgents] = useState([]);
  const [state, setState] = useState({});
  const [workflows, setWorkflows] = useState([]);
  const [workflowDetail, setWorkflowDetail] = useState({});
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingWorflowDetails, setIsLoadingWorflowDetails] = useState(false);
  const [showComponent, setShowComponent] = useState({
    autoAssign: false,
    customerAssign: false,
  });
  const [tabValue, setTabValue] = useState("1");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [showNameChange, setShowNameChange] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});

  const handleNameChange = (customer_code, key, value) => {
    setShowNameChange(true);
    setCustomerDetails({ customer_code, key, value });
  };

  const {
    columns: pColumns,
    rows: pRows,
    callbackFunction: callbackFunction,
  } = CustomerData({
    setIsLoading: setIsLoadingTable,
    agents: assignAgents,
    workflows: workflows,
    handleNameChange: handleNameChange,
  });

  const updateAutoTransfer = (stepName, agentEmail) => {
    setIsLoadingWorflowDetails(true);
    caseAPI
      .updateWorflowAutoTransfer(state.workflow, stepName, agentEmail)
      .then((res) => {
        if (res.status == 200) {
          setWorkflowDetail({
            ...workflowDetail,
            [stepName]: {
              ...workflowDetail[stepName],
              auto_transfer_email: agentEmail,
            },
          });
          setIsLoadingWorflowDetails(false);
        }
      })
      .catch((error) => {
        setIsLoadingWorflowDetails(false);
      });
  };

  const updateInitAgent = (agentEmail) => {
    setIsLoadingWorflowDetails(true);
    caseAPI
      .updateWorflowInitAgent(state.workflow, agentEmail)
      .then((res) => {
        if (res.status == 200) {
          setState({ ...state, init_agent: agentEmail });
          setIsLoadingWorflowDetails(false);
        }
      })
      .catch((error) => {
        setIsLoadingWorflowDetails(false);
      });
  };

  const getWorkflows = () => {
    caseAPI
      .getWorkflows()
      .then((res) => {
        if (res.status == 200) {
          setWorkflows(res.data);
        }
      })
      .catch((error) => {});
  };

  const getWorkflowDetails = (workflowId) => {
    setIsLoadingWorflowDetails(true);
    caseAPI
      .getWorkflowById(workflowId)
      .then((res) => {
        if (res.status == 200) {
          setWorkflowDetail(res.data.workflow);
          setState((prevState)=>{return { ...prevState,workflow:workflowId, init_agent: res.data.init_agent }});
          setIsLoadingWorflowDetails(false);
        }
      })
      .catch((error) => {
        setIsLoadingWorflowDetails(false);
      });
  };

  const getAgents = () => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAssignAgents(res.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getWorkflows();
    getAgents();
  }, []);

  const handleWorkflowSelect = (e) => {
    // let init_agent = workflows.filter((workflow) => {
    //   return workflow._id == e.target.value;
    // })[0]?.init_agent;
    // setState({ ...state, workflow: e.target.value, init_agent: init_agent });
    getWorkflowDetails(e.target.value);
  };

  const selectedStyle = {
    backgroundColor: "rgb(56,143,237)",
    color: "white !important",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ marginBottom: "1rem" }}>
              <TabContext value={tabValue}>
                <AppBar position="static">
                  <Tabs
                    orientation={"horizontal"}
                    value={tabValue}
                    onChange={handleSetTabValue}
                  >
                    <Tab
                      label="Customer Wise User Update"
                      value="1"
                      sx={tabValue == "1" ? selectedStyle : {}}
                    />
                    <Tab
                      label="Workflow Step Wise Assignee"
                      value="2"
                      sx={tabValue == "2" ? selectedStyle : {}}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value="1" index={0}>
                  {" "}
                  <>
                    <>
                      <MDBox pt={-1}>
                        <DataTable
                          table={{ columns: pColumns, rows: pRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          downloadable={false}
                          noEndBorder
                          canSearch={true}
                          isLoading={isLoadingTable}
                          // columnFilter={true}
                        />
                      </MDBox>
                    </>
                  </>
                </TabPanel>
                <TabPanel value="2" index={1}>
                  <>
                    <MDBox>
                      <Grid spacing={3} mb={3}>
                        <Grid item pt={4} xs={12} md={12} ml={3} mr={3}>
                          <Form>
                            <Grid container>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="email-to-label">
                                    WorkFlow Name
                                  </InputLabel>
                                  <Select
                                    id="workflow_id"
                                    labelId="email-to-label"
                                    value={state.workflow}
                                    name="workflow_id"
                                    size="small"
                                    label={"WorkFlow Name"}
                                    onChange={(e) => {
                                      handleWorkflowSelect(e);
                                    }}
                                    sx={{
                                      marginLeft: "2",
                                      width: "100%",
                                      minHeight: "3rem",
                                    }}
                                  >
                                    {workflows.map((workflow) => {
                                      return (
                                        <MenuItem
                                          sx={{ width: "100%" }}
                                          value={workflow._id}
                                          key={workflow._id}
                                        >
                                          {workflow.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Tooltip
                                title={
                                  state.workflow
                                    ? ""
                                    : "Agent change is disabled. Please select Workflow before changing agent."
                                }
                                placement="top"
                              >
                                <Grid item xs={6}>
                                  
                                  {<CustomAutoComplete
                          value={
                            state.init_agent
                          }
                          name={"secondary_contact"}
                          label={"Initial Assigned Agent"}
                          options={assignAgents}
                          onChange={(e) =>
                            updateInitAgent(e.target.value.email_id)
                          }
                          key={state.init_agent}
                          // size={"small"}
                        />}
                                </Grid>
                              </Tooltip>
                            </Grid>
                          </Form>
                        </Grid>
                      </Grid>
                    </MDBox>

                    {Object.keys(workflowDetail).length != 0 && (
                      <Card sx={{ margin: 5 }}>
                        <MDBox
                          mx={2}
                          mt={-3}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                        >
                          <MDTypography variant="h6" color="white">
                            {"StepWise Details"}
                          </MDTypography>
                        </MDBox>

                        <LoadingOverlay
                          active={isLoadingWorflowDetails}
                          spinner
                          text="Loading your content..."
                        >
                          <MDBox>
                            <Grid spacing={3} mb={3}>
                              <Grid item pt={4} xs={12} md={12} ml={3} mr={3}>
                                <Form>
                                  <Grid container>
                                    {Object.keys(workflowDetail).map((step) => {
                                      return (
                                        <>
                                          <Grid
                                            item
                                            xs={4}
                                            sx={{ paddingTop: "0.5rem" }}
                                          >
                                            <FormControl fullWidth>
                                              <MaxHeightTextarea
                                                value={step}
                                                label={"Step Name"}
                                                style={{
                                                  maxWidth: "100%",
                                                  textAlign: "center",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  fontSize: "small",
                                                }}
                                                disabled={true}
                                              />
                                            </FormControl>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={4}
                                            sx={{ paddingTop: "0.5rem" }}
                                          >
                                            <FormControl fullWidth>
                                              <InputLabel id="email-to-label">
                                                Rule Function
                                              </InputLabel>
                                              <Select
                                                id="assigned_agent_email"
                                                labelId="email-to-label"
                                                label={"Rule Function"}
                                                value={
                                                  workflowDetail[step]
                                                    .rule_function
                                                    ? workflowDetail[step]
                                                        .rule_function
                                                    : " Not assigned "
                                                }
                                                name={"Rule Function"}
                                                sx={{
                                                  marginLeft: "2",
                                                  width: "100%",
                                                  minHeight: "3rem",
                                                }}
                                                disabled={true}
                                              >
                                                {" "}
                                                <MenuItem
                                                  sx={{ width: "100%" }}
                                                  value={
                                                    workflowDetail[step]
                                                      .rule_function
                                                      ? workflowDetail[step]
                                                          .rule_function
                                                      : " Not assigned "
                                                  }
                                                  key={
                                                    workflowDetail[step]
                                                      .rule_function
                                                      ? workflowDetail[step]
                                                          .rule_function
                                                      : " Not assigned "
                                                  }
                                                >
                                                  {workflowDetail[step]
                                                    .rule_function
                                                    ? workflowDetail[step]
                                                        .rule_function
                                                    : " Not assigned "}
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={4}
                                            sx={{ paddingTop: "0.5rem" }}
                                          >
                                            <FormControl fullWidth>
                                              <InputLabel id="email-to-label">
                                                Assigned Agent
                                              </InputLabel>
                                              <Select
                                                id="assigned_agent_email"
                                                labelId="email-to-label"
                                                value={
                                                  workflowDetail[step]
                                                    .auto_transfer_email
                                                    ? workflowDetail[step]
                                                        .auto_transfer_email
                                                    : " Not assigned "
                                                }
                                                name="assigned_agent_email"
                                                size="small"
                                                label={"Assign Agent"}
                                                onChange={(e) =>
                                                  updateAutoTransfer(
                                                    step,
                                                    e.target.value
                                                  )
                                                }
                                                sx={{
                                                  marginLeft: "2",
                                                  width: "100%",
                                                  minHeight: "3rem",
                                                }}
                                              >
                                                <MenuItem
                                                  sx={{ width: "100%" }}
                                                  value={" Not assigned "}
                                                  key={0}
                                                >
                                                  Not assigned
                                                </MenuItem>
                                                {assignAgents.map((agent) => {
                                                  return (
                                                    <MenuItem
                                                      sx={{ width: "100%" }}
                                                      value={agent.email_id}
                                                      key={agent.email_id}
                                                    >
                                                      {agent.name}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                          {/* <Grid
                                            item
                                            xs={2}
                                            sx={{
                                              borderRadius: "lg",
                                              display: "flex",
                                              justifyContent: "end",
                                              alignItems: "center",
                                            }}
                                          >
                                            <FormControl>
                                              <MDButton
                                                size="small"
                                                color="success"
                                                variant="contained"
                                                onClick={() => {
                                                  updateAutoTransfer(
                                                    step,
                                                    workflowDetail[step]
                                                      .auto_transfer_email
                                                  );
                                                }}
                                              >
                                                Update
                                              </MDButton>
                                            </FormControl>
                                          </Grid> */}
                                        </>
                                      );
                                    })}
                                  </Grid>
                                </Form>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </LoadingOverlay>
                      </Card>
                    )}
                  </>
                </TabPanel>
              </TabContext>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {showNameChange && Object.keys(customerDetails).length != 0 && (
        <NameChange
          open={showNameChange}
          handleClose={setShowNameChange}
          customerDetails={customerDetails}
          callbackFunction={callbackFunction}
        ></NameChange>
      )}
    </DashboardLayout>
  );
}

export default WorkflowUpdate;
