import {
  faAnglesRight,
  faArrowRight,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Card, Icon, Tab, Tabs, Tooltip } from "@mui/material";
import { setGlobalState } from "Global";
import { AIAgentAPI } from "api";
import MDBox from "components/MDBox";
import { useEffect, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate, useParams } from "react-router-dom";
import RSC from "react-scrollbars-custom";
import SuggestionSkeleton from "skeletons/forms/dialogs/suggestionSkeleton";
import SummarySkeleton from "skeletons/forms/dialogs/summarySkeleton";
import EmptyResult from "utils/emptyResult";
import PerformActions from "./performActions";

import { ReactTyped } from "react-typed";
import Divider from "@mui/material/Divider";
import { position } from "stylis";
import { useGlobalState } from "Global";

function AIAgent(props) {
  const [history, setHistory] = useState([]);
  const [caseSummary, setCaseSummary] = useState([]);
  const [featureAction, setFeatureAction] = useState(null);
  const [fileAction, setFileAction] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [transferAction, setTransferAction] = useState(null);
  const [stepChange, setStepChange] = useState(null);
  const [noStepChange, setNoStepChange] = useState(null);
  const [actions, setActions] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const [requiredProperties, setRequiredProperties] = useState([]);
  const navigation = useNavigate();
  const params = useParams();
  const [tabValue, setTabValue] = useState("1");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [isLoading, setIsLoading] = useState(false);
  const [loaders, setLoaders] = useState({
    file_action: false,
    feature_action: false,
    step_change: false,
    autotransfer: false,
    no_step_change: false,
    suggestion: false,
  });
  const actionRef = useRef(null);
  const [userDetails] = useGlobalState("userDetails");



  const updateMapping = {
    suggestion: setSuggestions,
    feature_action: setFeatureAction,
    file_action: setFileAction,
    transfer_action: setTransferAction,
  };

  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } =
    AIAgentAPI.getCaseSuggestionsWS({ case_id: params.case_id, token: `Bearer ${userDetails["access_token"]}` });

  const getCaseSummary = async () => {
    setIsLoading(true);
    AIAgentAPI.getCaseSummary({ case_id: params.case_id })
      .then((res) => {
        if (res.status == 200) {
          setCaseSummary(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getSuggestions = async () => {
    setLoaders((prevLoaders) => {
      Object.keys(prevLoaders).map((loader) => {
        prevLoaders[loader] = true;
      });
      return { ...prevLoaders };
    });
    sendCustomMessage({ tasks: ["all"], type: "info" });
  };

  useEffect(() => {
    console.log(lastJsonMessage);
    if (lastJsonMessage) {
      if (lastJsonMessage["status_code"] == 200) {
        // Action Success/Failure Response
        if (lastJsonMessage["type"] && lastJsonMessage["type"] == "action") {
          props.reloadContents();
          if (lastJsonMessage["name"] == "autotransfer") {
            sendCustomMessage({ tasks: ["all"] });
          } else {
            sendCustomMessage({ tasks: [lastJsonMessage["name"]] });
          }
        }
        let response = lastJsonMessage["content"];

        if (typeof response == typeof {}) {
          if ("suggestion" in response) {
            setSuggestions(response["suggestion"]);
          } else if ("feature_action" in response) {
            setFeatureAction(response["feature_action"]);
          } else if ("file_action" in response) {
            setFileAction(response["file_action"]);
          } else if ("autotransfer" in response) {
            setTransferAction(response["autotransfer"]);
            setNoStepChange((prev) => null);
            setStepChange((prev) => null);
          } else if ("step_change" in response) {
            setStepChange(response["step_change"]);
            setNoStepChange((prev) => null);
            setTransferAction((prev) => null);
          } else if ("no_step_change" in response) {
            setNoStepChange(response["no_step_change"]);
            setTransferAction((prev) => null);
            setStepChange((prev) => null);
          }
          setLoaders((prevLoaders) => {
            return { ...prevLoaders, [Object.keys(response)[0]]: false };
          });
        }
      } else if (lastJsonMessage["status_code"] != 200) {
        if (lastJsonMessage["type"] && lastJsonMessage["type"] == "action") {
          setLoaders((prevLoaders) => {
            return { ...prevLoaders, [lastJsonMessage["name"]]: false };
          });
        }
        setGlobalState("error", {
          open: true,
          message: lastJsonMessage["content"],
          type: "error",
        });
      }
    }
  }, [lastJsonMessage]);

  const getSuggestionsTemp = () => {
    setIsLoading(true);
    AIAgentAPI.getCaseSuggestionsTemp({ case_id: params.case_id })
      .then((res) => {
        if (res.status == 200) {
          setSuggestions(res.data.suggestion["summary_of_current_situation"]);
          setActions(res.data.actions);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleShowActions = () => {
    setShowActions(true);
  };

  const sendCustomMessage = (message) => {
    if (readyState == 1) {
      sendJsonMessage(message);
    }
  };

  useEffect(() => {
    if (actionRef.current) {
      actionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [actionRef]);

  useEffect(() => {
    getSuggestions();
  }, [readyState]);

  useEffect(() => {
    if (tabValue == "1" && caseSummary.length == 0) {
      getCaseSummary();
    } else if (tabValue == "2" && suggestions.length == 0) {
      // getSuggestions();
      // getSuggestionsTemp()
    }
  }, [tabValue]);

  return (
    <TabContext value={tabValue}>
      <AppBar position="static" sx={{ margin: "0 30px 10px 0px" }}>
        <Tabs
          orientation={"horizontal"}
          value={tabValue}
          onChange={handleSetTabValue}
        >
          <Tab
            label="Case Summary"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                analytics
              </Icon>
            }
            value={"1"}
          />
          <Tab
            label="Suggestion/Actions"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                timeline
              </Icon>
            }
            value={"2"}
          />
        </Tabs>
      </AppBar>
      <LoadingOverlay
        active={false}
        spinner
        text="Loading your content..."
        styles={{
          wrapper: (base) => ({
            ...base,
            display: "flex !important",
            height: "100%! important",
          }),
          overlay: (base) => ({
            ...base,
            display: "flex !important",
            height: "90%! important",
          }),
        }}
      >
        <MDBox sx={{ height: "100%", width: "100%" }}>
          {/* Case Summary */}

          <TabPanel
            value="1"
            index={1}
            sx={{ width: "100%", height: "100%", margin: "0", padding: "0" }}
          >
            <RSC
              id="RSC-Example"
              style={{ width: "100%", height: "90%", position: "relative" }}
            >
              {isLoading == true && <SummarySkeleton />}
              {isLoading == false &&
                caseSummary.map((item, index) => {
                  return (
                    <MDBox
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "1rem",
                      }}
                    >
                      <MDBox
                        sx={{
                          fontWeight: "bold",
                          margin: "1rem 0",
                          padding: "0.5rem 0",
                          marginBottom: "0",
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {index + 1})
                      </MDBox>
                      <Card
                        sx={{
                          width: "90%",
                          padding: "0.5rem",
                          margin: "1rem",
                          marginBottom: "0",
                          // backgroundImage:
                          //   "linear-gradient(to right, #00b4db, #0083b0)",
                        }}
                      >
                        <MDBox sx={{ display: "flex" }}>
                          <MDBox
                            sx={{
                              marginLeft: "0.5rem",
                              fontWeight: "400",
                              // color: "white !important",
                            }}
                          >
                            {item}
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  );
                })}
              {isLoading == false && caseSummary.length == 0 && <EmptyResult />}
            </RSC>
          </TabPanel>

          {/* Case Suggestions and Actions */}
          <TabPanel
            value="2"
            index={1}
            sx={{ width: "100%", height: "100%", margin: "0", padding: "0" }}
          >
            <Card
              sx={{
                height: "90%",
                margin: "0 1rem",
                padding: "0 0.5rem",
                border: "0.2px solid lightgrey",
              }}
            >
              <RSC
                id="RSC-Example"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  // backgroundColor: "#F8F9FA",
                  // border: "1px solid grey",
                  borderRadius: "10px",
                }}
              >
                <MDBox
                  sx={{
                    fontWeight: "bold",

                    position: "absolute",
                    right: "1rem",
                    top: "0.5rem",
                  }}
                >
                  <Tooltip title={"Reload Suggestions/Actions"}>
                    <FontAwesomeIcon
                      icon={faRefresh}
                      fontSize={"small"}
                      onClick={() => getSuggestions()}
                      style={{ cursor: "pointer" }}
                      className="navbar-icon"
                    ></FontAwesomeIcon>
                  </Tooltip>
                </MDBox>
                {suggestions.length == 0 && (
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "3rem",
                    }}
                  >
                    <MDBox
                      sx={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        fontSize: "2rem !important",
                        padding: "2rem",
                        color: "#57adf3",
                      }}
                      className={"font-unset"}
                    >
                      Analysing your Case
                    </MDBox>
                    <lottie-player
                      // src="https://lottie.host/2b1760a2-ec51-4df4-8440-19d41e4210d0/yulTOVUmsF.json"
                      src="https://lottie.host/caa0746a-0c81-4ca8-b24f-673728bde6d6/mbhVIMLL9x.json"
                      background="transparent"
                      speed="1"
                      style={{
                        width: "90%",
                        margin: "-7rem 0 -5rem 0",
                        alignItems: "center",
                        maxWidth: "25rem",
                      }}
                      loop
                      autoplay
                      direction="1"
                      mode="normal"
                    ></lottie-player>
                  </MDBox>
                )}
                {(suggestions.length != 0 || showActions) && (
                  <>
                    {/* <Card
                    sx={{
                      width: "95%",
                      padding: "1rem 1rem 1rem 1rem",
                      margin: "0rem 0.5rem 1rem 0.5rem ",
                      position: "relative",
                      whiteSpace: "pre-wrap",
                    }}
                  > */}{" "}
                    <MDBox
                      sx={{
                        padding: "1rem 0.5rem 0.5rem 0.5rem",
                        margin: "0rem 0.5rem 0.5rem 0.5rem ",
                        whiteSpace: "pre-wrap",
                        minHeight: "25vh",
                      }}
                    >
                      <MDBox sx={{ justifyContent: "center" }}>
                        <MDBox
                          sx={{
                            // marginLeft: "0.5rem",
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                            fontSize: "1.3rem !important",
                            color: "green",
                          }}
                          className={"font-unset"}
                        >
                          AI Suggestions
                        </MDBox>
                      </MDBox>
                      {/* {suggestions.join(" <br /> ")} */}

                      {suggestions.length > 0 && (
                        <ReactTyped
                          strings={[
                            suggestions
                              .map((str, index) => `${index + 1})&nbsp;${str}`)
                              .join("\n"),
                          ]}
                          onComplete={handleShowActions}
                          typeSpeed={0}
                          style={{ fontSize: "1.5rem" }}
                        />
                      )}
                    </MDBox>
                    <Divider />
                    {/* </Card> */}
                    {
                      <PerformActions
                        // ref={actionRef}
                        featureAction={featureAction}
                        fileAction={fileAction}
                        transferAction={transferAction}
                        noStepChange={noStepChange}
                        stepChange={stepChange}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        reloadSuggestions={getSuggestions}
                        sendCustomMessage={sendCustomMessage}
                        loaders={loaders}
                        setLoaders={setLoaders}
                        updateMapping={updateMapping}
                        {...props}
                      />
                    }
                    {!showActions && false && (
                      <MDBox
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <MDBox
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                            fontSize: "1rem !important",
                            color: "#0389F6",
                          }}
                          className={"font-unset"}
                        >
                          Analysing Actions to be taken
                        </MDBox>
                        <lottie-player
                          src="https://lottie.host/2b1760a2-ec51-4df4-8440-19d41e4210d0/yulTOVUmsF.json"
                          background="transparent"
                          speed="1"
                          style={{
                            width: "60%",
                            margin: "-3rem 0",
                            alignItems: "center",
                            maxWidth: "25rem",
                          }}
                          loop
                          autoplay
                          direction="1"
                          mode="normal"
                        ></lottie-player>
                      </MDBox>
                    )}
                  </>
                )}
              </RSC>
            </Card>
            <MDBox sx={{ position: "absolute", bottom: "1.3rem", left: "0" }}>
              * Beta Version
            </MDBox>
          </TabPanel>
        </MDBox>
      </LoadingOverlay>
    </TabContext>
  );
}

export default AIAgent;
