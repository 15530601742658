// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import Checkbox from "@mui/material/Checkbox";

// Images
import { bulkStatAPI } from "api";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";

export default function rejectedData({ setIsLoadingRejected }) {

  const navigation = useNavigate();
  const [rejectedAPI, setRejectedAPI] = useState([]);

  const [rejectedRows, setRejectedRows] = useState([]);

  const [rejectedSelects, setRejectedSelects] = useState([]);
  const [changeValue, setChangeValue] = useState(true);
  const [rejectedColumns, setRejectedColumns] = useState([]);


  const fetchRejectedAPI = useCallback(async () => {
    setIsLoadingRejected(true);
    try {
      const res = await bulkStatAPI.getRejectedData();
      const rejectedData = res.data.map((item) => ({
        ...item,
        // checked: false,
      }));
      setRejectedAPI(rejectedData);
      setIsLoadingRejected(false);
    } catch (err) {
      setIsLoadingRejected(false);
      if ([401].includes(err.response?.status)) {
        
        setGlobalState("error", {
          open: true,
          message: "Login Expired!",
          type: "error",
        });
      }
    }
  }, []);

  

  const changeRejectedValues = useCallback(async () => {
    setChangeValue((prev) => !prev);
    setRejectedSelects([])
  }, []);

  useEffect(() => {
    fetchRejectedAPI();
  }, [changeValue]);

  const getNewRejected = useCallback(async () => {
    const tempRows = [];

      rejectedAPI
      ? (() => {
        const initColumns = rejectedAPI && rejectedAPI.length > 0 ? Object.keys(rejectedAPI[0]) : [];
          // initColumns.push("select");
          // const columns = initColumns.filter((column) => column !== "checked");
          setRejectedColumns(initColumns);
          rejectedAPI.map((caseDetail, key) => {
            const rows = {};
            for (const column of initColumns) {
              rows[column] = caseDetail[column];
            }
            tempRows.push(rows);
          });
        })()
      : null;
    setRejectedRows(tempRows);
  }, [rejectedAPI]);

  
  useEffect(() => {
    getNewRejected();
  }, [getNewRejected]);

  return [
    rejectedRows,
    rejectedAPI,
    setRejectedAPI,
    setRejectedSelects,
    rejectedSelects,
    changeRejectedValues,
    rejectedColumns
  ];
}