import * as React from "react";
import Button from "@mui/material/Button";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Icon, MenuItem, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faCircleChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function ButtonGroup(props) {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <MDBox
      display="inline-block"
      sx={{
        position: "absolute",
        display: "flex",
        bottom: "1.5rem",
        right: "2rem",
      }}
    >
      <MDBox sx={{ position: "relative" }}>
        <Tooltip title="Download the Excel" placement="top">
          <MDButton
            variant={"outlined"}
            color={"info"}
            onClick={props.handleDownloadBulk}
            sx={{ borderRadius: "10px 0 0 10px" }}
          >
            <MDTypography>Download</MDTypography>
          </MDButton>
        </Tooltip>
        <Tooltip title="More Options" placement="top">
          <MDButton
            color={"info"}
            onClick={() => {
              setShowOptions(!showOptions);
            }}
            sx={{ borderRadius: "0 10px 10px 0", border: "1px solid grey" }}
          >
            <MDTypography>
              <FontAwesomeIcon
                icon={faChevronDown}
                color="white"
              ></FontAwesomeIcon>
            </MDTypography>
          </MDButton>
        </Tooltip>
        {showOptions && (
          <MDBox
            sx={{
              position: "absolute",

              width: "100%",
              background: "white",
            }}
          >
            <Tooltip title="Send Excel through Mail" placement="bottom">
              <MDButton
                variant={"contained"}
                color={"info"}
                onClick={()=>{setShowOptions(!showOptions);props.handleDownloadBulkEmail()}}
                sx={{ width: "100%" }}
              >
                <MDTypography sx={{ color: "white !important" }}>
                  Send Mail
                </MDTypography>
              </MDButton>
            </Tooltip>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}
