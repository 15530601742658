import { Card } from "@mui/material";
import { setGlobalState } from "Global";
import { AIAgentAPI } from "api";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { caseAPI } from "api";
import MDButton from "components/MDButton";
import CustomAutoComplete from "components/controls/AutoComplete";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import EmptyResult from "utils/emptyResult";
import MDTypography from "components/MDTypography";
import { LottieFiles } from "utils/lottieFiles";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
      box-sizing: border-box;
      width: 320px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 8px 12px;
      border-radius: 8px;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[700] : grey[200]
      };
      box-shadow: 0px 2px 2px ${
        theme.palette.mode === "dark" ? grey[900] : grey[50]
      };
  
      &:hover {
        border-color: ${blue[400]};
      }
  
      &:focus {
        border-color: ${blue[400]};
        box-shadow: 0 0 0 3px ${
          theme.palette.mode === "dark" ? blue[600] : blue[200]
        };
      }
  
      // firefox
      &:focus-visible {
        outline: 0;
      }
    `
);

function StepChangeAction({
  action,
  index,
  handleNotificationChange,
  handlePerformAction,
  agents,
  loaders
}) {
  return (
    action && <MDBox sx={{padding:"0",margin:"0"}}>
            <MDBox
        sx={{
          fontWeight: "500",
          margin: "0.5rem",
          display: "flex",
          alignItems: "center",
          marginBottom:"0.5rem",
          marginLeft: "0",
        }}
      >
        
        <lottie-player
        key={loaders["step_change"]?"loading":( "success")} 
        src={LottieFiles[loaders["step_change"]?"loading":( "success" )]}
          background="transparent"
          speed="1"
          style={{
            minWidth:"2rem",
            minHeight:"2rem",
            height: "2rem",
            width: "2rem",
          }}
          {...(loaders["step_change"]?{loop:true}:{})}
          keepLastFrame={true}
          autoplay
          direction="1"
          mode="normal"
        ></lottie-player>
        Step Change{" "}
        
          {!loaders["step_change"] && <MDBox sx={{ fontWeight: "400" }}>&nbsp; -&gt; Steps that AI will perform</MDBox>}
        
      </MDBox>

{!loaders["step_change"] && 
      <MDBox sx={{ paddingLeft: "2rem",display:"flex",flexDirection:"column" ,justifyContent:"space-between !important"}}>
        <MDBox sx={{display:"flex",marginLeft:"0.5rem"}}>
          <MDTypography sx={{fontWeight:"450"}}>Go to Next Step: &nbsp;</MDTypography>
          {action.next_step_chosen}
        </MDBox>
        <MDBox sx={{ marginTop:"0.5rem", display:"flex" ,marginLeft:"0.5rem"}}>
        <MDTypography sx={{fontWeight:"450"}}>Reason: &nbsp;</MDTypography>
        {action.reason_for_chosing_it}
        </MDBox>

        <MDBox
          sx={{
            display: "flex",
            justifyContent: "right",
            cursor: "pointer",
          }}
        >
            <MDButton
              onClick={(e) => handlePerformAction(action)}
              sx={{
                backgroundColor: "#66bb6a !important",
                minHeight: "2rem !important",
                marginRight: "1rem !important",
                fontSize: "0.7rem !important",
                height:"2rem !important"
              }}
              className={"font-unset"}
            >
              Change Step
            </MDButton>
        </MDBox>
      </MDBox>}
    </MDBox>
  );
}

export default StepChangeAction;
