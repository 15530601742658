import Icon from "@mui/material/Icon";
import Bulk from "layouts/bulkupload/index";
import AccessDenied from "layouts/authentication/access-denied";
import Dashboard from "layouts/dashboard";
import Forms from "layouts/forms";
import History from "layouts/history/index";
import Logout from "layouts/logout";
import Profile from "layouts/profile2";
import Tables from "layouts/tables";
import CreateCase from "layouts/tables/createCase";
import WorkflowUpdate from "layouts/workflowUpdate/workflowUpdate";
import WorkflowView from "layouts/workflowView/workflowview";
import UserManagement from "layouts/admin/userManagement";
import FormDetails from "layouts/formDetails";

export const defaultRoutes = [
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          person
        </Icon>
      ),
      route: "/profile",
      path: "/profile",
      access: ["agent1", "admin", "agent2", "superuser","3PA","3PU"],
      component: <Profile />,
    },

    {
      type: "collapse",
      name: "Analytics",
      key: "dashboard",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          analytics
        </Icon>
      ),
      route: "/dashboard",
      path: "/dashboard",
      access: ["agent1", "admin", "agent2", "superuser"],
      component: <Dashboard/>,
    },
    {
      type: "collapse",
      name: "Cases",
      key: "cases",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          table_view
        </Icon>
      ),
      path: "/:agent_id/cases",
      access: ["agent1", "admin", "agent2", "superuser","3PA","3PU"],
      component: <Tables/>,
    },
    {
      type: "collapse",
      name: "All Cases",
      key: "adminCases",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          table_view
        </Icon>
      ),
      path: "/:agent_id/adminCases",
      route: "/:agent_id/adminCases",
      access: ["agent2","admin", "superuser","3PA"],
      component: <Tables />,
    },
    {
      type: "collapse",
      name: "User Management",
      key: "userManagement",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          group_add
        </Icon>
      ),
      route: "/admin/userManagement",
      path: "/admin/userManagement",
      access: ["superuser"],
      component: <UserManagement/>,
    },
    {
      type: "collapse",
      name: "History",
      key: "history",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          history
        </Icon>
      ),
      route: "/history",
      path: "/history",
      access: ["agent1", "admin", "agent2", "superuser","3PA","3PU"],
      component: <History />,
    },

    {
      type: "",
      name: "History",
      key: "history",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          history
        </Icon>
      ),
      route: "/history",
      path: "/history/:case_id",
      access: ["agent1", "admin", "agent2", "superuser","3PA","3PU"],
      component: <History />,
    },
    {
      type: "collapse",
      name: "Bulk Case Upload",
      key: "bulk",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          upload
        </Icon>
      ),
      route: "/bulk",
      path: "/bulk",
      access: ["admin", "superuser"],
      component: <Bulk />,
    },
    {
      type: "",
      name: "Case",
      key: "case",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          notifications
        </Icon>
      ),
      route: "/cases/case",
      path: "/cases/:case_id",
      access: ["agent1", "admin", "superuser", "agent2","3PA","3PU"],
      component: <Forms />,
    },
    // {
    //   type: "collapse",
    //   name: "Form Details",
    //   key: "caseDetails",
    //   icon: (
    //     <Icon fontSize="medium" className="navbar-icon">
    //       notifications
    //     </Icon>
    //   ),
    //   route: "/caseDetails/case",
    //   path: "/caseDetails/65c0c1aa38b5a2e87528850f", // Using Default case id for developement
    //   access: ["agent1", "admin", "superuser", "agent2","3PA","3PU"],
    //   component: <FormDetails />,
    // },
    {
      type: "collapse",
      name: "Create Case",
      key: "createCase",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          add
        </Icon>
      ),
      route: "/createCase",
      path: "/createCase",
      access: ["admin", "superuser"],
      component: <CreateCase />,
    },
    {
      type: "collapse",
      name: "Worflow Update",
      key: "workflowUpdate",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          update
        </Icon>
      ),
      route: "/workflowUpdate",
      path: "/workflowUpdate",
      access: ["superuser"],
      component: <WorkflowUpdate />,
    },
    {
      type: "collapse",
      name: "Worflow View",
      key: "workflowview",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          account_tree{" "}
        </Icon>
      ),
      route: "/workflowview",
      path: "/workflowview",
      access: ["agent2", "superuser", "agent1", "admin","3PA","3PU"],
      component: <WorkflowView />,
    },

    {
      type: "collapse",
      name: "AI Analytics",
      key: "AIAnalytics",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          smart_toy
        </Icon>
      ),
      route: "/workflowview",
      path: "/workflowview",
      href: process.env.REACT_APP_ANALYTICS_URL + "/texttosql",
      componentType: "premium",
      access: ["agent2", "superuser", "agent1", "admin"],
      // component: <WorkflowView />,
    },
    {
      type: "collapse",
      name: "Logout",
      key: "access-denied",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          logout
        </Icon>
      ),
      route: "/logout",
      path: "/logout",
      access: ["agent2", "superuser", "agent1", "admin","3PA","3PU"],

      component: <Logout />,
    },
    {
      type: "",
      name: "Access Denied",
      key: "access-denied",
      icon: (
        <Icon fontSize="medium" className="navbar-icon">
          assignment
        </Icon>
      ),
      route: "/access-denied",
      path: "/access-denied",
      access: ["agent2", "superuser", "agent1", "admin","3PA","3PU"],

      component: <AccessDenied />,
    },
  ];