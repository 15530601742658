import { analyticsAPI } from "api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";

import { makeStyles } from '@material-ui/core';
import {
  AppBar,
  Autocomplete,
  Box,
  Card,
  Chip,
  FormControl,
  Grid,
  Icon,
  Tab,
  Tabs,
  Tooltip,
  Paper,
  TextField
} from "@mui/material";
import { setGlobalState, useGlobalState } from "Global";
import LoadingOverlay from "react-loading-overlay";
import Plot from "react-plotly.js";
import { useNavigate } from "react-router-dom";
import AccessWrapper from "wrappers/accessWrapper";

const useStyles = makeStyles({
  popper: { minWidth: "max-content !important" },
});


const ignoredSummaries = [
  "CaseState",
  "ClaimType",
  "AgeBucket",
  "Workflow",
  "Customer",
  "Retailer",
  "ReasonCodes",
  "SKU",
  "Category",
];

export const summaryMappings = {
  AgeBucket: {},
  Workflow: {},
  Customer: {},
  Retailer: {},
  ReasonCodes: {},
  SKU: {},
  Category: {},
  CaseState: {},
  ClaimType: {},
  Region: {},
};

export const summaryFilterMapping = {
  AgeBucket: [],
  Workflow: [],
  Customer: [],
  ReasonCodes: [],
  SKU: [],
  Category: [],
  CaseState: [],
  Retailer: [],
  ClaimType: [],
  days: 180,
};

export const chartToFilterMapping = {
  AgeBucket: "age_bucket",
  Workflow: "workflow_id",
  Customer: "customer_name",
  ReasonCodes: "reason_codes",
  SKU: "sku",
  Category: "category",
  CaseState: "state",
  Retailer: "retailer_name",
  ClaimType: "claim_type",
  days: "days",
};

function AdvancedAnalytics(props) {
  const navigation = useNavigate();
  const [filter, setFilter] = useState({
    days: 90,
    resolved_only: false,
    level: 1,
    variants: [],
  });

  const [summary, setSummary] = useState({});
  const [summaryFilters, setSummaryFilters] = useState(summaryMappings);
  const [summaryFilterOptions, setSummaryFilterOptions] = useState({});
  const [summaryLoader, setSummaryLoaders] = useState({
    AgeBucket: false,
    Workflow: false,
    Customer: false,
    ReasonCodes: false,
    SKU: false,
    Retailer: false,
    Category: false,
  });
  const [summaryToggles, setSummaryToggles] = useState({});

  const [SVT, setSVT] = useState({ days: 90, resolved_only: false });

  const [AWCA, setAWCA] = useState({ days: 90, resolved_only: false });

  const [LWC, setLWC] = useState({ days: 90, resolved_only: false });

  const [storedSummary] = useGlobalState("advancedAnalytics");

  const classes = useStyles();

  Array.prototype.scaleBetween = function (scaledMin, scaledMax) {
    var max = Math.max.apply(Math, this);
    var min = Math.min.apply(Math, this);
    return this.map((num) => {
      if (max == min) {
        return scaledMin;
      } else {
        return (
          ((scaledMax - scaledMin) * (num - min)) / (max - min) + scaledMin
        );
      }
    });
  };

  const getSummaries = async () => {
    Object.keys(summaryMappings).forEach(async (summaryName) => {
      if (!ignoredSummaries.includes(summaryName)) {
        getSummary(summaryName);
      }
    });
  };

  // Get Summary for all pie charts
  const getSummary = async (summaryName, bulk = false) => {
    const dynamicFunction = analyticsAPI[`getSummary${summaryName}`];
    if (!bulk) {
      setSummaryLoaders((prevSummaryLoader) => ({
        ...prevSummaryLoader,
        [summaryName]: true,
      }));
    }
    let test = await dynamicFunction(
      convertToParams(summaryFilters[summaryName])
    )
      .then(async (res) => {
        if (res.status == 200) {
          const tempData = res.data;
          // tempData["Count"].size = tempData["Count"].size.map((each) => {
          //   return (each * 5) % 100;
          // });
          tempData["Count"].size = tempData["Count"].size.scaleBetween(20, 100);

          tempData["Amount"].size = tempData["Amount"].size.scaleBetween(
            20,
            100
          );
          const data = {
            Count: {
              type: tempData["Count"].type,
              lat: tempData["Count"].lat,
              lon: tempData["Count"].long,
              mapName: tempData["Count"].name,
              hoverinfo: "text",
              text: tempData["Count"].keys,
              // locations: ["South Africa"],
              // locationmode: "country names",
              marker: {
                size: tempData["Count"].size,
                color: "lightgreen",
                line: {
                  color: "green",
                  width: 2,
                },
              },
            },
            Amount: {
              type: tempData["Amount"].type,
              lat: tempData["Amount"].lat,
              lon: tempData["Amount"].long,
              mapName: tempData["Amount"].name,
              hoverinfo: "text",
              text: tempData["Amount"].keys,
              // locations: ["South Africa"],
              // locationmode: "country names",
              marker: {
                size: tempData["Amount"].size,
                color: "lightgreen",
                line: {
                  color: "green",
                  width: 2,
                },
              },
            },
            Extra: tempData["Extra"],
          };

          const layout = {
            title: tempData["Count"].name,
            showlegend: false,

            geo: {
              scope: "world",
              // fitbounds:"locations",
              resolution: 100,
              showland: true,
              landcolor: "rgb(255,178,102)",
              subunitwidth: 1,
              countrywidth: 1,
              subunitcolor: "rgb(232,191,55)",
              countrycolor: "rgb(255,255,255)",
              projection: {
                scale: "7",
              },
              center: {
                lat: "-23",
                lon: "23",
              },
              // showocean: true,
              showcountries: true,
              bgcolor: "rgb(169,237,247)",
            },
            margin: {
              r: 0,
              b: 0,
              l: 0,
              pad: 0,
            },
          };

          setSummary((prevSummary) => ({
            ...prevSummary,
            [summaryName]: { ...data, layout: layout },
          }));
          setGlobalState("advancedAnalytics", (prevSummary) => ({
            ...prevSummary,
            [summaryName]: { ...data, layout: layout },
          }));
          if (!bulk) {
            setSummaryLoaders((prevSummaryLoader) => ({
              ...prevSummaryLoader,
              [summaryName]: false,
            }));
          }
        }
      })
      .catch((error) => {
        if (!bulk) {
          setSummaryLoaders((prevSummaryLoader) => ({
            ...prevSummaryLoader,
            [summaryName]: false,
          }));
        }
      });
  };

  const handleSummaryFilters = (event, summaryName) => {
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value._id ? value._id : "";
      }
    }

    if (event.target.value.includes("All")) {
      event.target.value = [];
    }
    setSummaryFilters({
      ...summaryFilters,
      [summaryName]: {
        ...summaryFilters[summaryName],
        [event.target.name]: event.target.value,
      },
    });
  };

  const getSummaryFilterValues = () => {
    analyticsAPI.getSummaryFilterValues().then((res) => {
      let tempData = res.data;
      Object.keys(tempData).forEach((filter) => {
        tempData[filter].push({ name: "All",  _id: "All"});
      });
      setSummaryFilterOptions(res.data);
    });
  };

  const initializeSummaryFilters = () => {
    let tempFilters = {};
    Object.keys(summaryMappings).forEach((summary) => {
      tempFilters[summary] = summaryFilterMapping;
    });
    setSummaryFilters(tempFilters);
  };

  const initializeSummaryToggles = () => {
    let tempToggles = {};
    Object.keys(summaryMappings).forEach((summary) => {
      tempToggles[summary] = "Count";
    });
    setSummaryToggles(tempToggles);
  };

  const convertToParams = (params) => {
    let tempParams = {};
    if (params) {
      Object.keys(params).forEach((param) => {
        tempParams[chartToFilterMapping[param]] = params[param];
      });
    }
    return tempParams;
  };

  const handleSetTabValue = (event, newValue, summaryName) => {
    setSummaryToggles({
      ...summaryToggles,
      [summaryName]: newValue,
    });
  };

  useEffect(() => {
    // getLWC();
    initializeSummaryToggles();
    initializeSummaryFilters();
    getSummaryFilterValues();

    const fetchAllSummaries = async () => {
      if (Object.keys(storedSummary).length != 0) {
        setSummary(storedSummary);
      } else {
        getSummaries();
      }
    };
    getSVT();

    getAWCA();

    fetchAllSummaries();
    // getCCT();
  }, []);

  // SVT Functions

  const getSVT = () => {
    analyticsAPI
      .getStepsTime(SVT?.days)
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          const SVTdata = [
            {
              x: data.number_of_steps_done_per_agent,
              y: data.time_taken_by_each_agent,
              mode: "markers",
              type: "scatter",
              hoverinfo: "text",
              text: data.agent_names,
              marker: { size: 18 },
            },
          ];

          const Xmax = Math.max(...data.number_of_steps_done_per_agent);
          const Ymax = Math.max(...data.time_taken_by_each_agent);

          const SVTlayout = {
            scattermode: "group",
            title: "Agent Performance",
            xaxis: { title: "Steps" },
            yaxis: { title: "Time (in days)" },
            shapes: [
              {
                type: "rect",
                x0: Xmax / 2,
                y0: Ymax / 2,
                x1: Xmax,
                y1: Ymax,
                fillcolor: "green",
                opacity: 0.2,
                line: {
                  color: "green",
                  width: 2,
                },
              },
              {
                type: "rect",
                x0: Xmax / 2,
                y0: 0,
                x1: Xmax,
                y1: Ymax / 2,
                fillcolor: "green",
                opacity: 0.5,
                line: {
                  color: "green",
                  width: 2,
                },
              },
              {
                type: "rect",
                x0: 0,
                y0: 0,
                x1: Xmax / 2,
                y1: Ymax / 2,
                fillcolor: "red",
                opacity: 0.2,
                line: {
                  color: "red",
                  width: 2,
                },
              },
              {
                type: "rect",
                x0: 0,
                y0: Ymax / 2,
                x1: Xmax / 2,
                y1: Ymax,
                fillcolor: "red",
                opacity: 0.5,
                line: {
                  color: "red",
                  width: 2,
                },
              },
            ],
            annotations: [
              {
                x: (Xmax / 4) * 3,
                y: 0.25,
                xref: "x",
                yref: "paper",
                text: "Very Efficient",
                showarrow: false,
                opacity: 0.5,
                font: {
                  family: "Arial",
                  size: 14,
                  color: "#000000",
                },
              },
              {
                x: Xmax / 4,
                y: 0.75,
                xref: "x",
                yref: "paper",
                text: "Least Efficient",
                showarrow: false,
                opacity: 0.5,
                font: {
                  family: "Arial",
                  size: 14,
                  color: "#000000",
                },
              },
            ],
          };
          setSVT({ ...SVT, data: SVTdata, layout: SVTlayout });
        }
      })
      .catch((error) => {});
  };

  const handleSVTChange = (event) => {
    setSVT({ ...SVT, days: event.target.value });
  };

  // AWCA Functions
  const getAWCA = () => {
    analyticsAPI
      .getAgentWiseClaimAmountInProgess(AWCA?.days)
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          const tempData = [
            {
              x: data.x,
              y: data.y,
              type: "bar",
            },
          ];
          const layout = {
            title: data.name,
            xaxis: { title: data["x-name"] },
            yaxis: { title: data["y-name"] },
          };
          setAWCA({ ...AWCA, data: tempData, layout });
        }
      })
      .catch((error) => {});
  };

  const handleAWCAChange = (event) => {
    setAWCA({ ...AWCA, [event.target.name]: event.target.value });
  };

  // LWC Functions
  const getLWC = (days) => {
    analyticsAPI
      .getLocationWiseClaims(LWC?.days)
      .then((res) => {
        if (res.status == 200) {
          const tempData = res.data.location_of_claims;
          tempData.size = tempData.size.map((each) => {
            return each * 5;
          });
          const data = [
            {
              type: "scattergeo",
              lat: tempData.lat,
              lon: tempData.long,
              hoverinfo: "text",
              text: tempData.name,
              // locations: ["South Africa"],
              // locationmode: "country names",
              marker: {
                size: tempData.size,
                color: "lightgreen",
                line: {
                  color: "green",
                  width: 2,
                },
              },
            },
          ];

          const layout = {
            title: "Location Wise Claims Raised",
            showlegend: false,

            geo: {
              scope: "world",
              // fitbounds:"locations",
              resolution: 110,
              showland: true,
              landcolor: "rgb(255,178,102)",
              subunitwidth: 1,
              countrywidth: 1,
              subunitcolor: "rgb(232,191,55)",
              countrycolor: "rgb(255,255,255)",
              projection: {
                scale: "7",
              },
              center: {
                lat: "-23",
                lon: "23",
              },
              // showocean: true,
              showcountries: true,
              bgcolor: "rgb(169,237,247)",
            },
            margin: {
              r: 0,
              b: 0,
              l: 0,
              pad: 0,
            },
          };

          setLWC({ ...LWC, data, layout });
        }
      })
      .catch((error) => {});
  };

  const handleLWCChange = (event) => {
    setLWC({ ...LWC, days: event.target.value });
  };

  return (
    <>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid
            item
            xs={12}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            {/* SVT card */}
            <Card>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid justifyContent={"center"} xs={9} md={9} display="flex">
                    <MDBox
                      sx={{
                        alignItems: "center",
                        height: "fit-content",
                        paddingTop: "1rem",
                        maxWidth: "100%",
                        width: "auto",
                        minHeight: "35vh",
                      }}
                    >
                      {<Plot data={SVT?.data} layout={SVT?.layout} />}
                    </MDBox>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      id="days"
                      label="Period (Days)"
                      name={"days"}
                      value={SVT?.days}
                      onChange={handleSVTChange}
                      variant="outlined"
                      sx={{ marginBottom: "2rem", marginTop: "35%" }}
                    />{" "}
                    <MDButton onClick={getSVT} color="warning">
                      <Icon>search</Icon>&nbsp;Filter
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>

            {/* LWC card */}
            {/* <Card sx={{ marginTop: "2rem" }}>
                  <MDBox p={3}>
                    <Grid container spacing={3}>
                      <Grid
                        justifyContent={"center"}
                        xs={9}
                        md={9}
                        display="flex"
                      >
                        <MDBox
                          sx={{
                            alignItems: "center",
                            height: "auto",
                            paddingTop: "1rem",
                            maxWidth: "100%",
                            marginBottom: "1rem",
                            minHeight: "35vh",
                          }}
                        >
                          {LWC?.data && (
                            <Plot data={LWC.data} layout={LWC.layout} />
                          )}
                        </MDBox>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        md={3}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <TextField
                          id="days"
                          label="Days"
                          name={"days"}
                          value={LWC?.days}
                          onChange={handleLWCChange}
                          variant="outlined"
                          sx={{ marginBottom: "2rem", marginTop: "35%" }}
                        />{" "}
                        <MDButton onClick={getLWC} color="warning">
                          <Icon>search</Icon>&nbsp;Filter
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card> */}

            {/* AWCA card */}
            <Card sx={{ marginTop: "2rem" }}>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid justifyContent={"center"} xs={9} md={9} display="flex">
                    <MDBox
                      sx={{
                        alignItems: "center",
                        height: "auto",
                        paddingTop: "1rem",
                        maxWidth: "100%",
                        marginBottom: "1rem",
                        minHeight: "35vh",
                      }}
                    >
                      {AWCA?.data && (
                        <Plot data={AWCA.data} layout={AWCA.layout} />
                      )}
                    </MDBox>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      id="days"
                      label="Period (Days)"
                      name={"days"}
                      value={AWCA?.days}
                      onChange={handleAWCAChange}
                      variant="outlined"
                      sx={{ marginBottom: "2rem", marginTop: "35%" }}
                    />{" "}
                    <MDButton onClick={getAWCA} color="warning">
                      <Icon>search</Icon>&nbsp;Filter
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>

            <Grid
              item
              xs={12}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              {/* Summary of Claims card */}
              {Object.keys(summaryMappings).map((summaryChart, index) => {
                if (!ignoredSummaries.includes(summaryChart))
                  return (
                    <Card sx={{ marginTop: "2rem" }} key={index}>
                      <MDBox p={3}>
                        <Grid container spacing={3}>
                          <Grid
                            justifyContent={"center"}
                            xs={8}
                            md={8}
                            display="flex"
                          >
                            <LoadingOverlay
                              active={summaryLoader[summaryChart]}
                              spinner
                              text="Loading your content..."
                            >
                              <MDBox
                                sx={{
                                  alignItems: "center",
                                  height: "auto",
                                  paddingTop: "1rem",
                                  maxWidth: "100%",
                                  marginBottom: "1rem",
                                  minHeight: "35vh",
                                }}
                              >
                                <AppBar position="static">
                                  <Tabs
                                    orientation={"horizontal"}
                                    value={summaryToggles[summaryChart]}
                                    onChange={(event, value) =>
                                      handleSetTabValue(
                                        event,
                                        value,
                                        summaryChart
                                      )
                                    }
                                  >
                                    <Tab label="Count" value="Count" />
                                    <Tab label="Amount" value="Amount" />
                                  </Tabs>
                                </AppBar>
                                {summary[summaryChart] && (
                                  <>
                                    <Plot
                                      data={[
                                        summary[summaryChart][
                                          summaryToggles[summaryChart]
                                        ],
                                      ]}
                                      layout={{
                                        ...summary[summaryChart].layout,
                                        title:
                                          summary[summaryChart][
                                            summaryToggles[summaryChart]
                                          ]["mapName"],
                                      }}
                                    />
                                    <MDBox
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        fontSize: "small",
                                      }}
                                    >
                                      {summary[summaryChart].Extra?.map(
                                        (extraValue) => {
                                          return <MDBox>{extraValue}</MDBox>;
                                        }
                                      )}
                                    </MDBox>
                                  </>
                                )}
                              </MDBox>
                            </LoadingOverlay>
                          </Grid>
                          <Grid item xs={4} md={4} mt={3} container>
                            {Object.keys(summaryFilters).map(
                              (filterName, index) => {
                                if (filterName != summaryChart) {
                                  return (
                                    <Grid item xs={6} key={index}>
                                      <FormControl
                                        size={"large"}
                                        sx={{
                                          width: "90%",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        {summaryFilterOptions[filterName]
                                          ?.length > 0 && (
                                          <Autocomplete
                                            multiple
                                            value={
                                              summaryFilters[summaryChart][
                                                filterName
                                              ]
                                            }
                                            onChange={(event, newValue) => {
                                              handleSummaryFilters(
                                                {
                                                  target: {
                                                    name: filterName,
                                                    value: newValue,
                                                  },
                                                },
                                                summaryChart
                                              );
                                            }}
                                            size={"small"}
                                            id="multiple-limit-tags"
                                            options={
                                              summaryFilterOptions[filterName]
                                            }
                                            getOptionLabel={(option) => {
                                              return option?.name
                                                ? option.name
                                                : option;
                                            }}
                                            classes={{ popper: classes.popper }}
                                            PaperComponent={(props) => (
                                              <Paper
                                                sx={{
                                                  fontSize: "25px",
                                                }}
                                                {...props}
                                              />
                                            )}
                                            renderInput={(params) => {
                                              return (
                                                <TextField
                                                  {...params}
                                                  label={filterName}
                                                  placeholder={filterName}
                                                  InputProps={{
                                                    ...params.InputProps,

                                                    endAdornment: <></>,
                                                  }}
                                                />
                                              );
                                            }}
                                            renderTags={(
                                              value,
                                              getTagProps,
                                              ownerState
                                            ) => {
                                              let showChips = summaryFilters[
                                                summaryChart
                                              ][filterName].slice(0, 2);
                                              if (ownerState.focused) {
                                                showChips =
                                                  summaryFilters[summaryChart][
                                                    filterName
                                                  ];
                                              }
                                              return (
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    gap: 0.1,
                                                    justifyContent: "left",
                                                  }}
                                                >
                                                  {showChips.map(
                                                    (value, index) => {
                                                      if (
                                                        index <
                                                        (ownerState.focused
                                                          ? summaryFilters[
                                                              summaryChart
                                                            ][filterName].length
                                                          : 1)
                                                      ) {
                                                        return (
                                                          <Tooltip
                                                            title={value}
                                                            placement="bottom-start"
                                                          >
                                                            <Chip
                                                              key={value}
                                                              label={value}
                                                              sx={{
                                                                fontSize:
                                                                  "x-small",
                                                                height:
                                                                  "fit-content",
                                                              }}
                                                            />
                                                          </Tooltip>
                                                        );
                                                      } else {
                                                        return (
                                                          <Chip
                                                            key={"+1"}
                                                            label={`+${
                                                              summaryFilters[
                                                                summaryChart
                                                              ][filterName]
                                                                .length - 1
                                                            }`}
                                                            sx={{
                                                              fontSize:
                                                                "x-small",
                                                              height:
                                                                "fit-content",
                                                            }}
                                                          />
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </Box>
                                              );
                                            }}
                                            sx={{ width: "100%" }}
                                          />
                                        )}
                                      </FormControl>
                                    </Grid>
                                  );
                                }
                              }
                            )}
                            {summaryFilters[summaryChart] && (
                              <Grid item xs={6}>
                                <FormControl
                                  size={"large"}
                                  sx={{
                                    width: "90%",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <TextField
                                    id="days"
                                    label="Period (Days)"
                                    name={"days"}
                                    value={summaryFilters[summaryChart]["days"]}
                                    onChange={(e) =>
                                      handleSummaryFilters(e, summaryChart)
                                    }
                                    variant="outlined"
                                    sx={{ marginBottom: "1rem" }}
                                  />
                                </FormControl>
                              </Grid>
                            )}

                            <MDButton
                              sx={{ height: "2rem", width: "100%" }}
                              onClick={(e) => {
                                getSummary(summaryChart);
                              }}
                              color="warning"
                            >
                              <Icon>search</Icon>&nbsp;Filter
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                  );
              })}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default AccessWrapper(AdvancedAnalytics, ["superuser"]);
