import CircularProgress, {
    circularProgressClasses
} from '@mui/material/CircularProgress';



function CustomLoader() {
    return ( 
        <CircularProgress
        // variant="indeterminate"
        // disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          
          
          
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        
      />
     );
}

export default CustomLoader;