
// react-router-dom components

// @mui material components

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components

// Images
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AccessDenied() {
  return (
    <MDBox
      sx={{
        paddingTop: "10%",
        height: "100%",
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <MDBox
        variant="gradient"
        borderRadius="lg"
        coloredShadow="error"
        sx={{ width: "50%" }}
        p={2}
        mb={1}
        textAlign="center"
      >
        {/* <img src={accessDeniedImage} width={"40%"} height={"40%"} /> */}
        <FontAwesomeIcon
          size={"xl"}
          color="#f44334"
          icon={faBan}
        ></FontAwesomeIcon>
        <MDTypography variant="h4" fontWeight="medium" color="error" mt={1}>
          You don't have access. Please contact the administrator.
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default AccessDenied;
