import {
  faAdd,
  faCloudUpload,
  faFileArrowUp,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import { useState, useRef } from "react";

function UploadFileCard({ stepName, fileName, uploadFiles, length = 0 }) {
  const handleFile = (files) => {
    uploadFiles(stepName, fileName, files);
  };
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleAlert = () => {
    alert("Please upload 1 file at a time");
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (e.dataTransfer.files.length == 1) {
        handleFile(e.dataTransfer.files);
      } else {
        handleFile(e.dataTransfer.files);
      }
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (e.target.files.length == 1) {
        handleFile(e.target.files);
      } else {
        handleFile(e.target.files);
      }
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      {length == 0 ? (
        <MDBox sx={{ height: "4rem", width: "4rem" }} className={"side-upload"}>
                      <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              style={{ display: "none" }}
              onChange={handleChange}
              multiple={true}
            />
          <Tooltip title={`Upload ${fileName}`}>

            <FontAwesomeIcon
              icon={faUpload}
              style={{
                marginRight: "0.5rem",
                width: "100%",
                height: "inherit",
                color: "grey",
                cursor: "pointer",
              }}
              onClick={onButtonClick}
            ></FontAwesomeIcon>
          </Tooltip>
        </MDBox>
      ) : (
        <form
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
          onDrop={handleDrop}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onClick={onButtonClick}
        >
          <Card
            sx={{
              cursor: "pointer",
              width: "8rem",
              padding: "0.8rem",
              margin: "1rem",
            }}
            className={"add-files"}
          >
            <FontAwesomeIcon
              icon={faCloudUpload}
              style={{
                width: "50%",
                height: "50%",
                color: "grey",

                alignSelf: "center",
              }}
            ></FontAwesomeIcon>
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              style={{ display: "none" }}
              onChange={handleChange}
              multiple={true}
            />
            <MDBox>
              {" "}
              Drag File here <br />
              or <br />
              <a>Browse File</a>
            </MDBox>
          </Card>
        </form>
      )}
    </>
  );
}

export default UploadFileCard;
