import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  Grid,
  TextField,
  Icon,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDProgress from "components/MDProgress";
import { analyticsAPI } from "api";

import { setGlobalState } from "Global";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import PMWorkflow from "layouts/dashboard/processmining/pmWorkflow";

const defaultStyles = {
  cursor: "pointer",
  border: "solid 0.5px black",
  padding: "10px",
  borderRadius: "10px",
  margin: "5px",
};

const onSelectStyles = {
  cursor: "pointer",
  border: "solid 0.5px black",
  padding: "10px",
  borderRadius: "10px",
  margin: "5px",
  backgroundColor: "lightgreen",
};

function CaseVariant({ variantDetails, handleClose }) {
  const navigation = useNavigate();
  const [open, setOpen] = useState(true);
  const [plot, setPlot] = useState({});
  const [PMData, setPMData] = useState({});
  const [filter, setFilter] = useState({
    resolved_only: false,
    level: 1,
    cases: [],
  });
  const [selectedCases, setSelectedCases] = useState([0]);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };

  const handleCase = (index) => {
    let tempCases = selectedCases;

    if (!tempCases.includes(index)) {
      tempCases.push(index);
      setSelectedCases([...tempCases]);
    } else {
      let currIndex = tempCases.indexOf(index);
      tempCases.splice(currIndex, 1);
      setSelectedCases([...tempCases]);
    }
  };

  useEffect(() => {
    handlePlotFilter();
  }, []);

  const handlePlotFilter = () => {
    let cases = [];
    selectedCases.forEach((index) => {
      cases.push(variantDetails.cases_details[index]?.name);
    });
    setIsLoading(true);
    analyticsAPI
      .getPMData(
        filter.resolved_only,
        filter.case_id,
        filter.level,
        null,
        cases
      )
      .then((res) => {
        if (res.status == 200) {
          setPMData(res.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth={"xl"}>
      <MDBox>
        <Grid container spacing={6} m={3} width={"95%"}>
          <Grid container spacing={3}>
            <Grid justifyContent={"center"} xs={9} md={9} display="flex">
              <LoadingOverlay
                active={isLoading}
                spinner
                text="Loading your content..."
              >
                <MDBox
                  sx={{
                    display: "flex",
                    width: "60vw !important",
                    height: "80vh",
                    maxHeight: "100%",
                    justifyContent: "center",
                  }}
                >
                  {/* React Flow component */}
                  {!isLoading && (
                    <PMWorkflow nodes={PMData.nodes} edges={PMData.edges} />
                  )}
                </MDBox>
              </LoadingOverlay>
              {/* <Plot data={data} layout={layout}/> */}
            </Grid>
            <Grid
              item
              xs={3}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControl sx={{ marginBottom: "2rem" }}>
                <MDTypography
                  id="demo-row-radio-buttons-group-label"
                  sx={{ fontSize: "medium" }}
                >
                  View
                </MDTypography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="level"
                  // value={filter?.view}
                  onChange={handleChange}
                  sx={{ display: "flex", justifyContent: "center" }}
                  defaultValue={1}
                >
                  <FormControlLabel
                    value={1}
                    name="level"
                    control={<Radio />}
                    label="Eagle"
                  />
                  <FormControlLabel
                    value={2}
                    name="level"
                    control={<Radio />}
                    label="In-Depth"
                  />
                </RadioGroup>
              </FormControl>
              {/* <FormControl size={"large"} sx={{ marginBottom: "2rem" }}>
                <InputLabel sx={{}} id="resolved_label">
                  Resolved
                </InputLabel>
                <Select
                  labelId="resolved_label"
                  value={filter?.resolved_only ? filter.resolved_only : "false"}
                  label="Resolved"
                  name="resolved_only"
                  onChange={handleChange}
                  sx={{ p: 1.5 }}
                >
                  <MenuItem value={"false"} name={"resolved_only"}>
                    False
                  </MenuItem>
                  <MenuItem value={"true"} name={"resolved_only"}>
                    True
                  </MenuItem>
                </Select>
              </FormControl> */}
              <MDBox
                sx={{
                  marginBottom: "2rem",
                  maxHeight: "60%",
                  overflowX: "hidden",
                  overflowX: "auto",
                }}
              >
                <MDTypography sx={{ fontSize: "medium" }}>Cases</MDTypography>
                {variantDetails?.cases_details?.map((singleCase, index) => {
                  // console.log(selectedsingleCases);
                  return (
                    <>
                      <MDBox
                        onClick={(event) => {
                          handleCase(index);
                        }}
                        sx={
                          selectedCases.includes(index)
                            ? onSelectStyles
                            : defaultStyles
                        }
                        key={index}
                      >
                        <MDTypography
                          sx={{
                            fontSize: "small",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {singleCase?.name}
                        </MDTypography>

                        <MDProgress
                          value={singleCase?.time_taken_percentage}
                          text={singleCase?.time_taken_days}
                          color="info"
                          variant="gradient"
                          label={true}
                        />
                      </MDBox>
                    </>
                  );
                })}
              </MDBox>
              <MDButton onClick={handlePlotFilter} color="warning">
                <Icon>search</Icon>&nbsp;Search
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </Dialog>
  );
}

export default CaseVariant;
