import * as React from "react";
import { Input as BaseInput } from "@mui/base/Input";
import { styled } from "@mui/system";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";

const Input = React.forwardRef((props, ref) => {
  return <CustomTextField {...props} ref={ref} />;
});

export default function CustomInput({
  name,
  label,
  value,
  error = null,
  onChange,
  disabled = false,
  unit = false,
  sx = {},
  style,
  ...props
}) {
  return (
    <Input
      name={name}
      label={label}
      value={value}
      InputProps={
        unit
          ? {
              startAdornment: <InputAdornment>ZAR</InputAdornment>,
            }
          : {}
      }
      onChange={onChange}
      disabled={disabled}
      sx={{...sx, '& .MuiInputBase-input': style}}
      {...props}
    />
  );
}

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  width: "100%",
  margin: "10px 0 10px 0",
  // "& .MuiOutlinedInput-root": {
  //   "& .MuiOutlinedInput-notchedOutline": {
  //     borderColor: theme.palette.inputBorderColor,
  //     color: theme.palette.text.disabled,
  //   },
  //   "&:hover fieldset": {
  //     border: disabled ? '' :'2px solid '+ theme.palette.primary.main,
  //   },
  //   "&.Mui-focused fieldset": {
  //     border: '2px solid '+ theme.palette.primary.main,
  //   },
  // },
}));
