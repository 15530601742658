// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import Checkbox from "@mui/material/Checkbox";

// Images
import { bulkStatAPI } from "api";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { setGlobalState } from "Global";

export default function excelData({ setIsLoadingExcel }) {

  const navigation = useNavigate();
  const [excelAPI, setExcelAPI] = useState([]);

  const [excelRows, setExcelRows] = useState([]);

  const [excelSelects, setExcelSelects] = useState([]);
  const [changeValue, setChangeValue] = useState(true);
  const [excelColumns, setExcelColumns] = useState([]);


  const fetchExcelAPI = useCallback(async () => {
    setIsLoadingExcel(true);
    try {
      const res = await bulkStatAPI.getExcelData();
      const excelData = res.data.map((item) => ({
        ...item,
        // checked: false,
      }));
      setExcelAPI(excelData);
      setIsLoadingExcel(false);
    } catch (err) {
      setIsLoadingExcel(false);
      if ([401].includes(err.response?.status)) {
        setGlobalState("error", {
          open: true,
          message: "Login Expired!",
          type: "error",
        });
      }
    }
  }, []);

  

  const changeExcelValues = useCallback(async () => {
    setChangeValue((prev) => !prev);
    setExcelSelects([])
  }, []);

  useEffect(() => {
    fetchExcelAPI();
  }, [changeValue]);

  const getNewExcel = useCallback(async () => {
    const tempRows = [];

      excelAPI
      ? (() => {
        const initColumns = excelAPI && excelAPI.length > 0 ? Object.keys(excelAPI[0]) : [];
          // initColumns.push("select");
          // const columns = initColumns.filter((column) => column !== "checked");
          setExcelColumns(initColumns);
          excelAPI.map((caseDetail, key) => {
            const rows = {};
            for (const column of initColumns) {
              rows[column] = caseDetail[column];
            }
            tempRows.push(rows);
          });
        })()
      : null;
    setExcelRows(tempRows);
  }, [excelAPI]);

  
  useEffect(() => {
    getNewExcel();
  }, [getNewExcel]);

  return [
    excelRows,
    excelAPI,
    setExcelAPI,
    setExcelSelects,
    excelSelects,
    changeExcelValues,
    excelColumns
  ];
}