import React, { useEffect, useState } from "react";

import FileViewer from "react-file-viewer";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { featureAPI } from "api";
import { setGlobalState } from "Global";
import { useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import MDBox from "components/MDBox";

function FileModal({ file }) {
  const params = useParams();

  const [fileUrl, setFileUrl] = useState(null);
  const getFileType = (fileName) => {
    let fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (fileType == "msg") {
      fileType = "pdf";
    }

    return fileType;
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const downloadFile = () => {
    featureAPI
      .downloadFile({
        case_id: params.case_id,
        file_name: file.fileName,
        file_feature_name: file.fileFeatureName,
        step_name: file.stepName,
      })
      .then(async (res) => {
        if (res.status == 200) {
          let blob = res.data;
          // 2. Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          setFileUrl(url);
          sessionStorage.setItem(file["file_hash"] + file.fileName, await blobToBase64(blob));

        }
      })
      .catch((error) => {
        if (![422].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message: "Error while retrieving the file. Please load again",
            type: "error",
            code: error.response.status
          });
        }
      });
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], { type: mimeString });
    return blob;
}

  useEffect(async () => {
    if (sessionStorage.getItem(file["file_hash"] + file.fileName)) {
      let url = window.URL.createObjectURL(
        new Blob([dataURItoBlob(sessionStorage.getItem(file["file_hash"] + file.fileName))])
      );
      setFileUrl(url);
    } else {
      downloadFile();
    }
  }, []);

  if (getFileType(file["name"]) == "pdf" && fileUrl) {
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer
          fileUrl={fileUrl}
          //   plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}

          //   renderError={renderError}
        />
      </Worker>
    );
  } else if (fileUrl !=null) {
    return (
      <FileViewer fileType={getFileType(file["name"])} filePath={fileUrl} />
    );
  } else {
    return <LoadingOverlay styles={{wrapper:(base)=>({...base,display:"flex !important",height:"100%! important"})}} active={true} spinner text="Loading your content..."><MDBox sx={{height:"100%"}}></MDBox></LoadingOverlay>;
  }
}

export default FileModal;
