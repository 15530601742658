import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Controls from "components/controls/Controls";
import UploadFileCard from "layouts/forms/file/uploadFileCard";
import { useState } from "react";
import { LottieFiles } from "utils/lottieFiles";

function FeatureUpdateAction({
  action,
  index,
  handleChange,
  handlePerformAction,
  loaders
}) {
  
    const ControlType = action?Controls[action.type]:Controls["Text"];

  return (
    <>
      <MDBox
        sx={{
          fontWeight: "500",
          margin: "0.5rem",
          display: "flex",
          alignItems: "center",
          marginLeft: "0",
        }}
      >
        {(action==null || action) &&
        <lottie-player
        key={loaders["feature_action"]?"loading":(action ? "failure": "success")} 
          src={LottieFiles[loaders["feature_action"]?"loading":(action ? "failure": "success" )]}
          background="transparent"
          speed="1"
          style={{
            minWidth:"2rem",
            minHeight:"2rem",
            height: "2rem",
            width: "2rem",
          }}
          {...(loaders["feature_action"]?{loop:true}:{})}
          keepLastFrame={true}
          autoplay
          direction="1"
          mode="normal"
        ></lottie-player>}
        Features Required{" "}
        {!loaders["feature_action"] && action ? (<>
        <MDBox sx={{whiteSpace:"nowrap",marginRight:"0.5rem"}}>&nbsp; -&gt;</MDBox>
          <MDBox sx={{ fontWeight: "400" }}> Please fill these Features</MDBox>
          </>
        ) : (
          ""
        )}
      </MDBox>
      {!loaders["feature_action"] && action && (
        <MDBox sx={{ paddingLeft: "2rem",display:"flex",flexDirection:action.type=="KeyvalueConstOptions"?"column":"row" ,justifyContent:"space-between !important"}}>
          <MDBox >
            <MDBox
              sx={{
                margin: "0 0 0 0",
                fontWeight: "normal",
                fontSize: "0.7rem !important",
              }}
              className="font-unset"
            >
              {action.feature_name}
            </MDBox>
            <ControlType
              name={action.feature_name}
              // label={action.feature_name}
              placeholder={action.feature_name}
              value={action.value}
              onChange={(e) => handleChange(e, "feature_action", action.type)}
              options={action.scope}
              keyscopes={action.keyscope}
              size={"small"}
              style={{
                borderRadius: "5px",
                lineHeight: "0.5 !important",
                boxShadow: `2px 2px 4px rgba(0,0,0, 0.15)`,
                minHeight: "2rem",
              }}
              /* styles the label component */
              InputLabelProps={{
                style: {
                  height: "1rem",
                },
              }}
              /* styles the input component */
              {...(action.type!="DropdownSingleSelect" ? { inputProps: {
                style: {
                  height: "1rem",
                  padding: "0.5rem 0.5rem",
                },
              }} : {})}
              componentSize={"small"}
              // inputProps={action.type=="DropdownSingleSelect"?{...params.inputProps}:{
              //   style: {
              //     height: "1rem",
              //     padding: "0.5rem 0.5rem",
              //   },
              // }}
            />
          </MDBox>

          <MDBox
            sx={{
              display: "flex",
              margin:"0.5rem 0 0 0.5rem",
              alignItems:"end",
              cursor: "pointer",
              justifyContent:"end"
            }}
          >
            <MDButton
              onClick={(e) => handlePerformAction(action)}
              sx={{
                backgroundColor: "#66bb6a !important",
                minHeight: "2rem !important",
                fontSize: "0.7rem !important",
                height:"2rem !important"
              }}
              className={"font-unset"}
            >
              Submit
            </MDButton>
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default FeatureUpdateAction;
