// react-routers components
import { Link, useNavigate, useParams } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { featureAPI } from "api";
import { setGlobalState } from "Global";
import DataTable from "examples/Tables/DataTable";
import TableDisplay from "layouts/tableDisplay/tableDisplay";

function GroupedCaseList() {
  const [groupedCases, setGroupedCases] = useState([]);
  const navigation = useNavigate();
  const params = useParams();

  const handleRowClick = (event, row) => {
    const case_id = event.row.values.case_id;
    if (case_id != params.case_id) navigation(`/cases/${case_id}`);
  };

  const getGroupedCases = () => {
    featureAPI
      .getGroupedCases({ case_id: params.case_id })
      .then((res) => {
        if (res.status == 200) {
          setGroupedCases(res.data.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getGroupedCases();
  }, []);

  return (
    <Card sx={{ height: "100%", boxShadow: "none" }}>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <TableDisplay
            data={groupedCases}
            showTotalEntries={false}
            canSearch={false}
            entriesPerPage={false}
            customStyle={{ marginTop: "-2rem" }}
            rowClick={handleRowClick}
            rowColor={{
              id: { name: "case_id", value: params.case_id },
              backgroundColor: "lightgrey",
              color: "black",
            }}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default GroupedCaseList;
