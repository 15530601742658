import React from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AgentChange from "layouts/forms/dialogs/agentChange";
import EmailForm from "layouts/forms/dialogs/emailform";
import StateChange from "layouts/forms/dialogs/stateChange";
import StatusChange from "layouts/forms/dialogs/statusChange";
import LoadingOverlay from "react-loading-overlay";

// Data
import {
  faBell,
  faCheck,
  faChevronDown,
  faChevronUp,
  faComments,
  faEdit,
  faEnvelopesBulk,
  faHistory,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, Tooltip } from "@mui/material";
import { setGlobalState } from "Global";
import { OCRAPI, caseAPI, featureAPI } from "api";
import { Validations } from "components/controls/Validations";
import "css/forms/dropdown.css";
import { format } from "date-fns";
import SideChat from "layouts/chat";
import WorkFlowChange from "layouts/forms/dialogs/workFlowChange";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Xarrow from "react-xarrows";
import { toFloatMark } from "utils/conversion";
import DialogWrapper from "utils/dialog/dialogWrapper";
import { NoMaxWidthTooltip } from "utils/tooltips";
import { toDecimalMark } from "../../utils/conversion";
import { useGlobalState } from "Global";

const featureMapping = {
  amountName: "Claim Amount (ZAR)",
};

const _ = require("lodash");

const boxStyle = {
  border: "1px #348dec solid",
  borderRadius: "10px",
  padding: "1rem",
  textAlign: "center",
  width: "fit-content",
  minWidth: "200px",
  height: "fit-content",
  color: "black",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  fontSize: "medium",
};

function FormDetails() {
  const navigation = useNavigate();
  const params = useParams();
  const [state, setState] = useState({
    showState: false,
    featuresIsEmpty: true,
    prevFeaturesIsEmpty: true,
    nextStep: {},
  });
  const [currSteps, setCurrSteps] = useState({});
  const [prevSteps, setPrevSteps] = useState({});
  const [userFiles, setUserFiles] = useState({});
  const [uploadFiles, setUploadFiles] = useState({ files: {}, prevFiles: {} });
  const [showMail, setShowMail] = useState(false);
  const [status, setStatus] = useState([]);
  const [showAgentChange, setShowAgentChange] = useState(false);
  const [showStatusChange, setShowStatusChange] = useState(false);
  const [showStateChange, setShowStateChange] = useState(false);
  const [showWorkflowChange, setShowWorkflowChange] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [showFiles, setShowFiles] = useState({ files: true, prevFiles: true });
  const [caseDetails, setCaseDetails] = useState({});
  const [caseTags, setCaseTags] = useState([]);
  const [isLoadingCaseDetials, setIsLoadingCaseDetials] = useState(true);
  const [amountValue, setAmountValue] = useState("");
  const [value, setValue] = useState(null);
  const [reachedBottom, setReachedBottom] = useState(false);
  const [showCards, setShowCards] = useState({
    currFeatures: true,
    prevFeatures: true,
    stepChange: true,
  });
  const [verifiedFeatures, setVerifiedFeatures] = useState([]);
  const [showNotificationHistory, setShowNotificationHistory] = useState(false);
  const [showCaseTransferHistory, setShowCaseTransferHistory] = useState(false);
  const [showAIAgent, setShowAIAgent] = useState(false);
  const [validationList, setValidationList] = useState([]);
  const [userDetails] = useGlobalState("userDetails")

  const stepMapping = {
    prevSet: setPrevSteps,
    currSet: setCurrSteps,
    prev: prevSteps,
    curr: currSteps,
  };

  const [caseFileKey, setCaseFileKey] = useState(0);

  useEffect(() => {
    getFeatures();
    // getFiles();
    getCaseDetails();
    getNextPaths();
    getTags();
    getFeaturesVerified();
  }, [location.pathname]);

  const reloadContents = () => {
    getFeatures();
    getCaseDetails();
    getNextPaths();
  };

  const getNextPaths = async () => {
    caseAPI
      .getNextPaths(params.case_id)
      .then((res) => {
        if (res.status == 200) {
          assignAgents(res.data);
        }
      })
      .catch((error) => {});
  };

  const getTags = async () => {
    caseAPI
      .getTags(params.case_id)
      .then((res) => {
        if (res.status == 200) {
          setCaseTags(res.data);
        }
      })
      .catch((error) => {});
  };

  const assignAgents = async (currStatus) => {
    let tempStatus = currStatus;

    for (let i in tempStatus) {
      tempStatus[i]["agent_details"] = await getAutoAssignedDetails(
        currStatus[i].value
      );
    }
    setStatus(tempStatus);
  };

  const getAutoAssignedDetails = async (nextStep) => {
    let details = {
      error: true,
    };
    try {
      const res = await caseAPI.getAutoAssignedPerson(params.case_id, nextStep);

      if (res.data === null) {
        details["name"] = "No Person Available";
      } else {
        details["error"] = false;
        details = { ...details, ...res.data };
      }
    } catch (error) {}
    return details;
  };

  const handleStepChange = () => {
    caseAPI
      .changeStatus(
        params.case_id,
        state.nextStep.name,
        state.nextStep.emailId ? true : false,
        state.nextStep.emailId,
        state.nextStep.message
      )
      .then((res) => {
        if (res.status == 200) {
          
          if (!["admin", "superuser","3PA"].includes(userDetails.permission)) {
            navigation(`/${userDetails.id}/cases`);
          } else {
            window.location.reload();
          }
        }
      })
      .catch((error) => {});
  };

  const handleSelectNextStep = (nextStep, emailId, status = null) => {
    if (["OOO", "Busy"].includes(status)) {
      setGlobalState("error", {
        open: true,
        message:
          "Selected agent is Busy/Out of Office. Please select the secondary assignee incase faster resolution is needed.",
        type: "warning",
      });
    }
    setState((prevState) => {
      return {
        ...prevState,
        nextStep: {
          ...prevState.nextStep,
          name: nextStep,
          emailId: emailId,
        },
      };
    });
  };

  const handleSelectNextStepMessage = (message, status = null) => {
    setState((prevState) => {
      return {
        ...prevState,
        nextStep: {
          ...prevState.nextStep,
          message: message,
        },
      };
    });
  };

  const getCaseDetails = () => {
    setIsLoadingCaseDetials(true);
    caseAPI
      .getCase(params.case_id)
      .then((res) => {
        let tempCaseDetails = {
          "Current WorkFlow": {
            name: res.data.workflow_name,
            change: () => {
              setShowWorkflowChange(!showWorkflowChange);
            },
          },
          "Claim Amount": {
            name: "ZAR " + toDecimalMark(parseFloat(res.data.claim_amount)),

            show: false,
          },
          "Customer Name": {
            name: res.data.customer,

            show: false,
          },
          "Current Assigned Agent": {
            name: res.data.assigned_agent_email,
            change: () => {
              setShowAgentChange(!showAgentChange);
            },
          },
          "Current Case State": {
            name: res.data.case_state,
            change: () => {
              setShowStateChange(!showStateChange);
            },
          },
          "Current Step": {
            name: res.data.current_status,
            change: () => {
              setShowStatusChange(!showStatusChange);
            },
          },
        };
        setCaseDetails(tempCaseDetails);
        setIsLoadingCaseDetials(false);
      })
      .catch((error) => {
        setIsLoadingCaseDetials(false);
      });
  };

  const getFeatures = async () => {
    setIsLoading(true);
    featureAPI
      .getFeatures(params.case_id)
      .then((res) => {
        setState({
          ...state,
          features: res.data.features,
          prevFeatures: res.data.prev_features,
          featuresIsEmpty: res.data.features_isempty,
          prevFeaturesIsEmpty: res.data.prev_features_isempty,
        });

        setCurrSteps(getFeatureMapping(res.data.features));
        setPrevSteps(getFeatureMapping(res.data.prev_features));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getFeatureMapping = (features) => {
    let temp_steps = {};
    Object.keys(features).forEach((stepName) => {
      let temp_features = {};
      Object.keys(features[stepName]).forEach((feature) => {
        temp_features[feature] = features[stepName][feature].value;
      });
      temp_steps[stepName] = temp_features;
    });
    return temp_steps;
  };

  const setBullet = (dict, value) => {
    Object.keys(dict).forEach((item) => {
      dict[item] = false;
    });
    dict[value] = true;
    return dict;
  };

  const handleInputChange = (
    e,
    stepName,
    values,
    setValues,
    type,
    featuresData,
    stepType
  ) => {
    let { name, value } = e.target;
    let temp_step = values[stepName];
    if (type == "Bullet") {
      value = setBullet(temp_step[name], value);
    } else if (type == "Date") {
      value = format(value, "yyyy-MM-dd");
    } else if (type == "Integer") {
      if (value) {
        let newVal = toFloatMark(value);
        if (name == featureMapping.amountName) {
          setAmountValue(newVal);
        }
        if (value.slice(-1) == ".") {
          value = newVal + ".";
        } else if (value.slice(-2) == ".0") {
          value = newVal + ".0";
        } else {
          value = newVal;
        }
      }
    }

    temp_step[name] = value;
    let newValues = { ...values, [stepName]: temp_step };

    // // Perform Preupdate validation functions
    // let tempPreValidationList =
    //   featuresData[stepName][e.target.name]["pre_update_validation_functions"];
    // let preValidationChecks = preUpdateValidations(
    //   newValues[stepName],
    //   tempPreValidationList,
    //   stepType
    // );

    // if(!preValidationChecks){
    //   return;
    // }

    // Add Validation Functions to the validation queue

    let tempValidationsList =
      featuresData[stepName][e.target.name]?.validation_functions;

    if (
      typeof tempValidationsList == typeof [] &&
      tempValidationsList.length > 0
    ) {
      tempValidationsList = tempValidationsList.filter(
        (validation) =>
          !validationList.some((item) => _.isEqual(validation, item))
      );
      setValidationList((preValidationList) => {
        let newValidationList = [...preValidationList, ...tempValidationsList];
        return newValidationList;
      });
    }
    setValues(newValues);
  };

  const checkValidations = (type, stepName) => {
    let isValid = true;
    validationList.forEach((validation) => {
      let dynamicValidation = Validations[validation.name];
      let result = true;
      if (validation.name != "C2") {
        result = dynamicValidation(
          validation.parameters["feature_name1"],
          type[validation.parameters["feature_name1"]],
          validation.parameters["feature_name2"],
          type[validation.parameters["feature_name2"]]
        );
      } else {
        result = dynamicValidation(
          type["Reason for Invalidation"],
          type["Claim Status"]
        );
      }
      if (result !== true) {
        setGlobalState("error", {
          open: true,
          message: result,
          type: "error",
        });
        isValid = false;
      }
    });
    return isValid;
  };

  const updateStep = (stepName) => {
    let updateStepData = {};

    if (currSteps[stepName]) {
      updateStepData = currSteps[stepName];
    } else {
      updateStepData = prevSteps[stepName];
    }
    if (checkValidations(updateStepData, stepName)) {
      setIsLoading(true);
      featureAPI
        .updateFeatures(params.case_id, stepName, updateStepData)
        .then((res) => {
          if (res.status == 200) {
            setIsLoading(false);
            getCaseDetails();
            getFeatures();
            setGlobalState("error", {
              open: true,
              message: "Features Successfully updated.",
              type: "success",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const getFeaturesVerified = () => {
    OCRAPI.getFeaturesVerified({ case_id: params.case_id })
      .then((res) => {
        if (res.status == 200) {
          setVerifiedFeatures(res.data);
        }
      })
      .catch((error) => {});
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
    </DashboardLayout>
  );
}

export default FormDetails;
