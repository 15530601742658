// @mui material components

//  React components
import MDBox from "components/MDBox";
//  React example components
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
// Data

// Dashboard components
import DataTable from "examples/Tables/DataTable";

import AccessWrapper from "wrappers/accessWrapper";

import TableReport from "./tableReport";

function TableDisplay(props) {
  const navigation = useNavigate();

  const [excelRows, excelColumns] = TableReport({
    excelData: props.data,
    ignoreColumns: props.ignoreColumns,
  });

  return (
    <Card>
      <MDBox
        sx={{
          alignItems: "center",
          height: "auto",
          maxWidth: "100%",
          minHeight: "15vh",
          paddingTop:"2rem"
        }}
      >
        <MDBox
          sx={{
            position: "relative",
          }}
        >
          {excelRows && (
            <DataTable
              table={{
                columns: excelColumns,
                rows: excelRows,
              }}
              isSorted={true}
              canSearch={true}
              showTotalEntries={true}
              noEndBorder
              downloadable={false}
              entriesPerPage={{defaultValue:10}}
              {...props}
              // downloadFileName={`Excel ${tableValue} Data`}
            />
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}
export default AccessWrapper(TableDisplay, ["agent1", "superuser", "admin","3PA","3PU"]);
