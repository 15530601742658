import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function SummarySkeleton() {
  return (
    <SkeletonTheme highlightColor="white">
      {Array(8)
        .fill(0)
        .map((item, index) => {
          return (
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "1rem",
              }}
              key={index}
            >
              <MDBox
                sx={{
                  fontWeight: "bold",
                  margin: "1rem 0",
                  padding: "0.5rem 0",
                  marginBottom: "0",
                }}
              >
                <FontAwesomeIcon icon={faAnglesRight} />
              </MDBox>
              <Card
                sx={{
                  width: "90%",
                  padding: "0.5rem",
                  margin: "1rem",
                  marginBottom: "0",
                }}
              >
                <Skeleton />
              </Card>
            </MDBox>
          );
        })}
    </SkeletonTheme>
  );
}

export default SummarySkeleton;
