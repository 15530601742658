import MDBox from "components/MDBox";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props

// @mui material components

//  React components
import { Avatar, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function NotificationSkeleton(props) {
  return (
    <SkeletonTheme highlightColor="white" {...props}>
      {Array(5)
        .fill(0)
        .map((item, index) => {
          return (
            <MDBox
              key={index}
              component="li"
              display="flex"
              alignItems="center"
              py={1}
              mb={1}
            >
              <MDBox mr={2} sx={{ width: "2rem" }}>
                <Skeleton circle height= "2rem"/>
              </MDBox>
              <MDBox width={"15rem"}>
                <Skeleton height={"2rem"} />
              </MDBox>
              <MDBox ml="auto">
                <Skeleton height={"2rem"} width={"4rem"} />
              </MDBox>
            </MDBox>
          );
        })}
    </SkeletonTheme>
  );
}

export default NotificationSkeleton;
