// @mui material components

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components

// Data
import {
  faChevronDown,
  faChevronUp,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Icon, Tooltip } from "@mui/material";
import { setGlobalState } from "Global";
import { OCRAPI, featureAPI } from "api";
import "css/forms/dropdown.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileThumbnailCard from "./fileThumbnailCard";
import UploadFileCard from "./uploadFileCard";
import LoadingOverlay from "react-loading-overlay";
import DialogWrapper from "utils/dialog/dialogWrapper";
import FileModal from "./showFile";
import FilesSkeleton from "skeletons/forms/filesSkeleton";
import CustomLoader from "utils/customLoader";

export default function CaseFiles({ featureType, stepName }) {
  const navigation = useNavigate();
  const params = useParams();

  const [userFiles, setUserFiles] = useState({});

  const [showFiles, setShowFiles] = useState({ files: true, prevFiles: true });

  const [showCards, setShowCards] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [selectedFile, setSelectedFile] = useState({});

  const getFiles = () => {
    featureAPI
      .getFiles(params.case_id)
      .then((res) => {
        if (res.status == 200) {
          const [tempFiles, filesIsEmpty] = getDefaultFileList(res.data.files);
          const [tempPrevFiles, prevFilesIsEmpty] = getDefaultFileList(
            res.data.prev_files
          );
          setUserFiles({
            files: tempFiles,
            prevFiles: tempPrevFiles,
          });
          setShowFiles({
            prevFiles: prevFilesIsEmpty,
            files: filesIsEmpty,
          });
        }
      })
      .catch((error) => {});
  };

  const getDefaultFileList = (userFeatureFiles) => {
    let isEmpty = true;
    let featureFiles = userFeatureFiles;
    Object.keys(featureFiles).forEach((stepName) => {
      Object.keys(featureFiles[stepName]).forEach((fileName) => {
        if (featureFiles[stepName][fileName] != []) {
          isEmpty = false;
        }
      });
    });
    return [featureFiles, isEmpty];
  };

  const deleteFile = (fileName, fileFeatureName, stepName) => {
    setIsLoading(true);
    featureAPI
      .deleteFile({
        case_id: params.case_id,
        filename: fileName,
        feature_name: fileFeatureName,
        step_name: stepName,
      })
      .then((res) => {
        if (res.status == 200) {
          getFiles();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const uploadFiles = (stepName, fileListName, fileList) => {
    const formdata = new FormData();
    setIsLoading(true);
    let files_feature_list = [];
    Array.from(fileList).forEach((file) => {
      files_feature_list.push(fileListName);

      formdata.append("files_list", file);
    });
    formdata.append("files_feature_list", files_feature_list);

    featureAPI
      .updateFiles(params.case_id, stepName, formdata)
      .then((res) => {
        if (res.status == 200) {
          getFiles();
          setIsLoading(false);
          setGlobalState("error", {
            open: true,
            message: "Files SuccessFully uploaded.",
            type: "success",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);

      });
  };

  useEffect(() => {
    getFiles();
  }, []);

  return (
    <MDBox>
      <LoadingOverlay
        active={isLoading}
        spinner={<CustomLoader />}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "#F6F6F6",
            opacity: "0.6",
          }),
        }}
        text="Loading your content..."
      >
        {showFiles[featureType + "Files"] == false && (
          <MDBox mt={2} pl={2} pr={2} pb={2}>
            {userFiles[featureType + "Files"] &&
              userFiles[featureType + "Files"][stepName] && (
                <>
                  <MDBox
                    mx={0}
                    mt={0}
                    mb={0.5}
                    py={1}
                    px={1}
                    bgColor="secondary"
                    borderRadius="lg"
                    coloredShadow="secondary"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowCards({
                        ...showCards,
                        [featureType + "Files"]:
                          !showCards[featureType + "Files"],
                      });
                    }}
                  >
                    <MDTypography
                      variant="h6"
                      color="white"
                      sx={{
                        paddingLeft: "0.5rem",
                      }}
                    >
                      {"Attached Files"}
                    </MDTypography>
                    <MDBox sx={{ cursor: "pointer" }}>
                      <Tooltip
                        title={
                          showCards[featureType + "Files"] ? "Hide" : "Expand"
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            showCards[featureType + "Files"]
                              ? faChevronUp
                              : faChevronDown
                          }
                          color={"white"}
                          style={{
                            marginRight: "0.5rem",
                          }}
                        ></FontAwesomeIcon>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  {showCards[featureType + "Files"] && (
                    <MDBox sx={{ padding: "1rem" }}>
                      {/* {isLoading==true && <FilesSkeleton />} */}
                      {Object.keys(
                        userFiles[featureType + "Files"][stepName]
                      ).map((file, index) => {
                        return (
                          <Card
                            key={
                              userFiles[featureType + "Files"][stepName][index]
                            }
                            sx={{
                              position: "relative",
                              marginTop: "1rem",
                              padding: "0.5rem",
                            }}
                          >
                            <MDBox
                              sx={{
                                fontSize: "0.8rem",
                                marginLeft: "1rem",
                              }}
                              color="text"
                            >
                              {file}
                            </MDBox>
                            <MDBox
                              sx={{ display: "flex", position: "relative" }}
                            >
                              {userFiles[featureType + "Files"][stepName][
                                file
                              ].map((specificFile) => {
                                return (
                                  <FileThumbnailCard
                                    key={specificFile}
                                    file={specificFile}
                                    deleteFile={deleteFile}
                                    stepName={stepName}
                                    fileFeatureName={file}
                                    handleThumbnailClick={() => {
                                      setShowModal(true);
                                      setSelectedFile({
                                        ...specificFile,
                                        fileName: specificFile["name"],
                                        stepName: stepName,
                                        fileFeatureName: file,
                                      });
                                    }}
                                  />
                                );
                              })}
                              <UploadFileCard
                                fileName={file}
                                length={
                                  userFiles[featureType + "Files"][stepName][
                                    file
                                  ].length
                                }
                                uploadFiles={uploadFiles}
                                stepName={stepName}
                              />
                            </MDBox>
                          </Card>
                        );
                      })}
                    </MDBox>
                  )}
                </>
              )}
          </MDBox>
        )}
      </LoadingOverlay>

      {/* File Viewer */}
      {showModal && (
        <DialogWrapper handleClose={() => setShowModal(false)} width={"45%"}>
          {/* <RSC id="RSC-Example" style={{ width: "100%", height: "100%" }}> */}
          <MDBox className={"fileViewer"}>
            <FileModal file={selectedFile} key={selectedFile.fileName}/>
          </MDBox>
          {/* </RSC> */}
        </DialogWrapper>
      )}
    </MDBox>
  );
}
