import { Card, Grid, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import Controls from "components/controls/Controls";
import { Form } from "components/controls/useForm";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function RowSkeleton({ columns=10 }) {
  return (
    <SkeletonTheme highlightColor="white">
      {Array(10)
        .fill(0)
        .map((item, index) => {
          return (
            <TableRow key={index}>
              {Array(columns)
                .fill(0)
                .map((cell,index2) => (
                  <DataTableBodyCell key={index2}>
                    <Skeleton style={{ height: "1rem",width:"200px" }} />
                  </DataTableBodyCell>
                ))}
            </TableRow>
          );
        })}
    </SkeletonTheme>
  );
}

export default RowSkeleton;
